import { ImagesService } from '../../../services/backoffice/mine/images/images.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';

@Component({
  selector: 'app-image-back',
  templateUrl: './image-back.component.html',
  styleUrls: ['./image-back.component.scss']
})
export class ImageBackComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() stateModal: boolean;
  @Output() reponseStateModalForm = new EventEmitter();

  data: any;
  typeModal: string;
  titleModal: string = 'Imagenes';
  stateModalAlert: boolean = false;
  idMine: string = '';
  originalImage: boolean = true;
  originalLogo: boolean = true;
  modalInfo: ModalInfo = new ModalInfo();

  isButtonDisabled: boolean = false;

  constructor( private imagesService: ImagesService, private sanitizer: DomSanitizer, private route: ActivatedRoute ) {
    this.idMine = this.route.snapshot.paramMap.get('id');
   }

  ngOnInit(): void {

    // this.getImage();
    // this.image = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,'+'https://s3.amazonaws.com/duhaime/blog/tsne-webgl/assets/cat.jpg');
    this.getImage();
    this.getLogo();
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);
    // this.elements = this.data;
    // this.mdbTable.setDataSource(this.elements);
    // this.elements = this.mdbTable.getDataSource();
    // this.previous = this.mdbTable.getDataSource();
    // this.ngAfterViewInit();

    if (this.stateModal == true) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");

      this.addModal.show();

      setTimeout(() => {
        this.reponseStateModalForm.emit(false);
      }, 500);
    }

    if(this.typeModal == 'edit'){

      // console.log('EDIT ZONE: '+this.data.id);
      // this.idObject = this.data.id;
      // this.ObjectForm.get('sector_faena_id').setValue(this.data.sector_faena_id);
      // this.ObjectForm.get('nombre').setValue(this.data.nombre);
      // this.ObjectForm.get('matriz').setValue(this.data.matriz);
    }else{
      // this.ObjectForm.reset();
    }
  }

  onSubmitObject(type: string){
    
    this.isButtonDisabled = true; 

    if(type == 'img'){

      const bodyImage = {
        "faena_id": this.idMine,
        "type": this.typeFile,
        "image": this.base64ImageCode
      }

      this.updateImage(bodyImage);

    }else{

      const bodyLogo = {
        "faena_id": this.idMine,
        "type": this.typeFile,
        "image": this.base64LogoCode
      }
      this.updateImage(bodyLogo);

    }  
  }

  onResponseAlert(event){
    this.stateModalAlert = event;
  }


  getServicesMineSubscription: Subscription;
  image: any;
  logo: any;
  getImage(){
  
    this.getServicesMineSubscription = this.imagesService.getImage( this.idMine ).subscribe({
      next: (resp) => { 
        this.image = resp;
        this.image = this.image.image;
         console.log('URL IMAGE: ' + this.image); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getLogo(){
  
    this.getServicesMineSubscription = this.imagesService.getLogo( this.idMine ).subscribe({
      next: (resp) => { 
        this.logo = resp;
        this.logo = this.logo.image;
         console.log('GET IMAGE: ' + resp); 
        },
      error: (err) => { 
        console.log('Received an error: ' + err.message);
      },
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  updateImage(form: any){
  
    this.imagesService.updateImage(form).subscribe({
      next: (resp) => { 
        //this.image = resp;
        this.messageAlert(true, 'success', 'Imagen acutalizada correctamente');
        this.isButtonDisabled = false; 
        console.log('GET IMAGE: ' + resp); 
      },
      error: (err) => {
         console.log('Received an error: ' + err.message);
         this.messageAlert(true, 'error', 'Error al actualizar imagen')
      },
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  base64Image: string | null = null;
  base64ImageCode: string | null = null;
  nameImage: string;

  base64Logo: string | null = null;
  base64LogoCode: string | null = null;
  nameLogo: string;

  typeFile: string = '';

  onFileSelected(event: any, type: string): void {
    const file: File = event.target.files[0];

    type == 'img' ? this.typeFile = 'img' : this.typeFile = 'logo';
    type == 'img' ? this.originalImage = false : this.originalLogo = false;

    if (file) {
      this.convertToBase64(file, type);
      this.nameImage = file.name;
    }

  }

  convertToBase64(file: File, type: string): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64String = e.target.result;
      type == 'img' ?  this.base64Image = base64String : this.base64Logo = base64String;
      // Verificar si la cadena tiene el formato esperado
      if (base64String.startsWith('data:image/png;base64,')) {
        // Dividir la cadena y obtener la segunda parte (datos base64)
        const [, base64Data] = base64String.split(';base64,');
        type == 'img' ? this.base64ImageCode = base64Data : this.base64LogoCode = base64Data;

        console.log('IMAGE BASE64: ' + this.base64ImageCode);
      } else {
        console.error('La cadena no tiene el formato esperado.');
      }
    };

    reader.readAsDataURL(file);
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

}
