
<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">{{ titleModal }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body">
              <div class="container mt-4 px-4">
                <form class="text-center forms-text" [formGroup]="objectForm">
                  <div class="mx-auto px-4" style="background-color: white;">
                    <div class="row">
                                    
                      <div class="md-form md-outline mt-5 col-sm-12">
                        <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Usuarios" label="Usuarios" [formControlName]="'usuarios'">
                          <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                          <mdb-select-option *ngFor="let option of users" [value]="option.id" >{{ option.nombre + ' ' + option.apellido_p }}</mdb-select-option>
                        </mdb-select-2>
                      </div>    
  
                    </div>
                  </div>         
              </form>
              </div>
              
            </div>
            <div class="modal-footer modals-footer">
                <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
                <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitObject()" mdbWavesEffect>Guardar</button>
            </div>
        </div>
    </div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
(reponseStateModalAccept)="onResponseAlert($event)"></app-modal-accept>


