
import { ConnectionLinksService } from '../../../services/configuration/links/connection-links.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-connection-links',
  templateUrl: './connection-links.component.html',
  styleUrls: ['./connection-links.component.scss']
})
export class ConnectionLinksComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  linkForm: FormGroup;

  mines = [];
  typeTable: string = 'times';

  headElements = ['N°', 'Codigo', ' Horas de incidencias al mes', 'Herramientas'];
  headTable = ['codigo', 'horas_perdidas', 'tools'];
  colspanLarge: number = 7;

  modalInfo: ModalInfo = new ModalInfo();
  stateModalTable: boolean = false;
  idMine: string;

  instrumentSelected: string = '';
  stateModalAlert: boolean = false;

  links = [{'name': 'Red Mina'}, {'name': 'VPN Mina'}, {'name': 'Enlace Dedicado'}, {'name': 'VPN EMT'}, {'name': 'Red EMT'}];

  connectionLinksSubscription: Subscription;

  constructor(private fb: FormBuilder, private connectionLinksService: ConnectionLinksService, private router: Router, public authService: AuthService) { 
    this.idMine = JSON.parse(localStorage.getItem("mine")).id;
  }

  ngOnInit(): void {

    this.initForm();
    this.getConnectionLinksByMine(this.idMine);
  }

  ngOnDestroy(): void {
    this.connectionLinksSubscription?.unsubscribe();
  }

  initForm(){

    this.linkForm = this.fb.group({
      'codigos': [''],
      'faena_id': [''],
      'tipo':[''],
      'fecha_comienzo':[''],
    });
  }

  onreponsDeleteObjectTable($event){

    this.instrumentSelected = $event;  
    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+$event.nombre);   
  }

  onreponseUpdateObjectTable($event){
  }

  onreponseChangeObjectTable($event){
  }


  onreponseTimeObjectTable(event){

    const queryParams = { name: event.codigo };

    this.instrumentSelected = event;
    //this.router.navigate(['/emt/connectionLosses', { queryParams: queryParams }]);
    this.router.navigate(['/emt/connectionLosses/'+event.id, {data: event.codigo}]);
    localStorage.setItem('linkCode', event.codigo );
  }

  onResponseAlert(event){
    this.stateModalAlert = false;
    console.log('RESPONSE MINE NORMAL');
  }

  addLink(){

    this.addModal.show();
  }

  onSubmit(){

    console.log('ON SUBMIT');

    if(this.linkForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');   
      return
    }

    this.creatConnectionLink();
  }


  creatConnectionLink(){

    this.linkForm.get('faena_id').setValue(this.idMine);
    this.linkForm.get('tipo').setValue('enlace');
    this.linkForm.get('fecha_comienzo').setValue(moment(new Date()).format('YYYY-MM-DD'+'T'+'HH:mm:ss'));
    
    this.connectionLinksService.creatConnectionLink(this.linkForm.value).subscribe({
      next: (resp: any) =>{  
  
        this.linkForm.reset();
        this.getConnectionLinksByMine(this.idMine);
        this.messageAlert(true, 'success', 'Enlaces de conexión creados correctamente');   
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Enlace no pudo ser creado correctamente');   
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }

  getConnectionLinksByMine(id: string){

    this.connectionLinksSubscription = this.connectionLinksService.getConnectionLinksByMine( id ).subscribe({
      next: (resp) => { 
         this.mines = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
        this.stateModalTable = true;  
      }
    });
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

}
