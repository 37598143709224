// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'https://m2d.emt.cl/api4',
  //base_url: 'http://localhost:3010/api',
  //base_url_fast: 'http://localhost:8000/api', 
  base_url_fast: 'https://reportabilidadfast.emt.cl/api', 
  //base_url_fast: 'https://fastgcp.emt.cl/api',
   //base_url_fast: 'http://10.10.10.18:8029/api',

  // base_url: 'https://apps6.emt.cl/api ',
  // base_url_fast: 'https://apps5.emt.cl/api',

  firebase: {
    apiKey: "AIzaSyDOaEab-SPEstFntb_lRY32TqNwwZAZgd8",
    authDomain: "reportabilidad-af140.firebaseapp.com",
    projectId: "reportabilidad-af140",
    storageBucket: "reportabilidad-af140.appspot.com",
    messagingSenderId: "174096268091",
    appId: "1:174096268091:web:df18a83ef69edaad87151e",
    measurementId: "G-G3W16KRGCD"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
