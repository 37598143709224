
<div class="padding-view">
    <div class="breadcrumb-style">
      <mdb-breadcrumb customClass="white" class="breadcrumbs pl-4">
        <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/home">Backoffice</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="breadcrumbs-link">Incidencias</mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
    <div class="container z-depth-1 p-0 my-5">
      <div class="header-forms white-text header-div">
        <div>
        </div>
        <h5 class="header-title-forms">
            Incidencias
        </h5>
        <div class="add-icon">
            <a (click)="addObject()" *ngIf="authService.hasRole(['admin', 'supervisor'])"><mdb-icon fas icon="plus-circle" style="color: white;" mdbTooltip="Agregar" placement="top"></mdb-icon></a>
        </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="md-form md-outline col-md-3">
          <mdb-select-2 [outline]="true" class="input-style" placeholder="Elegir Escala de tiempo" label="Escala de tiempo">
          <mdb-select-option *ngFor="let option of timeLine; let i = index" [value]="option.id" (click)="changeTimeLine(option)">{{ option.nombre }}
          </mdb-select-option>
        </mdb-select-2>
        </div>
        <div class="md-form md-outline col-md-3">
          <button type="button" mdbBtn class="waves-light button-accept-modals save-button m-0"  (click)="filter()"
            mdbWavesEffect>Buscar
          </button> 
          <!-- <button type="button" mdbBtn class="waves-light button-accept-modals cancel-button m-0 ml-3"  (click)="resetFilter()"
            mdbWavesEffect>Reset
          </button>  -->
        </div>
      </div>
    </div>
    <div class="table-body container">
        <app-table-incidence-back [data]="dataTable" [stateModal]="stateModalTable" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge" (reponseDeleteTable)="onReponseDeleteTable($event)"
         (reponseUpdateTable)="onreponseUpdateObjectTable($event)"></app-table-incidence-back>
    </div>
    </div>
</div>

<app-incidence-back [modalInfo]="modalInfo" [stateModal]="stateModalType" [dataTimeLine]="timeLine" [data]="dataObject" [typeModal]="typeObject" (reponseRefresh)="onResponseRefresh()"
  (reponseStateModalForm) = "onReponseStateModalFormMine($event)"></app-incidence-back>  


<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert" (reponseDeleteObjectModal) = "onReponseDeleteObjectModal($event)"
  (reponseStateModalAccept) = "onResponseAlert($event)"></app-modal-accept>