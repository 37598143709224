import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Backoffice } from '../../../interfaces/backoffice';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class IncidentsService {


  constructor(private http: HttpClient) { }

  getTimeLine( ): Observable<any[]>{
    const url = `${ base_url_fast }/estadoperdida`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  getIncidencesByTimeLine( id: string ): Observable<any[]>{
    const url = `${ base_url_fast }/estadoperdida/tipoperdida/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  createIncidence( form: any ): Observable<any[]>{

    const url = `${ base_url_fast }/tipoperdida`;
    return this.http.post( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  updateIncidence( form: any, id: string ): Observable<any[]>{

    const url = `${ base_url_fast }/tipoperdida/${id}`;
    return this.http.put( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  
  deleteIncidence( id: string ): Observable<any[]>{

    const url = `${ base_url_fast }/tipoperdida/${id}`;
    return this.http.delete( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }
}