
import { InstrumentsService } from '../../../services/backoffice/instruments/instruments.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InfraestructuretiService } from 'src/app/services/configuration/infraestructure/infraestructureti.service';
import { A2vService } from 'src/app/services/configuration/a2v/a2v.service';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-ita2v',
  templateUrl: './ita2v.component.html',
  styleUrls: ['./ita2v.component.scss']
})
export class Ita2vComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  timesForm: FormGroup;

  times: any[] = [];
  idLinkConnection: string;
  linkCode: any = "";

  headElements = ['N°', 'F/H Inicio', 'F/H Termino', 'Duración[H]', 'Observación'];
  headTable = [  'fecha_inicio', 'fecha_termino', 'tiempo_caido', 'observacion'];
  colspanLarge: number = 7;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateModalTable: boolean = false;
  idMine: string;

  instrumentSelected: string = '';
  linkLossesSubscription: Subscription;
  a2vSubscription: Subscription;
  a2v: any = [];

  typeLosses = [
    { value: 'Reserva', label: 'Reserva' },
    { value: 'Mantención programada', label: 'Mantención programada' },
    { value: 'Mantención no programada', label: 'Mantención no programada' },
    // { value: 'Camaras', label: 'Camaras' },
    // { value: 'Inclinometros', label: 'Inclinómetros' },
    // { value: 'Geocentinela', label: 'Geocentinela' },
    // { value: 'Piezometros', label: 'Piezómetros' }
  ];



  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService, private a2vService: A2vService, private infraestructuretiService: InfraestructuretiService, private router: Router, private route: ActivatedRoute, public authService: AuthService) { 

    this.idMine = JSON.parse(localStorage.getItem("mine")).id;
    // this.idLinkConnection = this.route.snapshot.paramMap.get('id'); //a las rutas
    // console.log('ID Link: '+this.idLinkConnection);
    this.linkCode = localStorage.getItem('linkCode');
  }

  ngOnInit(): void {

    this.initForm();
    this.getLinkLossesByMine(this.idMine);
    this.getA2vByMine(this.idMine);
  }

  ngOnDestroy(): void {

    this.linkLossesSubscription?.unsubscribe();
    this.a2vSubscription?.unsubscribe();
  }

  initForm(){

    this.timesForm = this.fb.group({
      'a2v_id': [''],
      'tipo': [''],
      'fecha_inicio_date': ['', Validators.required],
      'fecha_inicio_hora': ['', Validators.required],
      'fecha_fin_date': ['', Validators.required],
      'fecha_fin_hora': ['', Validators.required],
      'tiempo_caido': [''],
      'observacion': [''],
    });
  }

  onreponsDeleteObjectTable($event){

    this.instrumentSelected = $event;
    this.stateModal = true;  
    this.modalInfo.messageModal = 'Esta seguro de eliminar '+$event.codigo;
    this.modalInfo.type = 'warning'    
  }

  onreponseUpdateObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.instrumentSelected = $event;
    this.router.navigateByUrl('/emt/instrument/'+ $event.id );
  }

  onreponseTimeObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.instrumentSelected = $event;
    this.router.navigateByUrl('/emt/times/'+ $event.id );
  }

  onreponseChangeObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.instrumentSelected = $event;
    this.router.navigateByUrl('/emt/positions/'+ $event.id );
    localStorage.setItem('instrument', $event.id );
  }

  onResponseNormal(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }

  showAddModal(){

    this.addModal.show();
  }


  onSubmitA2vLosses(){

    console.log('ON SUBMIT');

    if(this.timesForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');    
      return
    }
    this.createa2vLosses();
  }


  getLinkLossesByMine(id: string){

    this.linkLossesSubscription = this.a2vService.getLinkLossesByMine( id ).subscribe({
      next: (resp) => { 
         this.times = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getA2vByMine(id: string){

    this.a2vSubscription = this.a2vService.getA2vByMine( id ).subscribe({
      next: (resp) => { 
         this.a2v = resp;
         console.log('Response: ' + this.a2v.id); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  createa2vLosses(){

    this.a2vService.createa2vLosses(this.timesForm.value, this.a2v.id).subscribe({
      next: (resp: any) =>{  
  
        this.timesForm.reset();
        this.addModal.hide();
        this.messageAlert(true, 'success', 'Perdida de tiempo creado correctamente');
        this.getLinkLossesByMine(this.idMine); 
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
      }
  });
  }
  
  getFieldControl(fieldName: string) {
    return this.timesForm.get(fieldName);
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModal = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

}

