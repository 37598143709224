import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TrainingsService } from '../../../services/backoffice/training/trainings.service';
import { UsersService } from 'src/app/services/backoffice/users/users.service';

@Component({
  selector: 'app-back-training-note',
  templateUrl: './back-training-note.component.html',
  styleUrls: ['./back-training-note.component.scss']
})

export class BackTrainingNoteComponent implements OnInit, OnDestroy {
  
  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() modalInfo: any;
  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() responseStateModalForm = new EventEmitter();
  @Output() responseRefresh = new EventEmitter();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idObject: string = '';
  titleModal: string = 'Editar Fecha/Nota';

  typeFilter: string = '';
  userFilter: string = '';
  titleType: string = '';
  stateFilter: boolean = false;

  state: Array<string> = ["Capacitacion", "Certificacion"];

  public myDatePickerOptions: IMyOptions = {
    // Your options
    //  dateFormat: 'DD/MM/YYYY'
  };

  users: any[] = [];
  notes: any[] = [];
  notesOriginal: any[] = [];
  userId: string = '';
  noteForm: string = '';
  commentForm: string = '';

  usersSubscription: Subscription;
  notesSubscription: Subscription;

  disabledButton: boolean = true;

  constructor( private fb: FormBuilder, private trainingsService: TrainingsService, private usersService: UsersService ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getUsers();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("MESSAGE STATEMODAL TRUE CHANGES");
    if (this.stateModal) {
      this.addModal.show();
      setTimeout(() => {
        this.responseStateModalForm.emit(false);
      }, 500);
    }
    console.log("TYPE MODAL: "+this.typeModal);

      if (this.typeModal == 'edit') {
        this.titleModal = 'Editar Fecha/Nota';
        console.log('USER ID: '+this.data);

        this.idObject = this.data;
        console.log('USER ID: '+this.idObject);

        this.userFilter = this.idObject;
        this.typeFilter = 'Capacitacion';
        this.titleType =  'Capacitaciones';
        console.log('FILTER: ' + this.userFilter);
        this.getNotesByUser(this.userFilter);
        this.filter();

        this.disabledButton = true;
        // if (this.userFilter != this.userId) {
        //   this.getNotesByUser(this.userFilter);
        // } else {
        //   this.notes = this.notesOriginal.filter((object) => object.tipo.toLowerCase() == this.typeFilter.toLowerCase());
        // }
        // this.objectForm.patchValue({
        //   categoria: this.data.categoria,
        //   tipo: this.data.tipo,
        //   nombre: this.data.nombre,
        //   periodo_validez: this.data.periodo_validez,
        // });
      } else {
        // this.objectForm.reset();
        this.disabledButton = false;
        this.titleModal = 'Agregar Fecha/Nota';
      }
  }

  ngOnDestroy(): void {

    this.usersSubscription?.unsubscribe();
    this.notesSubscription?.unsubscribe();
  }

  private initializeForm(): void {
    this.objectForm = this.fb.group({
      nota: ['', Validators.required],
      fecha: ['', Validators.required],
      comentario: ['', Validators.required],
      modulo_id: [''],
      usuario_id: ['']
    });
  }

  showAddModal() {
    this.addModal.show();
  }

  onResponseNormal(event) {
    this.stateModalAlert = event;
  }

  // onSubmitObject() {
  //   if (this.objectForm.invalid) {
  //     this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');
  //     return;
  //   }

  //   if (this.typeModal == 'edit') {
  //     this.createNote();
  //   }
  // }


  submit(item) {
    console.log('ITEM ID: ' + item.registro_id);
    // console.log('ITEM ID: ' + item.nota);
    // console.log('NOTA: ' + this.noteForm);
    console.log('NOTA: ' + this.objectForm.value.nota);
    console.log('NOTA: ' + this.objectForm.value.comentario);
    console.log('NOTA: ' + this.objectForm.value.fecha);

    // this.objectForm.patchValue({
    //   nota: item.nota,
    //   fecha: item.fecha,
    //   comentario: item.comentario,
    //   modulo_id: item.modulo_id,
    //   usuario_id: item.usuario_id
    // });
    item.registro_id ? this.updateNoteTraining(item.registro_id) : this.createNoteTraining();

  }


  onTabClick(item: any) {
    //console.log(`Se hizo clic en la pestaña ${item.registro}`);
    console.log(`Registro ID ${item.registro_id}`);

    // Aquí puedes agregar tu lógica personalizada
    this.objectForm.patchValue({
      nota: item.nota,
      fecha: item.fecha,
      comentario: item.comentario,
      modulo_id: item.modulo_id,
      usuario_id: item.usuario_id,
    });
  }

  createNoteTraining() {

    console.log('CREATE NOTE');
    console.log('NOTA: ' + this.objectForm.value.nota);
    this.objectForm.value.fecha = new Date(this.objectForm.value.fecha).toISOString();
    console.log('DATEX: ' +  this.objectForm.value.fecha);
    console.log('MODULO: ' +  this.objectForm.value.modulo_id);
    console.log('USUARIO ID: ' +  this.objectForm.value.usuario_id);

    // this.objectForm.value.fecha ="2023-10-04 13:36:00";
    this.trainingsService.createNoteTraining(this.objectForm.value).subscribe({
      next: (resp: any) => {
        // this.responseRefresh.emit(false);
        // this.objectForm.reset();
        // this.addModal.hide();
        this.getNotesByUser(this.userFilter);
        this.messageAlert(true, 'success', 'Nota actualizada correctamente');
      },
      error: (err) => {
        console.log('Received an error: ' + err);
        this.messageAlert(true, 'error', 'Error al actualizar nota');
      },
      complete: () => {
        console.log('Completed');
      },
    });
  }

  updateNoteTraining(registro_id: string) {

    console.log('ITEM ID: ' + registro_id);
    console.log('NOTA: ' + this.noteForm);

    this.objectForm.value.fecha = new Date(this.objectForm.value.fecha).toISOString();
    console.log('DATEX: '+this.objectForm.value.fecha);
    // this.objectForm.value.fecha ="2023-10-04 13:36:00";
    this.trainingsService.updateNoteTraining(registro_id, this.objectForm.value).subscribe({
      next: (resp: any) => {
        this.getNotesByUser(this.userFilter);
        this.messageAlert(true, 'success', 'Nota actualizada correctamente');
      },
      error: (err) => {
        console.log('Received an error: ' + err);
        this.messageAlert(true, 'error', 'Error al actualizar nota');
      },
      complete: () => {
        console.log('Completed');
      },
    });
  }


  getUsers() {
    this.usersSubscription = this.usersService.getUsersFast().subscribe({
      next: (resp) => {
        this.users = resp;
        console.log('Response: ' + resp);
      },
      error: (err) => {
        console.log('Received an error: ' + err.message);
        this.messageAlert(true, 'error', 'Error al crear usuario');
      },
      complete: () => {
        console.log('Completed');
      },
    });
  }

  getNotesByUser(id: string) {
    this.userId = id;
    this.notesSubscription = this.trainingsService.getNotesByUser(id).subscribe({
      next: (resp) => {
        this.notesOriginal = resp;
        this.notes = this.notesOriginal.filter((object) => object.tipo.toLowerCase() == this.typeFilter.toLowerCase());
        console.log('Response: ' + resp);
      },
      error: (err) => {
        console.log('Received an error: ' + err.message);
      },
      complete: () => {
        console.log('Completed');
      },
    });
  }

  filter() {
    console.log('FILTER');
    this.stateFilter = true;
    this.titleType = this.typeFilter.toLowerCase() === 'capacitacion' ? 'Capacitaciones' : 'Certificaciones';
    console.log('FILTER: ' + this.typeFilter.toLowerCase());
    console.log('FILTER: ' + this.userFilter);

    if (this.userFilter != this.userId) {
      this.getNotesByUser(this.userFilter);
    } else {
      this.notes = this.notesOriginal.filter((object) => object.tipo.toLowerCase() == this.typeFilter.toLowerCase());
    }
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
