import { Mine } from 'src/app/models/mine';
import { InstrumentsService } from '../../../services/backoffice/instruments/instruments.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: ['./instruments.component.scss']
})
export class InstrumentsComponent implements OnInit {

  mines: any[] = [];

  headElements = ['N°', 'Nombre', ' Tipo', 'Este', 'Norte', 'Cota', 'Herramientas'];
  headTable = ['codigo', 'tipo_nombre', 'este', 'norte', 'cota', 'tools'];
  colspanLarge: number = 7;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateModalTable: boolean = false;
  mine: Mine;
  objectSelected: any;

  instrumentsSubscription: Subscription;

  constructor( private instrumentsService: InstrumentsService, private router: Router, public authService: AuthService) {

    this.mine = JSON.parse(localStorage.getItem("mine"));
   }

  ngOnInit(): void {

    this.getInstrumentsByMine(this.mine.id);
  }


  onreponsDeleteObjectTable($event){

    this.objectSelected = $event;
    this.stateModal = true;  
    this.modalInfo.messageModal = 'Esta seguro de eliminar '+$event.codigo;
    this.modalInfo.type = 'warning'    
  }

  onreponseUpdateObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.objectSelected = $event;
    this.router.navigateByUrl('/emt/instrument/'+ $event.id );

    localStorage.setItem('instrument', $event.id );
  }

  onreponseTimeObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.objectSelected = $event;
    this.router.navigateByUrl('/emt/times/'+ $event.id );

    localStorage.setItem('instrument', $event.id );
  }

  onreponseChangeObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.objectSelected = $event;
    this.router.navigateByUrl('/emt/positions/'+ $event.id );

    localStorage.setItem('instrument', $event.id );

  }


  onResponseModalAlert(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }

  onReponseDeleteObjectModal($event){

    this.deleteObject(this.objectSelected.id);
  }

  getInstrumentsByMine(idmine: string){

    this.instrumentsSubscription = this.instrumentsService.getInstrumentsByMine(idmine).subscribe({
      next: (resp) => { 
         this.mines = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  deleteObject(id: string){

    this.instrumentsService.deleteInstrument(id).subscribe({
      next: (resp) => { 
         //this.getMines();  
         this.mines = this.mines.filter(objeto => objeto.id !== this.objectSelected.id);
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }
}
