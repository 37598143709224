
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Instrument } from '../../../models/instrument';

import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Itinfraestructurelosses } from '../../../models/itinfraestructurelosses';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class InfraestructuretiService {

  constructor(private http: HttpClient) { }

  getInstruments(): Observable<Instrument[]>{
    const url = `${ base_url_fast }/instrumentos`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Instrument[] }) => resp.objects )
              );
  }

  getInfaestructuretiByMine(id: string): Observable<any[]>{

    const url = `${ base_url_fast }/faena/infraestructura/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  // creatConnectionLink(form: any) {
  //   const url = `${ base_url_fast }/enlaces`;
  //   const req = this.http.post(url, form);
  //   return req;
  // }

  // getLinkLossesByMine(id: string): Observable<Ita2v[]>{
  //   const url = `${ base_url_fast }/infraestructura/estado/all/${id}`;
  //   return this.http.post( url, null )
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: Ita2v[] }) => resp.objects )
  //             );
  // }

  getLinkLossesByMine(id: string): Observable<Itinfraestructurelosses[]>{
    const url = `${ base_url_fast }/infraestructura/estado/all/${id}`;
    return this.http.post( url, null, httpOptions )
    .pipe(
      map( (resp: {ok: boolean, objects: Itinfraestructurelosses[] }) => 
      resp.objects.map( data => {
        return {
          id: data.id,
          infraestructura_id: data.infraestructura_id,     
          fecha_inicio: moment(data.fecha_inicio).format('DD-MM-YYYY HH:mm'),
          fecha_termino: moment(data.fecha_termino).format('DD-MM-YYYY HH:mm'),
          tiempo_caido: data.tiempo_caido,
          observacion: data.observacion,
        }
      })
      )
    )
  }

  createInfraestructuretiLosses(form: any, id: string) {

    const dateInit = form.fecha_inicio_date ? new Date(`${form.fecha_inicio_date}T${form.fecha_inicio_hora}`).toISOString(): null;
    const dateEnd = form.fecha_fin_date ? new Date(`${form.fecha_fin_date}T${form.fecha_fin_hora}`).toISOString(): null;

    let body = {
        "infraestructura_id": id,
        "fecha_inicio": dateInit,
        "fecha_termino": dateEnd,
        "observacion": form.observacion,
      }

    const url = `${base_url_fast}/infraestructura/estado`;
    const req = this.http.post(url, body, httpOptions);
    return req;
  }



}
