
    <div class="row">
        <div class="container">
                <div class="row">
                    <div class="col-md-12 mx-auto">
                        <div class="md-form">
                        <input
                            type="text"
                            [(ngModel)]="searchText"
                            class="form-control"
                            style="background-color: white;"
                            id="searchZone"
                            mdbInput/>
                        <label for="search" class="pl-3">Buscar</label>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table  id="tableSortExample" mdbTable #tableEl="mdbTable" small="true"  class="z-depth-1 table-body">
                        <thead>
                            <tr>
                                <th *ngFor="let head of headElements; let i = index" aria-controls="tableSortExample" scope="col"
                                    class="table-header text-nowrap" [mdbTableSort]="elements" [sortBy]="headElements[i]">{{ head | titlecase }}
                                    <mdb-icon fas icon="sort"></mdb-icon>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let el of elements; let i = index">
                                <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" class="th-lg"  scope="row">{{ i+1 }}
                                </th>
                                <ng-container *ngFor="let item of headTable">
                                    <td *ngIf="(i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex) && item != 'tools' " class="th-lg">{{ el[item] }}
                                    </td>
                                    <td *ngIf="(i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex) && item == 'tools' " class="th-lg">
                                        <button class="btn button-edit-table"
                                            (click)="updateObject(el)" mdbTooltip="Editar" placement="top">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                        <button class="btn ml-3 button-delete-table"  *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])"
                                            (click)="deleteObject(el)" mdbTooltip="Eliminar" placement="top"> 
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                        <tfoot class="grey lighten-5 w-100">
                            <tr class="table-footer">
                                <td [attr.colspan] = "colspanLarge" >
                                    <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="elements"></mdb-table-pagination>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
        </div>
    </div>
