import { SectorMine } from '../../../../models/sector-mine';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';
import { Mine } from '../../../../models/mine';

import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Companies } from '../../../../models/companies';
import { HttpHeaders } from '@angular/common/http';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class MinesService {

  constructor(private http: HttpClient) { }

  getCompanies(): Observable<Companies[]>{
    const url = `${ base_url_fast }/compania`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Companies[] }) => resp.objects )
              );
  }

  getMines(): Observable<Mine[]>{
    const url = `${ base_url_fast }/faena`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Mine[] }) => resp.objects )
              );
  }

  getMineById(id: string): Observable<any[]>{

    const url = `${ base_url_fast }/faena/${id}`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }


  updateMine(form: any, id: string): Observable<any[]>{

    let date_init = moment( form.inicio_mes ).format('YYYY-MM-DD'+'T'+'HH:mm:ss');
    console.log('MOMENT: '+date_init);

    const body = {
      "nombre_corto": form.nombre_corto,
      "nombre_largo": form.nombre_largo,
      "direccion_oficinas": form.direccion_oficinas,
      "email_svr": form.email_svr,
      "telefono_svr": form.telefono_svr,
      "imagen_corporativa": form.imagen_corporativa,
      "este": form.este,
      "norte": form.norte,
      "compania_id": form.compania_id,
      "inicio_mes": date_init
    }

    const url = `${ base_url_fast }/faena/${id}`;
    return this.http.put( url, body, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }


  createMine(form: any) {


    let date_init = moment( form.inicio_mes ).format('YYYY-MM-DD'+'T'+'HH:mm:ss').toString();
    console.log('MOMENT: '+date_init);

    const body = {
      "nombre_corto": form.nombre_corto,
      "nombre_largo": form.nombre_largo,
      "direccion_oficinas": form.direccion_oficinas,
      "email_svr": form.email_svr,
      "telefono_svr": form.telefono_svr,
      "imagen_corporativa": "",
      "este": form.este,
      "norte": form.norte,
      "url_imagen": "",
      "compania_id": form.compania_id,
      "inicio_mes": date_init
    }

    const url = `${base_url_fast}/faena`;
    const req = this.http.post(url, body, httpOptions);
    return req;
  }

  deleteMine(id: string): Observable<Mine[]>{
    const url = `${ base_url_fast }/faena/${id}`;
    return this.http.delete( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Mine[] }) => resp.objects )
              );
  }

  getSectorsByMines( idMine: String ): Observable<SectorMine[]>{
    const url = `${ base_url_fast }/faena/sector/${idMine}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: SectorMine[] }) => resp.objects )
              );
  }

  // getSectors(): Observable<SectorMine[]>{
  //   const url = `${ base_url_fast }/sector`;
  //   return this.http.get( url )
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: SectorMine[] }) => resp.objects )
  //             );
  // }



}

