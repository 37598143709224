<div class="container-fluid mt-3 px-0 mb-2">
  <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link mt-3 p-0 mb-0 pb-0">
    <mdb-breadcrumb-item class="active">Infraestructura TI</mdb-breadcrumb-item>
  </mdb-breadcrumb>
  <mdb-card>
    <mdb-card-header class="header-forms white-text header-div">
      <h5 class="header-title-forms">
        Infraestructura TI
      </h5>
      <!-- <div class="add-icon">
            <a (click)="addLink()"><mdb-icon fas icon="plus-circle" style="color: white;"></mdb-icon></a>
        </div> -->
    </mdb-card-header>
    <div class="table-body container">
      <app-table-times [data]="mines" [stateModal]="stateModalTable" [headElements]="headElements"
        [headTable]="headTable" [colspanLarge]="colspanLarge" [typeTable]="typeTable"
        (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
        (reponseUpdateTable)="onreponseUpdateObjectTable($event)" (reponseTimeTable)="onreponseTimeObjectTable($event)"
        (reponseChangeTable)="onreponseChangeObjectTable($event)"></app-table-times>
    </div>
  </mdb-card>
</div>


<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
  <div class="modal-dialog modal-size" role="document">
    <div class="modal-content">
      <div class="header-div">
        <h4 class="modal-title-head" id="myModalLabel">Agregar enlaces de conexión</h4>
        <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close"
          (click)="addModal.hide()">
          <span aria-hidden="true" class="p-0 m-0">×</span>
        </button>
      </div>
      <div class="modal-body modals-body">
        <div class="container mt-4 px-4">
          <form class="text-center row forms-text" [formGroup]="linkForm">


            <div class="col-12 mx-auto" style="background-color: white;">
              <div class="row">

                <div class="col-12 sub-headers-forms">
                  <span class="title-forms modals-subtitle">Enlaces</span>
                </div>

                <div class="col-12">
                  <div class="row px-3">

                    <div class="col-12 md-form md-outline">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Enlaces" label="Enlaces"
                        [formControlName]="'codigos'">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of links" [value]="option.name">{{ option.name }}</mdb-select-option>
                      </mdb-select-2>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </form>
        </div>

      </div>
      <div class="modal-footer modals-footer">
        <!-- <button type="button" mdbBtn color="secondary" size="sm" class="bwaves-light" data-dismiss="modal" (click)="addModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect>Save changes</button> -->
        <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()"
          mdbWavesEffect>Cancelar</button>
        <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmit()"
          mdbWavesEffect>Guardar</button>
      </div>
    </div>
  </div>
</div>


<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal"
  (reponseStateModalAccept)="onResponseNormal($event)"></app-modal-accept>