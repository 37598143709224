import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Backoffice } from '../../../interfaces/backoffice';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

@Injectable({
  providedIn: 'root'
})
export class BackofficeService {

  constructor(private http: HttpClient) { }

  getBackoffice( type:string ): Observable<Backoffice[]>{
    const url = `${ base_url }/backoffice/type/${type}`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: Backoffice[] }) => resp.objects )
              );
  }
}
