
<app-navbar-preview></app-navbar-preview>
<main class="background-preview padding-view">
    <div class="breadcrumb-style">
        <mdb-breadcrumb customClass="white" class="breadcrumbs pl-4">
            <mdb-breadcrumb-item class="breadcrumbs-link">Faenas</mdb-breadcrumb-item>
        </mdb-breadcrumb>
     </div>
    <div class="container-fluid">
        <div class="text-center">
            <h1 class="title-preview mb-4">Faenas</h1>
        </div>

        <div class="card-columns container pb-4 card-config">
            <app-card [mine]="mine" [index]="i+1"
                *ngFor="let mine of mines; let i=index">
            </app-card>
        </div>
    </div>
</main>

<ngx-spinner bdColor = "rgba(0,0,0,0.85)" size = "medium" color = "#fff" type = "line-spin-fade" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>


