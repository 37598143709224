
import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import DxfParser from 'dxf-parser';
import { saveAs } from 'file-saver';
import { ModalInfo } from 'src/app/models/modal-info';

@Component({
  selector: 'app-zone-back',
  templateUrl: './zone-back.component.html',
  styleUrls: ['./zone-back.component.scss']
})

export class ZoneBackComponent implements OnInit, OnDestroy {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();
  @Output() reponseObject = new EventEmitter();
  @Output() responseAddObject = new EventEmitter();

  modalInfo: ModalInfo = new ModalInfo();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  idObject: string = '';
  titleModal: string = 'Agregar Zona'

  types = [{"id": 1, "nombre":"Vigilancia"}, {"id": 2, "nombre":"Monitoreo"}]

  sectorsMine = [];
  objectsSubscription: Subscription;

  geojson: any;

  constructor( private fb: FormBuilder, private minesbackService: MinesbackService, private router: Router, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id'); 
   }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'sector_faena_id': ['', Validators.required],
      'nombre': ['', Validators.required],
      'matriz': [''],
      'geometry': [''],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'sector_faena_id': this.data.sector_faena_id,
      'nombre': this.data.nombre,
      'geometry': [''],
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");
      this.addModal.show();
      this.getSectorsByMine(this.idMine);
      this.reponseStateModalForm.emit(false);

      if(this.objectForm){
        if(this.typeModal == 'edit'){
  
          console.log('EDIT ZONE: '+this.data.id);
          this.titleModal = 'Editar Zona'
          this.idObject = this.data.id;
          this.patchForm();
        }else{ 
          this.titleModal = 'Agregar Zona'
          this.objectForm.reset();
        }
      }
    }
  }

  showAddModal(){

    this.addModal.show();
  }

  onResponseAlert(event){

    this.stateModalAlert = event;
  }

  onSubmitObject(){

    console.log('ON SUBMIT');
    if(this.objectForm.invalid){
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes'); 
      return
    }

    this.typeModal == 'new' ? this.createZone() : this.updateZone();
  }

  geo = {
    'type': 'Angular',
  }
  createZone(){

    this.objectForm.get('geometry').setValue(this.geojson);
    this.minesbackService.createZone(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
      this.responseAddObject.emit(resp)
      this.reponseRefresh.emit(false);
      this.messageAlert(true, 'success', 'Zona creada correctamente');
      this.objectForm.reset();
      this.addModal.hide();  
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
    }
    });
  }

  updateZone(){

    console.log('UPDATE ZONE');
    console.log('UPDATE ZONE'+this.objectForm.value.nombre);
    console.log('UPDATE ZONE'+this.geojson);

    this.objectForm.get('geometry').setValue(this.geojson);
    this.minesbackService.updateZone(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{ 
        const formValue = { ...this.objectForm.value };
        formValue['id'] = this.idObject;
        this.reponseObject.emit(formValue);
        this.reponseRefresh.emit(false);   
        this.objectForm.reset();
        this.addModal.hide(); 
        this.messageAlert(true, 'success', 'Zona actualizada correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar zona');
    },
    complete: () => {  
        console.log('Completed');
    }
    });
  }
  
  getSectorsByMine(id: string){
  
    this.objectsSubscription = this.minesbackService.getSectorsByMine(id).subscribe({
      next: (resp) => { 
         this.sectorsMine = resp;
         console.log('Response Sector: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  onFileChange(event: any): void {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      const selectedFile = fileInput.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        // Llama a la función para procesar el contenido del archivo DXF
        this.processDxfContent(reader.result as string);
      };

      // Lee el contenido del archivo como texto
      reader.readAsText(selectedFile);
    }
  }

  // Método para procesar el contenido DXF y convertirlo a GeoJSON
  processDxfContent(dxfContent: string): void {
    // Crea una instancia del parser DXF
    const parser = new DxfParser();

    // Parsea el contenido DXF
    const dxfData = parser.parseSync(dxfContent);

    // Convierte el resultado a GeoJSON (implementa la lógica según tus necesidades)
    const geoJsonData = this.convertToGeoJson(dxfData);

    // Guarda el resultado como archivo GeoJSON
    const geoJsonBlob = new Blob([JSON.stringify(geoJsonData)], { type: 'application/json' });
    saveAs(geoJsonBlob, 'resultado.geojson');
    this.geojson = geoJsonData;
  }

  convertToGeoJson(dxfData: any): any {
    const features = [];
  
    // Itera sobre las entidades DXF
    for (const entity of dxfData.entities) {
      // Verifica el tipo de entidad (puedes extender esto según las entidades DXF que manejes)
      if (true) {
        // Convierte la entidad de línea a un objeto GeoJSON
        console.log('LINE');
        const feature = {
          type: 'Feature',
          properties: {
            Layer: entity.layer,
            SubClasses: entity.subClasses,
            EntityHandle: entity.handle,
          },
          geometry: {
            type: 'LineString',
            coordinates: entity.vertices.map((vertex:any) => [vertex.x, vertex.y, vertex.z]),
          },
        };
  
        // Agrega la entidad a la lista de features
        features.push(feature);
      }
      // Puedes agregar más lógica para otros tipos de entidades DXF (puntos, polígonos, etc.)
    }
    console.log('FEATURES: '+features);
    // Crea el objeto GeoJSON en el formato deseado
    const geoJsonData = {
      type: 'FeatureCollection',
      name: 'entities',
      crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::31370' } },
      features: features,
    };
  
    return geoJsonData;
  }
  
  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
