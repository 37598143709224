
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { Instrument } from '../../../models/instrument';

import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LinkLosses } from '../../../models/link-losses';
import * as moment from 'moment';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class ConnectionLinksService {

  constructor(private http: HttpClient) { }

  getInstruments(): Observable<Instrument[]>{
    const url = `${ base_url_fast }/instrumentos`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Instrument[] }) => resp.objects )
              );
  }

  getConnectionLinksByMine(id: string): Observable<any[]>{

    const url = `${ base_url_fast }/faena/enlaces/${id}`;
    return this.http.post( url, null, httpOptions)
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  creatConnectionLink(form: any) {
    const url = `${ base_url_fast }/enlaces`;
    const req = this.http.post(url, form, httpOptions);
    return req;
  }

  // getLinkLossesByMine(id: string): Observable<LinkLosses[]>{
  //   const url = `${ base_url_fast }/enlaces/estado/all/${id}`;
  //   return this.http.post( url, null )
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: LinkLosses[] }) => resp.objects )
  //             );
  // }

  getLinkLossesByMine(id: string): Observable<LinkLosses[]>{
    const url = `${ base_url_fast }/enlaces/estado/all/${id}`;
    return this.http.post( url, null, httpOptions )
        .pipe(
          map( (resp: {ok: boolean, objects: LinkLosses[] }) => 
          resp.objects.map( data => {
            return {
              id: data.id,
              enlace_id: data.enlace_id,     
              fecha_inicio: moment(data.fecha_inicio).format('DD-MM-YYYY HH:mm'),
              fecha_termino: moment(data.fecha_termino).format('DD-MM-YYYY HH:mm'),
              tiempo_caido: data.tiempo_caido,
              observacion: data.observacion,

            }
          })
          )
        )
  }


  createLinkLosses(form: any, id: string) {


    let init_day = moment(form.fecha_inicio_date).get('D');
    let init_month = moment(form.fecha_inicio_date).get('M');
    let init_year = moment(form.fecha_inicio_date).get('y');

    let init_hour = moment( form.fecha_inicio_hora, 'HH-mm a').get('hour');
    let init_minutes = moment( form.fecha_inicio_hora, 'HH-mm a').get('minutes');
  
    let date_init = moment({ year :init_year, month :init_month, day :init_day, 
      hour :init_hour, minute :init_minutes, second :0, millisecond :0}).format('YYYY-MM-DD'+'T'+'HH:mm:ss');

    let end_day = moment(form.fecha_fin_date).get('D');
    let end_month = moment(form.fecha_fin_date).get('M');
    let end_year = moment(form.fecha_fin_date).get('y');

    let end_hour = moment( form.fecha_fin_hora, 'HH-mm a').get('hour');
    let end_minutes = moment( form.fecha_fin_hora, 'HH-mm a').get('minutes');
  
    let date_end = moment({ year :end_year, month :end_month, day :end_day, 
      hour :end_hour, minute :end_minutes, second :0, millisecond :0}).format('YYYY-MM-DD'+'T'+'HH:mm:ss');

    let body = {
        "enlace_id": id,
        "fecha_inicio": date_init,
        "fecha_termino": date_end,
        "observacion": form.observacion,
      }

    const url = `${base_url_fast}/enlaces/estado`;
    const req = this.http.post(url, body, httpOptions);
    return req;
  }



}
