import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { InstrumentsService } from 'src/app/services/backoffice/instruments/instruments.service';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit, OnDestroy {

  brands = [];

  headElements = ['N°', 'nombre', 'Tipo', 'Herramientas'];
  headTable = ['nombre', 'tipo', 'tools'];
  colspanLarge: number = 4;

  modalInfo: ModalInfo = new ModalInfo();
  stateModalAlert: boolean = false;
  stateModalTable: boolean = false;

  stateModalType: boolean = false;
  typeObject: string = 'new';
  dataObject: any;

  objectsSubscription: Subscription;
  objectSelected: any;

  constructor( private instrumentsService: InstrumentsService, private router: Router, public authService: AuthService) { }

  ngOnInit(): void {

    this.getBrands();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
 }

  onreponsDeleteObjectTable($event){
    console.log('COMPANY1: '+$event.uid);
    this.objectSelected = $event;
    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+$event.nombre); 
  }

  onreponseUpdateObjectTable($event){
    console.log('UPDATE: ');
    this.typeObject = 'edit';
    this.stateModalType = true;
    this.dataObject = $event;
  }

  onResponseAlert(event){
    this.stateModalAlert = false;
  }

  onReponseDeleteObjectModal($event){

    this.deleteObject(this.objectSelected.id);
  }

  onReponseStateModalFormMine(event){
    this.stateModalType = event;
  }
  
  addObject(){
    this.typeObject = 'new';
    this.stateModalType = true;
  }
  
  onResponseRefresh(){
    console.log('REFRESH');
    this.getBrands();
  }

  getBrands(){

    this.objectsSubscription = this.instrumentsService.getBrandInstrument( ).subscribe({
      next: (resp) => { 
         this.brands = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  
  deleteObject(id: string){

    this.instrumentsService.deleteBrand(id).subscribe({
      next: (resp) => { 
         //this.getMines();  
         this.brands = this.brands.filter(objeto => objeto.id !== this.objectSelected.id);
         this.messageAlert(true, 'success', 'La marca fue eliminada satisfactoriamente'); 
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  } 

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
