
import { KpiComponent } from './kpi/kpi.component';
import { A2vLossesComponent } from './a2v/a2v-losses/a2v-losses.component';
import { InfraestructureComponent } from './itInfrastructure/infraestructure/infraestructure.component';
import { ItlossesComponent } from './itInfrastructure/itlosses/itlosses.component';
import { ConnectionLinksComponent } from './links/connection-links/connection-links.component';
import { LossesComponent } from './links/losses/losses.component';
import { DailyShiftsComponent } from './daily-shifts/daily-shifts.component';
import { DailyShiftComponent } from './daily-shift/daily-shift.component';
import { PreviewComponent } from './preview/preview.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AuthGuard } from '../guards/auth.guard';
import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { ConfigComponent } from './config/config.component';
import { MinesComponent } from './mines/mines.component';
import { InstrumentsComponent } from './instruments/instruments/instruments.component';
import { InstrumentComponent } from './instruments/instrument/instrument.component';
import { TimesComponent } from './instruments/times/times.component';
import { PositionsComponent } from './instruments/positions/positions.component';

import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { Ita2vComponent } from './a2v/ita2v/ita2v.component';
import { RoleGuard } from '../guards/role.guard';
// import { AuthGuard2 } from '../guards/auth.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);

const routes: Routes = [

  { path: 'preview', component: PreviewComponent, canActivate: [ AuthGuard ]},
  { 
    path: 'emt', 
    component: PagesComponent,

    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: ['admin', 'geotecnico', 'desarrollo', 'supervisor', 'vigilante', 'pmo'] }, // Define el rol esperado para esta ruta
    
    children: [
      // { path: '', component: HomeComponent},
      { path: 'home/:id', component: HomeComponent},
      { path: 'dailyshift', component: DailyShiftComponent},
      { path: 'dailyshifts', component: DailyShiftsComponent},
      { path: 'config', component: ConfigComponent},
      { path: 'mines', component: MinesComponent},

      { path: 'instruments', component: InstrumentsComponent},
      { path: 'instrument/:id', component: InstrumentComponent},
      { path: 'times/:id', component: TimesComponent},
      { path: 'positions/:id', component: PositionsComponent},

      { path: 'connectionLinks', component: ConnectionLinksComponent},
      { path: 'connectionLosses/:id', component: LossesComponent},

      { path: 'positions/:id', component: PositionsComponent},

      { path: 'itinfrastructure', component: InfraestructureComponent},
      { path: 'itinfrastructureLosses/:id', component: ItlossesComponent},

      { path: 'ita2v', component: Ita2vComponent},
      { path: 'ita2vLosses/:id', component: A2vLossesComponent},
      { path: 'kpi', component: KpiComponent},

    ]

},

    ];
    // canActivate: [ AuthGuard ],
    // canLoad: [ AuthGuard ],
  @NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
