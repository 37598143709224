import { Subscription } from "rxjs";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMyOptions, MDBDatePickerComponent, ModalDirective } from "ng-uikit-pro-standard";
import { ModalInfo } from "src/app/models/modal-info";
import { Router } from "@angular/router";
import { TrainingsService } from "src/app/services/backoffice/training/trainings.service";
import { MinesService } from "src/app/services/backoffice/mine/mines/mines.service";
import { AuthService } from "src/app/services/backoffice/auth/auth.service";

@Component({
  selector: "app-trainings",
  templateUrl: "./trainings.component.html",
  styleUrls: ["./trainings.component.scss"],
})
export class TrainingsComponent implements OnInit, OnDestroy {
  @ViewChild("datePicker", { static: true })
  datePicker!: MDBDatePickerComponent;
  @ViewChild("addModal", { static: true }) addModal!: ModalDirective;

  elements: any[] = [];
  mineForm: FormGroup;
  companySelected: any;

  //headElements: string[] = ['N°', 'Nombre', 'Apellido', 'Nivel', 'Evaluación', 'Geotecnia', 'A2V', 'Prot/Proc', 'Guardian(IBIS)', 'HMI(Reutech)', 'SSR Viewer (GP FX O XT)', 'Ranger (Fast GBSAR)', 'Geomos EETT Leica', 'T4D Control', 'ArcGis', 'Bosh','Herramientas'];
  //headTable: string[] = ['nombre', 'apellido_p', 'nivel_usuario', 'Mod1', 'Mod2', 'Mod3','Geotecnia', 'Guardian(IBIS)', 'HMI(Reutech)', 'SSR Viewer (GP FX O XT)', 'Ranger (Fast GBSAR)', 'Geomos EETT Leica', 'T4D Control', 'ArcGis', 'Bosh', 'tools'];
  //colspanLarge: number = 8;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateModalTable: boolean = false;
  stateModalState: boolean = false;
  stateModalType: boolean = false;
  stateModalNote: boolean = false;
  typeObject: string = "new";
  dataObject: any;

  typeObjectNote: string = "new";
  dataObjectNote: string = "";

  structure_table: any = [];

  myDatePickerOptions: IMyOptions = {};

  mineFilter: string;
  lastNameFilter: string = "";
  nameFilter: string = "";
  originalElements = [];

  types = [];
  mines = [];

  minesSubscription: Subscription;
  trainingSubscription: Subscription | undefined;

  constructor( private trainingsService: TrainingsService, private mineService: MinesService, private router: Router, private formBuilder: FormBuilder, public authService: AuthService ) {}

  ngOnInit(): void {

    this.initForm();
    this.getTrainingsFull();
    this.getMines();
  }

  ngOnDestroy(): void {
    
    this.trainingSubscription?.unsubscribe();
    this.minesSubscription?.unsubscribe();
  }
  
  initForm(){

    this.mineForm = this.formBuilder.group({
      nombre: ["", Validators.required],
    });
  }

  onReponsDeleteObjectTable($event) {
    console.log("COMPANY1: " + $event.uid);
    this.companySelected = $event;
    this.messageAlert(true, "warning", "Esta seguro de eliminar " + $event.nombre_corto);
  }

  onReponseUpdateObjectTable($event) {
    console.log("UPDATE: ");
    this.typeObject = "edit";
    this.stateModalType = true;
    this.dataObject = $event;
  }

  onResponseNormal(event) {
    this.stateModal = false;
    console.log("RESPONSE MINE NORMAL");
  }

  onSubmit() {}


  onReponseStateModalFormMine(event) {
    this.stateModalType = event;
  }

  onReponseStateModalType(event) {
    console.log("onReponseStateModalType");
    this.stateModalType = event;
  }

  onReponseStateModalState(event) {
    this.stateModalState = event;
    console.log("onReponseStateModalState: " + event);
  }

  onReponseStateModalNote(event) {
    this.stateModalNote = event;
  }

  addMine() {
    this.typeObject = "new";
    this.stateModalType = true;
  }

  onResponseRefresh() {
    console.log("REFRESH");
    this.getTrainingsFull();
  }

  addNote() {
    this.typeObjectNote = "new";
    this.stateModalNote = true;
  }

  addState() {
    this.stateModalState = true;
    // this.typeObjectState = 'new'
  }

  addType() {
    this.stateModalType = true;
  }

  editeObject(object: any) {
    // console.log('ID USER: '+object.id_usuario);
    this.dataObjectNote = object.id_usuario;
    this.typeObjectNote = "edit";
    console.log("typeObject: " + this.typeObject);

    this.stateModalNote = true;
  }

  getSelectedCamera(event: any) {
    console.log("EVENT: " + event.nombre_largo);
    this.mineFilter = event.nombre_largo;
  }

  filter() {
    console.log("FILTER: " + this.mineFilter);
    this.elements = this.originalElements;

    if (this.nameFilter.length > 0)
      this.elements = this.elements.filter(
        (object) => object.nombre.toLowerCase() == this.nameFilter.toLowerCase()
      );

    if (this.lastNameFilter.length > 0)
      this.elements = this.elements.filter(
        (object) =>
          object.apellido_p.toLowerCase() == this.lastNameFilter.toLowerCase()
      );

    if (this.mineFilter.length > 0)
      this.elements = this.elements.filter((object) =>
        object.faenas_nombres.includes(this.mineFilter)
      );
  }

  resetFilter() {
    this.elements = this.originalElements;
  }

  getTrainingsFull() {
    this.trainingSubscription = this.trainingsService.getTrainingsFull().subscribe({
        next: (resp) => {
          this.structure_table = resp;
          this.originalElements = this.structure_table.elements;
          this.elements = this.originalElements;
          this.stateModalTable = true;
          console.log("Response: " + resp);
        },
        error: (err) => {
          console.log("Received an error: " + err.message);
          this.messageAlert(true, "error", err.message);
        },
        complete: () => {
          console.log("Completed");
        },
      });
  }

  getMines() {
    this.minesSubscription = this.mineService.getMines().subscribe({
      next: (resp) => {
        this.mines = resp;
        console.log("Response: " + resp);
      },
      error: (err) => {
        console.log("Received an error: " + err.message);
        this.messageAlert(true, "error", "Error al crear usuario");
      },
      complete: () => {
        console.log("Completed");
      },
    });
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModal = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
