
<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">{{ titleModal }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body">
              <div class="container mt-4 px-4">
                <form class="text-center forms-text" [formGroup]="objectForm">
            
                    <div class="mt-5" style="background-color: white;">
          
                        <div class="sub-headers-forms">
                          <span class="title-forms">General</span>
                        </div>
          
                        <div class="px-4">
                          <div class="row">
                            <div class="md-form md-outline mt-5 col-md-3">
                              <input type="text" formControlName="rut" id="mineFormId0" class="form-control" mdbInput
                                mdbValidate />
                              <label for="mineFormId3" class="pl-4">Rut (*)</label>
                              <mdb-error *ngIf="getFieldControl('rut')?.invalid && (getFieldControl('rut')?.dirty || getFieldControl('rut')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('rut')?.valid && (getFieldControl('rut')?.dirty || getFieldControl('rut')?.touched)" class="pl-3">Entrada válida</mdb-success>
                            </div>

                            <div class="md-form md-outline mt-5 col-md-3">
                              <input type="text" formControlName="name1" id="mineFormId1" class="form-control" mdbInput mdbValidate />
                              <label for="mineFormId1" class="pl-4">Nombre (*)</label>
                              <mdb-error *ngIf="getFieldControl('name1')?.invalid && (getFieldControl('name1')?.dirty || getFieldControl('name1')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('name1')?.valid && (getFieldControl('name1')?.dirty || getFieldControl('name1')?.touched)" class="pl-3">Entrada válida</mdb-success>
                            </div>                 

                            <div class="md-form md-outline mt-5 col-md-3">
                              <input type="text" formControlName="last_name1" id="mineFormId2" class="form-control" mdbInput mdbValidate />
                              <label for="mineFormId1" class="pl-4">Apellido paterno (*)</label>
                              <mdb-error *ngIf="getFieldControl('last_name1')?.invalid && (getFieldControl('last_name1')?.dirty || getFieldControl('last_name1')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('last_name1')?.valid && (getFieldControl('last_name1')?.dirty || getFieldControl('last_name1')?.touched)" class="pl-3">Entrada válida</mdb-success>
                            </div>
                            
                            <div class="md-form md-outline mt-5 col-md-3">
                              <input type="text" formControlName="last_name2" id="mineFormId3" class="form-control" mdbInput mdbValidate />
                              <label for="mineFormId1" class="pl-4">Apellido Materno (*)</label>
                              <mdb-error *ngIf="getFieldControl('last_name2')?.invalid && (getFieldControl('last_name2')?.dirty || getFieldControl('last_name2')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('last_name2')?.valid && (getFieldControl('last_name2')?.dirty || getFieldControl('last_name2')?.touched)" class="pl-3">Entrada válida</mdb-success>
                            </div>
                          
                            <div class="md-form md-outline mt-5 col-md-3">
                              <input type="text" formControlName="email" id="mineFormId4" class="form-control" mdbInput mdbValidate />
                              <label for="mineFormId3" class="pl-4">Email (*)</label>
                              <mdb-error *ngIf="getFieldControl('email')?.invalid && (getFieldControl('email')?.dirty || getFieldControl('email')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('email')?.valid && (getFieldControl('email')?.dirty || getFieldControl('email')?.touched)" class="pl-3">Entrada válida</mdb-success>
                            </div>
                            
                            <div class="md-form md-outline mt-5 col-md-3">
                              <input type="text" formControlName="telephone" id="mineFormId5" class="form-control" mdbInput mdbValidate />
                              <label for="mineFormId4" class="pl-4">Teléfono (*)</label>
                              <mdb-error *ngIf="getFieldControl('telephone')?.invalid && (getFieldControl('telephone')?.dirty || getFieldControl('telephone')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('telephone')?.valid && (getFieldControl('telephone')?.dirty || getFieldControl('telephone')?.touched)" class="pl-3">Entrada válida</mdb-success>
                            </div>
                            
                            <div class="md-form md-outline mt-5 col-md-3">
                              <input type="text" formControlName="address" id="mineFormId6" class="form-control" mdbInput mdbValidate />
                              <label for="mineFormId5" class="pl-4">Dirección (*)</label>
                              <mdb-error *ngIf="getFieldControl('address')?.invalid && (getFieldControl('address')?.dirty || getFieldControl('address')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('address')?.valid && (getFieldControl('address')?.dirty || getFieldControl('address')?.touched)" class="pl-3">Entrada válida</mdb-success>
                            </div>
                            
                            <div class="md-form md-outline mt-5 col-md-3">
                              <input type="text" formControlName="educational_level" id="mineFormId7" class="form-control" mdbInput mdbValidate />
                              <label for="mineFormId7" class="pl-4">Nivel de estudio (*)</label>
                              <mdb-error *ngIf="getFieldControl('educational_level')?.invalid && (getFieldControl('educational_level')?.dirty || getFieldControl('educational_level')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('educational_level')?.valid && (getFieldControl('educational_level')?.dirty || getFieldControl('educational_level')?.touched)" class="pl-3">Entrada válida</mdb-success>
                            </div>
                            
                            <div class="md-form md-outline mt-5 col-md-3">
                              <input type="text" formControlName="role" id="mineFormId8" class="form-control" mdbInput mdbValidate />
                              <label for="mineFormId8" class="pl-4">Rol (*)</label>
                              <mdb-error *ngIf="getFieldControl('role')?.invalid && (getFieldControl('role')?.dirty || getFieldControl('role')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('role')?.valid && (getFieldControl('role')?.dirty || getFieldControl('role')?.touched)" class="pl-3">Entrada válida</mdb-success>
                            </div>
                            
                            <div class="md-form md-outline mt-5 col-md-3">
                              <mdb-select-2 [outline]="true" placeholder="Seleccionar nivel" label="Nivel de Usuario (*)" [formControlName]="'user_level'">
                                <mdb-select-option *ngFor="let option of level" [value]="option.name">{{ option.name }}</mdb-select-option>
                              </mdb-select-2>
                            </div>

                            <div class="input-time md-form md-outline mt-5 col-md-3">
                              <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                                <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                                  label="Fecha de ingreso EMT (*)"  formControlName="start_date">
                                </mdb-date-picker>            
                              </div>
                            </div>   

                            <div class="input-time md-form md-outline mt-5 col-md-3">
                              <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                                <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                                  label="Fecha de expiración"  formControlName="end_date">
                                </mdb-date-picker>            
                              </div>
                            </div>   

                            <div class="md-form md-outline mt-5 col-md-3">
                              <mdb-select-2 [outline]="true" placeholder="Seleccionar estado" label="Estado (*)" [formControlName]="'active'">
                                  <mdb-select-option *ngFor="let option of state" [value]="option.value">{{ option.name }}</mdb-select-option>
                              </mdb-select-2>
                            </div>                                                    
                          </div>
                        </div>  
                    </div>         
              </form>
              </div>
              
            </div>
            <div class="modal-footer modals-footer">
                <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
                <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitObject()" mdbWavesEffect>Guardar</button>
            </div>
        </div>
    </div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
  (reponseStateModalAccept)="onResponseAlert($event)"></app-modal-accept>

