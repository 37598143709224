
import { AuthFireService } from '../../services/configuration/auth/auth-fire.service';
import { AuthService } from './../../services/backoffice/auth/auth.service';
import { Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/backoffice/users/users.service';
import { ModalInfo } from 'src/app/models/modal-info';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  modalInfo: ModalInfo = new ModalInfo();
  stateModalAlert: boolean = false;
  version: string = 'v0.2.1'
  user: User[] = [];

  userSubscription: Subscription;

  constructor(public formBuilder: FormBuilder, private router: Router, private userService: UsersService, private authFireService: AuthFireService, public authService: AuthService) { }

  ngOnInit(): void {

    this.initForm();
    console.log('Version: '+this.version)
  }


  ngOnDestroy(){

    this.userSubscription?.unsubscribe();
 }

  initForm(){

    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email, Validators.minLength(8), Validators.maxLength(25)]],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
    });
  }

  login() {
  
    if (this.loginForm.invalid) {
  
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');
      return;
    }
  
    this.loginUser();
  }
  
  onResponseNormal(event){
    this.stateModalAlert = false;
  }
  
  resendPass(){
  
    this.router.navigateByUrl('/resend');
  }
  

  onClick() {
    this.authFireService.loginWithGoogle()
      .then(response => {
        console.dir('LOGIN GOOGLE: '+response);
        localStorage.setItem('google', response.user.displayName );
        this.getUserByEmail( response.user.email);
        this.authService.login();

        setTimeout(() => {
          this.router.navigate(['/preview']);
        }, 2000);
      })
      .catch(error => {
      console.log(error);
      this.messageAlert(true, 'error', 'Error con la autentificación');
      }
      )

  }

  loginUser(){

    this.userService.login( this.loginForm.value ).subscribe({
      next: (resp) =>{  
        console.log('Response: ' + resp.ok);
        this.user = resp;
      },
      error: (err) => { 
        this.messageAlert(true, 'error', err.error.msg);
        console.log('Received an error: ' + err)},
      complete: () => { 
        setTimeout(() => {
          this.router.navigateByUrl('/preview');
        }, 2000);
          console.log('Completed'); }
    });
  }

  getUserByEmail(email: string){

    this.userSubscription = this.userService.getUserByEmail( email ).subscribe({
      next: (resp) => { 
         //this.user = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}