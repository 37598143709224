
<main class="background-preview padding-view">
    <div class="breadcrumb-style">
        <mdb-breadcrumb customClass="white" class="breadcrumbs pl-4">
            <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/home">Backoffice</mdb-breadcrumb-item>
            <mdb-breadcrumb-item class="breadcrumbs-link">Instrumentos</mdb-breadcrumb-item>
        </mdb-breadcrumb>
     </div>
    <div class="container-fluid">
        <div class="text-center">
            <h1 class="title-preview">Backoffice</h1>
            <h2 class="sub-title-preview">Instrumentos</h2>
        </div>
        <div class="card-columns container pb-4 card-config">

            <app-cardbackoffice (elementSelect)="goElement( $event )" [element]="element" [index]="i+1"
                *ngFor="let element of elements; let i=index ">
            </app-cardbackoffice>

        </div>
    </div>
</main>

<ngx-spinner bdColor = "rgba(0,0,0,0.85)" size = "medium" color = "#fff" type = "line-spin-fade" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>
