import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { PreviewComponent } from './preview/preview.component';
import { CardComponent } from './preview/card/card.component';
import { ComponentsModule } from '../components/components.module';
import { BreadcrumbModule, ButtonsModule, CharCounterModule, CollapseModule, DatepickerModule, FileInputModule, IconsModule, InputsModule, MDBBootstrapModulesPro, MdbTimePickerModule, TableModule, WavesModule  } from 'ng-uikit-pro-standard';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { DailyShiftComponent } from './daily-shift/daily-shift.component';
import { DailyShiftsComponent } from './daily-shifts/daily-shifts.component';
import { RouterModule } from '@angular/router';
import { ConfigComponent } from './config/config.component';
import { MinesComponent } from './mines/mines.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InstrumentsComponent } from './instruments/instruments/instruments.component';
import { InstrumentComponent } from './instruments/instrument/instrument.component';
import { PositionsComponent } from './instruments/positions/positions.component';
import { TimesComponent } from './instruments/times/times.component';
import { LossesComponent } from './links/losses/losses.component';
import { ConnectionLinksComponent } from './links/connection-links/connection-links.component';
import { A2vLossesComponent } from './a2v/a2v-losses/a2v-losses.component';

import { Ita2vComponent } from './a2v/ita2v/ita2v.component';
import { KpiComponent } from './kpi/kpi.component';
import { InfraestructureComponent } from './itInfrastructure/infraestructure/infraestructure.component';
import { ItlossesComponent } from './itInfrastructure/itlosses/itlosses.component';


@NgModule({
  declarations: [
    PagesComponent,
    PreviewComponent,
    CardComponent,
    HomeComponent,
    DailyShiftComponent,
    DailyShiftsComponent,
    ConfigComponent,
    MinesComponent,
    InstrumentsComponent,
    InstrumentComponent,
    PositionsComponent,
    TimesComponent,
    LossesComponent,
    ConnectionLinksComponent,
    A2vLossesComponent,
    InfraestructureComponent,
    Ita2vComponent,
    ItlossesComponent,
    KpiComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ComponentsModule,
    ReactiveFormsModule,
    SharedModule,
    MdbTimePickerModule,
    WavesModule,
    TableModule,
    InputsModule,
    IconsModule,
    InputsModule, WavesModule, ButtonsModule, CharCounterModule, CollapseModule,
    FormsModule,
    BreadcrumbModule,
    DatepickerModule,
    NgxSpinnerModule,
    FileInputModule,
    MDBBootstrapModulesPro.forRoot()
  ],
  schemas:  [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]

})
export class PagesModule { }


