	<div class="view intro background-login">
	  <div class="full-bg-img rgba-stylish-strong mask flex-center">
	    <div class="container container-login z-depth-5 wow fadeIn" data-wow-delay="5s">
	      <div class="row">
			<div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto pt-sm-5 pb-sm-2">
				<div class="d-flex justify-content-center">
					<img src="/assets/emt2.png" width="270px" heigth="210px" alt="image">
				</div>
			</div>
	        <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto pt-2">

	          <!--Form with header-->
	          <mdb-card bgColor="login-card" class="z-depth-1" style="animation-name: none; visibility: visible;">
	            <mdb-card-body>
					<h2 class="text-center title-login pb-3">Restablecer Contraseña</h2>
                    <p class="text-center text-recover">Ingresa tu correo electrónico y te enviaremos las instrucciones para restablecer tu contraseña.</p>
					<form [formGroup]="resendForm" (ngSubmit)="resend()"> 
						<div class="md-form md-outline pb-2 input-login">
						  <mdb-icon fas icon="envelope" class="prefix"></mdb-icon>
						  <input mdbInput mdbValidate formControlName="email"type="email" class="form-control" id="form9">
						  <label for="form9" style="color: black;">Email</label>
						  <mdb-error *ngIf="email.invalid && (email.dirty || email.touched)" style="color: red;">Input invalid</mdb-error>
						  <mdb-success *ngIf="email.valid && (email.dirty || email.touched)" style="color: green;">Input valid</mdb-success>
						</div>
						<div class="pt-3">
							<button class="btn-color" mdbBtn type="submit" block="true" mdbWavesEffect>ENVIAR</button>
							<p class="text-center text-recover pt-3"><a (click)="returnLogin()">Volver al Inicio</a></p>							
						</div>
					  </form>
	            </mdb-card-body>
	          </mdb-card>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>


<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
	(reponseStateModalAccept) = "onResponseNormal($event)"></app-modal-accept>
