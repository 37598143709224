<div class="container-fluid mt-3 px-0 mb-2">
  <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link mt-3 p-0 mb-0 pb-0">
    <mdb-breadcrumb-item class="active">KPI</mdb-breadcrumb-item>
  </mdb-breadcrumb>
  <div class="row">
    <div class="col-6 date-div">
      <p class="pl-1">Rango de fechas:</p>
      <p class="pl-3">{{ dateInit }}</p>
      <p class="pl-3">{{ dateEnd }}</p>
    </div>
    <div class="col-6 button-div">

      <button type="button" mdbBtn class="waves-light button-accept-modals save-button" (click)="createPng()"
        mdbWavesEffect>Imagen
      </button>

      <button type="button" mdbBtn class="waves-light button-accept-modals save-button" (click)="createExcel()"
        mdbWavesEffect>Excel
      </button>

      <button type="button" mdbBtn class="waves-light button-accept-modals save-button" (click)="modalKpi()"
        mdbWavesEffect>Consultar
      </button>
    </div>
  </div>

  <div class="table-container mb-3" id="htmlTable">
    <table mdbTable bordered="true" id="htmlData" #htmlData small="true" class="z-depth-1 table-body">
      <thead>
        <tr>
          <!-- <th scope="col" class="table-sub-title">Item</th>
          <th scope="col" class="table-sub-title"></th> -->
          <th scope="row" colspan="5" class="kpi-title text-center">KPI</th>
          <!-- <th scope="col" class="table-sub-title"></th>
          <th scope="col" class="table-sub-title">Observaciones</th> -->
        </tr>
        <tr>
          <th *ngFor="let head of headElements" scope="col" class="head-table text-center">{{ head }} </th>
        </tr>

      </thead>
      <tbody>
        <tr mdbTableCol *ngFor="let el of elements">
          <!-- <th scope="row">{{ el.id }}</th> -->

          <!-- <td>{{ el.first }}</td>
          <td>{{ el.last }}</td>
          <td>{{ el.handle }}</td>
          <td>{{ el.handle2 }}</td> -->

          <td *ngIf="el.level == 1" class="table-title">{{ el.item }}</td>
          <td *ngIf="el.level == 1" class="table-title text-center">{{ el.ponderacion }}</td>
          <td *ngIf="el.level == 1" class="table-title text-center">{{ el.disponibilidad }}</td>
          <td *ngIf="el.level == 1" class="table-title text-center">{{ el.utilizacion }}</td>
          <td *ngIf="el.level == 1" class="table-title text-center">{{ el.observacion }}</td>

          <td *ngIf="el.level == 2" class="table-sub-title"><i class="fas fa-circle table-circle pr-3"></i>{{ el.item }}
          </td>
          <td *ngIf="el.level == 2" class="table-sub-title text-center">{{ el.ponderacion }}</td>
          <td *ngIf="el.level == 2" class="table-sub-title text-center">{{ el.disponibilidad }}</td>
          <td *ngIf="el.level == 2" class="table-sub-title text-center">{{ el.utilizacion }}</td>
          <td *ngIf="el.level == 2" class="table-sub-title text-center">{{ el.observacion }}</td>

          <td *ngIf="el.level == 3" class="text-center">{{ el.item }}</td>
          <td *ngIf="el.level == 3" class="text-center">{{ el.ponderacion }}</td>
          <td *ngIf="el.level == 3" class="text-center">{{ el.disponibilidad }}</td>
          <td *ngIf="el.level == 3" class="text-center">{{ el.utilizacion }}</td>
          <td *ngIf="el.level == 3" class="text-center">{{ el.observacion }}</td>

        </tr>
      </tbody>
    </table>
  </div>
</div>



<!-- <button mdbBtn type="button" color="primary" (click)="modalKpi()" mdbWavesEffect>Primary</button> -->


<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
  <div class="modal-dialog modal-size" role="document">
    <div class="modal-content">
      <div class="header-div">
        <h4 class="modal-title-head" id="myModalLabel">Atributos tabla KPI</h4>
        <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close"
          (click)="addModal.hide()">
          <span aria-hidden="true" class="p-0 m-0">×</span>
        </button>
      </div>
      <div class="modal-body modals-body">
        <div class="container mt-4 px-4">
          <form class="text-center row forms-text" [formGroup]="kpiForm">


            <div class="col-12 mx-auto" style="background-color: white;">
              <div class="row">

                <div class="col-12 sub-headers-forms">
                  <span class="title-forms modals-subtitle">Atributos</span>
                </div>

                <div class="col-12">
                  <div class="row px-3">

                    <div class="col-md-4 md-form md-outline">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Tipos"
                        label="Tipos de instrumentos (*)" [formControlName]="'instrumentos'" (ngModelChange)="changedTypes($event)">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of types" [value]="option.value">{{ option.value }}</mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="col-md-4 md-form md-outline">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Instrumentos"
                        label="instrumentos (*)" [formControlName]="'instrumentos'">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of instruments" [value]="option.id">{{ option.codigo }}</mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="col-md-4 md-form md-outline">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Enlaces" label="Enlaces (*)"
                        [formControlName]="'enlace_id'">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of links" [value]="option.id">{{ option.codigo }}</mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="md-form md-outline mt-md-5 col-4">
                      <input type="text" formControlName="ponderacion_radar" id="id1" class="form-control" disabled
                        mdbInput mdbValidate />
                      <label for="materialcontactFormId" class="pl-4">Ponderación radar</label>
                      <!-- <mdb-error *ngIf="id.invalid && (id.dirty || id.touched)">Input Invalido</mdb-error>
                                  <mdb-success *ngIf="id.valid && (id.dirty || id.touched)">Input Valido</mdb-success> -->
                    </div>

                    <div class="md-form md-outline mt-md-5 col-4">
                      <input type="text" formControlName="ponderacion_estacion" id="id2" class="form-control" disabled
                        mdbInput mdbValidate />
                      <label for="materialcontactFormId" class="pl-4">Ponderación estación total</label>
                      <!-- <mdb-error *ngIf="id.invalid && (id.dirty || id.touched)">Input Invalido</mdb-error>
                                  <mdb-success *ngIf="id.valid && (id.dirty || id.touched)">Input Valido</mdb-success> -->
                    </div>

                    <div class="md-form md-outline mt-md-5 col-4">
                      <input type="text" formControlName="ponderacion_camara" id="id3" class="form-control" disabled
                        mdbInput mdbValidate />
                      <label for="materialcontactFormId" class="pl-4">Ponderación cámara</label>
                      <!-- <mdb-error *ngIf="id.invalid && (id.dirty || id.touched)">Input Invalido</mdb-error>
                                  <mdb-success *ngIf="id.valid && (id.dirty || id.touched)">Input Valido</mdb-success> -->
                    </div>

                    <div class="input-time md-form md-outline mt-md-5 col-md-2 col-3">
                      <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                        <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions"
                          [placeholder]="'-'" label="Fecha de Inicio (*)" formControlName="fecha_inicio_date" required>
                        </mdb-date-picker>
                      </div>
                    </div>

                    <div class="md-form  md-form md-outline mt-md-5 col-md-2 col-3">
                      <input type="time" id="id4" name="appt-time" class="form-control"
                        formControlName="fecha_inicio_hora" value="00:00" mdbInput />
                      <label for="input" class="pt-1 pr-3">Hora de Inicio (*)</label>
                    </div>

                    <div class="input-time md-form md-outline mt-md-5 col-md-2 col-3">
                      <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                        <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions"
                          [placeholder]="'-'" label="Fecha de Término (*)" formControlName="fecha_termino_date" required>
                        </mdb-date-picker>
                      </div>
                    </div>

                    <div class="md-form md-outline mt-md-5 col-md-2 col-3">
                      <input type="time" id="id5" name="appt-time" class="form-control"
                        formControlName="fecha_termino_hora" value="00:00" mdbInput />
                      <label for="input" class="pt-1 pr-3">Hora de Término (*)</label>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </form>
        </div>

      </div>
      <div class="modal-footer modals-footer">
        <!-- <button type="button" mdbBtn color="secondary" size="sm" class="bwaves-light" data-dismiss="modal" (click)="addModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect>Save changes</button> -->
        <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()"
          mdbWavesEffect>Cancelar</button>
        <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmit()"
          mdbWavesEffect>Consultar</button>
      </div>
    </div>
  </div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal"
  (reponseStateModalAccept)="onResponseNormal($event)"></app-modal-accept>