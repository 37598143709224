import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a2v-losses',
  templateUrl: './a2v-losses.component.html',
  styleUrls: ['./a2v-losses.component.scss']
})
export class A2vLossesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
