
<div class="container-fluid mt-3 px-0 mb-2">
    <!-- Default form contact -->
    <mdb-card>
      <mdb-card-header class="header-forms white-text text-center">
        <h5 class="header-title-forms">
          Editar Perfil
        </h5>
      </mdb-card-header>
      <mdb-card-body class="px-lg-5 pt-0 body-forms">
        <form class="text-center row forms-text" [formGroup]="userForm" (ngSubmit)="onSubmit()">
  
          <div class="row">
  
            <div class="col-12 mx-auto mt-5" style="background-color: white;">
              <div class="row">
  
                <div class="col-12 sub-headers-forms">
                  <span class="title-forms">General</span>
                </div>

                <div class="col-12">
                  <div class="row container-fluid">
                    <div class="md-form mt-5 col-4">
                      <input type="text" formControlName="name1" id="materialcontactFormId" class="form-control" mdbInput
                        mdbValidate />
                      <label for="materialcontactFormId">Primer Nombre</label>
                      <mdb-error *ngIf="name1.invalid && (name1.dirty || name1.touched)">Input Invalido</mdb-error>
                      <mdb-success *ngIf="name1.valid && (name1.dirty || name1.touched)">Input Valido</mdb-success>
                    </div>

                    <div class="md-form mt-5 col-4">
                      <input type="text" formControlName="name2" id="materialcontactFormId" class="form-control" mdbInput
                        mdbValidate />
                      <label for="materialcontactFormId">Segundo Nombre</label>
                      <mdb-error *ngIf="name2.invalid && (name2.dirty || name2.touched)">Input Invalido</mdb-error>
                      <mdb-success *ngIf="name2.valid && (name2.dirty || name2.touched)">Input Valido</mdb-success>
                    </div>

                    <div class="md-form mt-5 col-4">
                      <input type="text" formControlName="email" id="materialcontactFormId" class="form-control" mdbInput
                        mdbValidate />
                      <label for="materialcontactFormId">Email</label>
                      <mdb-error *ngIf="email.invalid && (email.dirty || email.touched)">Input Invalido</mdb-error>
                      <mdb-success *ngIf="email.valid && (email.dirty || email.touched)">Input Valido</mdb-success>
                    </div>
        
                    <div class="md-form mt-3 col-4">
                      <input type="text" formControlName="last_name1" id="materialcontactFormId" class="form-control" mdbInput
                        mdbValidate />
                      <label for="materialcontactFormId">Apellido Paterno</label>
                      <mdb-error *ngIf="last_name1.invalid && (last_name1.dirty || last_name1.touched)">Input Invalido</mdb-error>
                      <mdb-success *ngIf="last_name1.valid && (last_name1.dirty || last_name1.touched)">Input Valido</mdb-success>
                    </div>

                    <div class="md-form mt-3 col-4">
                      <input type="text" formControlName="last_name2" id="materialcontactFormId" class="form-control" mdbInput
                        mdbValidate />
                      <label for="materialcontactFormId">Apellido Materno</label>
                      <mdb-error *ngIf="last_name2.invalid && (last_name2.dirty || last_name2.touched)">Input Invalido</mdb-error>
                      <mdb-success *ngIf="last_name2.valid && (last_name2.dirty || last_name2.touched)">Input Valido</mdb-success>
                    </div>

                    <div class="md-form mt-3 col-4">
                      <input type="text" formControlName="telephone" id="materialcontactFormId" class="form-control" mdbInput
                        mdbValidate />
                      <label for="materialcontactFormId">Teléfono</label>
                      <mdb-error *ngIf="telephone.invalid && (telephone.dirty || telephone.touched)">Input Invalido</mdb-error>
                      <mdb-success *ngIf="telephone.valid && (telephone.dirty || telephone.touched)">Input Valido</mdb-success>
                    </div>

                  </div>
                </div>
  
  
  
              </div>
  
  
            </div>
  
  
            <div class="col-12 mx-auto mt-5" style="background-color: white;">
              <div class="row">
                <div class="col-12 sub-headers-forms">
                  <span class="title-forms">Avatar</span>
                </div>
                <div class="col-12">
                  <div class="row container-fluid">
                    <div class="col-3 pt-0 mt-0 mx-auto d-flex justify-content-center">
                      <!-- <div class="md-form">
                        <mdb-checkbox mdbValidate formControlName="mineNetworkLink">Enlace Red Mina</mdb-checkbox>
                      </div> -->
                    </div>

                  </div>
                </div>
              </div>
            </div>
  
  
            <div class="col-12 mx-auto mt-5" style="background-color: white;">
              <div class="row">
                <div class="col-12 sub-headers-forms">
                  <span class="title-forms">Observaciones</span>
                </div>
                <div class="col-12">

      
                </div>
              </div>
            </div>
  
  
  
          </div>
  
          <!-- <div class="md-form">
          <mdb-time-picker [buttonLabel]="'Done'" 
          [twelvehour]="true" 
          [darktheme]="false"
          [placeholder]="'Selected time'" 
          [label]="'Light
          version, 12hours'"
          [showClock]="true"></mdb-time-picker>
        </div> -->
  
  
          <!-- <div class="md-form">
          <input
            mdbInput
            type="text"
            id="form1"
            class="form-control"
            [mdbTimePicker]="timepicker"
            
          />
          <label for="form1">Example label</label>
          <mdb-timepicker-toggle
            [mdbTimePickerToggle]="timepicker"
          ></mdb-timepicker-toggle>
          <mdb-timepicker #timepicker></mdb-timepicker>
        </div> -->
  
  
  
  
  
  
        <div class="col-12 mx-auto pt-3">
          <button mdbBtn outline="true" rounded="true" block="false" class="z-depth-0 my-4 waves-effect button-send-forms" style="color: white;"
              mdbWavesEffect type="submit">
              Guardar
          </button>
        </div>
        </form>
      </mdb-card-body>
    </mdb-card>
    <!-- Default form contact -->
  </div>
  
  
  