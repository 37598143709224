import { Monitoring } from '../../../../models/monitoring';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';

import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})

export class MonitoringService {

  constructor(private http: HttpClient) { }

  getMonitoringsByZone( id: string ): Observable<Monitoring[]>{
    console.log('ID ZONE: '+id);
    const url = `${ base_url_fast }/zona/monitoreo/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Monitoring[] }) => resp.objects )
              );
  }

  getMonitoringById(id: string): Observable<Monitoring[]>{

    const url = `${ base_url_fast }/monitoreo/${id}`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Monitoring[] }) => resp.objects )
              );
  }


  updateMonitoring(form: any, id: string): Observable<Monitoring[]>{

    const url = `${ base_url_fast }/monitoreo/${id}`;
    return this.http.put( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Monitoring[] }) => resp.objects )
              );
  }


  createMonitoring(form: any) {
    const url = `${ base_url_fast }/monitoreo`;
    const req = this.http.post(url, form, httpOptions);
    return req;
  }

  getMonitoringsByZoneConcat(idsZone: any ): Observable<Monitoring[]>{

    console.log('ids: '+idsZone);
    const body = { ids: idsZone }
    const url = `${ base_url_fast }/areas/concat`;
    return this.http.post( url, body, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Monitoring[] }) => resp.objects )
              );
  }

  getAreasBySector( id: string ): Observable<Monitoring[]>{
    console.log('ID ZONE: '+id);
    const url = `${ base_url_fast }/sectores/areas/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Monitoring[] }) => resp.objects )
              );
  }

  getAreasByZones( ids: string ): Observable<any[]>{
    console.log('ID ZONE: '+ids);
    const body = {
      "ids": ids,
    }
    const url = `${ base_url_fast }/zonas/areas/array/`;
    return this.http.post( url, body, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  getPhasesBySector( id: string ): Observable<any[]>{
    console.log('ID ZONE: '+id);

    const url = `${ base_url_fast }/faena/sector/fase/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }
}
