

    <div class="row">
        <div class="col-4">


            <div class="div-button">
                <p class="report-last">Ultimo reporte creado: 07/01/2023</p>
                <button mdbBtn class="card-home" type="button" mdbWavesEffect><i class="fas fa-exclamation-triangle"></i>Crear Reporte Alertas/ Alarmas
                </button>
            </div>

            <!-- <div class="div-button">
                <button mdbBtn class="card-home" type="button" mdbWavesEffect><i class="fas fa-exclamation-circle"></i>Alarmas</button>
            </div> -->

            <div class="div-button">
                <p class="report-last">Ultimo reporte creado: 07/01/2023</p>
                <button mdbBtn class="card-home" type="button" mdbWavesEffect><i class="fas fa-calendar-day"></i>Crear Reporte Eventos
                </button>
            </div>
            
            <div class="div-button">
                <p class="report-last">Ultimo reporte creado: 07/01/2023</p>

                <button mdbBtn class="card-home" routerLink="/emt/dailyshift" type="button" mdbWavesEffect><i class="fas fa-user-clock"></i>Crear Reporte Turnos Diarios</button>
            </div>

            <div class="div-button">
                <p class="report-last">Ultimo reporte creado: 07/01/2023</p>

                <button mdbBtn class="card-home" type="button" mdbWavesEffect><i class="fas fa-tasks"></i>Crear Reporte Check List</button>
            </div>

            <div class="div-button">
                <p class="report-last">Ultimo reporte creado: 07/01/2023</p>
                <button mdbBtn class="card-home" type="button" mdbWavesEffect><i class="fas fa-bomb"></i>Crear Reporte Tronaduras</button>
            </div>

            <div class="div-button">
                <p class="report-last">Ultimo reporte creado: 07/01/2023</p>
                <button mdbBtn class="card-home" type="button" mdbWavesEffect><i class="fas fa-wave-square"></i>Crear Reporte Eventos Sismicos</button>
            </div>

            <div class="div-button">
                <p class="report-last">Ultimo reporte creado: 07/01/2023</p>
                <button mdbBtn class="card-home" type="button" mdbWavesEffect><i class="fas fa-bezier-curve"></i>Crear Reporte Estado del Servicio</button>
            </div>

            <div class="div-button pb-3">
                <p class="report-last">Ultimo reporte creado: 07/01/2023</p>
                <button mdbBtn class="card-home" type="button" mdbWavesEffect><i class="fas fa-globe-americas"></i>Crear Reporte Agentes Externos</button>
            </div>

            
        </div>

    </div>

    <ngx-spinner bdColor = "rgba(0,0,0,0.85)" size = "medium" color = "#fff" type = "line-spin-fade" [fullScreen] = "true"><p style="color: white" > Cargando... </p></ngx-spinner>