import { InfraestructuretiService } from '../../../services/configuration/infraestructure/infraestructureti.service';
import { ConnectionLinksService } from '../../../services/configuration/links/connection-links.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-infraestructure',
  templateUrl: './infraestructure.component.html',
  styleUrls: ['./infraestructure.component.scss']
})
export class InfraestructureComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  linkForm: FormGroup;

  mines: any[] = [];
  typeTable: string = 'times';

  headElements = ['N°', 'Codigo', 'Horas de incidencias al mes', 'Herramientas'];
  headTable = ['codigo', 'horas_perdidas', 'tools'];
  colspanLarge: number = 7;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateModalTable: boolean = false;
  idMine: string;

  instrumentSelected: string = '';
  connectionLinksSubscription: Subscription;


  links = [{'name': 'Red Mina'}, {'name': 'VPN Mina'}, {'name': 'Enlace dedicado'}, {'name': 'VPN EMT'}, {'name': 'Red EMT'}];

  constructor(  private fb: FormBuilder, private connectionLinksService: ConnectionLinksService, private infraestructuretiService: InfraestructuretiService, private router: Router) { 

    this.idMine = JSON.parse(localStorage.getItem("mine")).id;
  }

  ngOnInit(): void {

    this.initForm();
    this.getInfaestructuretiByMine(this.idMine);
  }

  ngOnDestroy(): void {

    this.connectionLinksSubscription?.unsubscribe();
  }

  initForm(){

    this.linkForm = this.fb.group({
      'codigos': [''],
      'faena_id': [''],
      'tipo':[''],
      'fecha_comienzo':['']
    });
  }

  onreponsDeleteObjectTable($event){

    this.instrumentSelected = $event;
    this.stateModal = true;  
    this.modalInfo.messageModal = 'Esta seguro de eliminar '+$event.codigo;
    this.modalInfo.type = 'warning'    
  }

  onreponseUpdateObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.instrumentSelected = $event;
    this.router.navigateByUrl('/emt/instrument/'+ $event.id );
    localStorage.setItem('instrument', $event.id );

    // this.stateCompany = 'update';
    // this.modalAddCompany.show();

  }

  onreponseTimeObjectTable($event){
    console.log('COMPANY2: '+$event.uid);
    this.instrumentSelected = $event;
    this.router.navigateByUrl('/emt/itinfrastructureLosses/'+ $event.id );
    localStorage.setItem('linkCode', $event.codigo );

    // this.stateCompany = 'update';
    // this.modalAddCompany.show();

  }

  onreponseChangeObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.instrumentSelected = $event;
    this.router.navigateByUrl('/emt/positions/'+ $event.id );
    localStorage.setItem('instrument', $event.id );
  }


  onResponseNormal(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }

  addLink(){

    this.addModal.show();
  }

  onSubmit(){

    console.log('ON SUBMIT');

    if(this.linkForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');    
      return
    }

    this.creatConnectionLink();
  }


  creatConnectionLink(){

    this.linkForm.get('fecha_comienzo').setValue(moment(new Date()).format('YYYY-MM-DD'+'T'+'HH:mm:ss'));
    this.linkForm.get('faena_id').setValue(this.idMine);
    this.linkForm.get('tipo').setValue('enlace');

    this.connectionLinksService.creatConnectionLink(this.linkForm.value).subscribe({
      next: (resp: any) =>{  
        this.linkForm.reset();
        this.messageAlert(true, 'success', 'Enlaces de conexión creados correctamente');          
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al crear enlace de conexión');
    },
    complete: () => {  
        console.log('Completed');
    }
    });
  }

  getInfaestructuretiByMine(id: string){

    this.connectionLinksSubscription = this.infraestructuretiService.getInfaestructuretiByMine( id ).subscribe({
      next: (resp) => { 
         this.mines = resp;
         this.stateModalTable = true;  
         console.dir('Response Infraestructures: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); ;
      }
    });
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModal = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

}


