import { ConnectionLinksService } from '../../../services/configuration/links/connection-links.service';
import { Instrument } from './../../../models/instrument';
import { InstrumentsService } from '../../../services/backoffice/instruments/instruments.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InfraestructuretiService } from 'src/app/services/configuration/infraestructure/infraestructureti.service';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-itlosses',
  templateUrl: './itlosses.component.html',
  styleUrls: ['./itlosses.component.scss']
})
export class ItlossesComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  timesForm: FormGroup;

  times: any[] = [];
  idLinkConnection: string;
  linkCode: any = "";

  headElements = ['N°', ' F/H Inicio', 'F/H Termino', 'Duración[H]', 'Observación'];
  headTable = [ 'fecha_inicio', 'fecha_termino', 'tiempo_caido', 'observacion'];
  colspanLarge: number = 7;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateModalTable: boolean = false;


  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  typeLosses = [];
  instrumentSelected: string = '';

  getLossesSubscription: Subscription;
  linkLossesSubscription: Subscription;

  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService, private connectionLinksService: ConnectionLinksService, private infraestructuretiService: InfraestructuretiService, private router: Router, private route: ActivatedRoute, public authService: AuthService ) { 

      this.idLinkConnection = this.route.snapshot.paramMap.get('id'); //a las rutas
      console.log('ID Link: '+this.idLinkConnection);
      this.linkCode = localStorage.getItem('linkCode');
  }

  ngOnInit(): void {

    this.initForm();
    this.getLinkLossesByMine(this.idLinkConnection);
    this.getLossesType();
  }

  ngOnDestroy(): void {

    this.getLossesSubscription?.unsubscribe();
    this.linkLossesSubscription?.unsubscribe();
  }

  initForm(){

    this.timesForm = this.fb.group({
      'fecha_inicio_date': [''],
      'fecha_inicio_hora': [''],
      'fecha_fin_date': [''],
      'fecha_fin_hora': [''],
      'infraestructura_id': [''],
      'observacion': [''],
    });
  }

  onreponsDeleteObjectTable($event){

    this.instrumentSelected = $event;
    this.stateModal = true;  
    this.modalInfo.messageModal = 'Esta seguro de eliminar '+$event.codigo;
    this.modalInfo.type = 'warning'    
  }

  onreponseUpdateObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.instrumentSelected = $event;
    this.router.navigateByUrl('/emt/instrument/'+ $event.id );
  }

  onreponseTimeObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.instrumentSelected = $event;
    this.router.navigateByUrl('/emt/times/'+ $event.id );
  }

  onreponseChangeObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.instrumentSelected = $event;
    this.router.navigateByUrl('/emt/positions/'+ $event.id );
    localStorage.setItem('instrument', $event.id );
  }


  onResponseNormal(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }

  showAddModal(){

    this.addModal.show();
  }

  goInfraesttructureti(){

    this.router.navigateByUrl('/emt/itinfrastructure' );
  }
  
  onSubmitLinkLosses(){

    console.log('ON SUBMIT');

    if(this.timesForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes'); 
      return
    }
    this.createInfraestructuretiLosses();
  }


  createInfraestructuretiLosses(){

    this.infraestructuretiService.createInfraestructuretiLosses(this.timesForm.value, this.idLinkConnection).subscribe({
      next: (resp: any) =>{  
  
        this.timesForm.reset();
        this.addModal.hide(); 
        this.messageAlert(true, 'success', 'Perdida de tiempo creado correctament');      
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.getLinkLossesByMine(this.idLinkConnection);
    }
  });
  }
  
  getLossesType(){
  
    this.getLossesSubscription = this.instrumentsService.getLossesType( ).subscribe({
      next: (resp) => { 
         this.typeLosses = resp;
         console.dir('Response Type: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getLinkLossesByMine(id: string){

    this.linkLossesSubscription = this.infraestructuretiService.getLinkLossesByMine( id ).subscribe({
      next: (resp) => { 
         this.times = resp;
         this.stateModalTable = true;  
         console.dir('Response State: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getFieldControl(fieldName: string) {
    return this.timesForm.get(fieldName);
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModal = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
