import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import { ModalInfo } from 'src/app/models/modal-info';

@Component({
  selector: 'app-area-back',
  templateUrl: './area-back.component.html',
  styleUrls: ['./area-back.component.scss']
})
export class AreaBackComponent implements OnInit, OnDestroy {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();
  @Output() reponseObject = new EventEmitter();
  @Output() responseAddObject = new EventEmitter();

  modalInfo: ModalInfo = new ModalInfo();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  idObject: string = '';
  titleModal: string = 'Agregar Área'

  zonesMine = [];
  
  types = [{"id": 1, "nombre":"Vigilancia"}, {"id": 2, "nombre":"Monitoreo"}]

  objectsSubscription: Subscription;

  constructor( private fb: FormBuilder, private minesbackService: MinesbackService, private router: Router, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id'); 
   }

  ngOnInit(): void {

    this.initForm();
    this.getZonesByMine(this.idMine);
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'zona_mina_id': ['', Validators.required],
      'nombre': ['', Validators.required],
      'poligono': [''],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'zona_mina_id': this.data.zona_mina_id,
      'nombre': this.data.nombre,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");

      this.addModal.show();
      this.getZonesByMine(this.idMine);
      this.reponseStateModalForm.emit(false);

      if(this.objectForm){
        if(this.typeModal == 'edit'){
  
          console.log('EDIT SERVICE: '+this.data.nombre);
          this.titleModal = 'Editar Área'
          this.idObject = this.data.id;
          this.patchForm();
        }else{
          this.titleModal = 'Agregar Área'
          this.objectForm.reset();
        }
      }
    }
  }

  showAddModal(){

    this.addModal.show();
  }

  onResponseNormal(event){

    this.stateModalAlert = event;
  }


  onSubmitObject(){

    console.log('ON SUBMIT');

    if(this.objectForm.invalid){
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');    
      return
    }

    this.typeModal == 'new' ? this.createArea() : this.updateArea();
  }


  createArea(){

    this.minesbackService.createArea(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
        this.responseAddObject.emit(resp)
        this.reponseRefresh.emit(false); 
        this.objectForm.reset();
        this.addModal.hide(); 
        this.messageAlert(true, 'success', 'Área creada correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al crear área');
    },
    complete: () => {  
        console.log('Completed');
    }
    });
  }

  updateArea(){

    this.minesbackService.updateArea(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{  
        const formValue = { ...this.objectForm.value };
        formValue['id'] = this.idObject;
        this.reponseObject.emit(formValue);
        this.reponseRefresh.emit(false);  
        this.objectForm.reset();
        this.addModal.hide();
        this.messageAlert(true, 'success', 'Área actualizado correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar área');
    },
    complete: () => {  
        console.log('Completed');
    }
    });
  }
     
  
  getZonesByMine(id: string){
  
    this.objectsSubscription = this.minesbackService.getZonesByMine(id).subscribe({
      next: (resp) => { 
         this.zonesMine = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }
  
  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
