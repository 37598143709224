import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() mine: any = {};
  @Input() index: any;


  @Output() mineSelect: EventEmitter<number>;


  constructor(private router: Router) {
    this.mineSelect = new EventEmitter();
  }

  ngOnInit() {
  }

  // edit( id: any ) {
  //   // console.log(  this.index );
  //   this.router.navigate( ['/home/project', id] );

  //   // this.heroeSeleccionado.emit( this.index );
  // }

  // activity( proyecto_id: any, nombre_proyecto: any ){

  //   localStorage.setItem('proyecto_id', proyecto_id  );
  //   localStorage.setItem('nombre_proyecto', nombre_proyecto  );
  //   this.router.navigate( ['/home/admin'] );
    
  // }

  // users( proyecto_id: any, nombre_proyecto: any  ){

  //   localStorage.setItem('proyecto_id', proyecto_id  );
  //   localStorage.setItem('nombre_proyecto', nombre_proyecto  );
  //   this.router.navigate( ['/home/assign'] );

  // }

  // seeMine( idx:number ){
  //   this.router.navigate( ['/home'] );
  // }

  home(mine: any){

    console.log('EVENT:'+mine.id);
    this.router.navigateByUrl('/emt/home/'+mine.id);
    localStorage.setItem('mine', JSON.stringify(mine));
    
  }

}
