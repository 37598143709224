
<div class="container-fluid mt-3 px-0 mb-2">
  <!-- Default form contact -->
  <mdb-card>
    <mdb-card-header class="header-forms white-text text-center">
      <h5 class="header-title-forms">
        Crear Turno Diario
      </h5>
    </mdb-card-header>
    <mdb-card-body class="px-lg-5 pt-0 body-forms">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="md-form">
              <input
                type="text"
                [(ngModel)]="searchText"
                class="form-control"
                id="search"
                style="background-color: white;"
                mdbInput
              />
              <label class="pl-2" for="search">Buscar</label>
            </div>
          </div>
        </div>
        <table  id="tableSortExample" mdbTable #tableEl="mdbTable" class="z-depth-1">
          <thead class="head-tables">
            <tr class="title-tables">
              <!-- <th *ngFor="let head of headElements; let i = index" class="title-tables" aria-controls="tableSortExample" scope="col" [mdbTableSort]="elements" [sortBy]="headElements[i]">{{ head | titlecase }}
                <mdb-icon fas icon="sort"></mdb-icon> -->
                <th [mdbTableSort]="elements" sortBy="id_report"><mdb-icon fas icon="sort" class="icon-sort"></mdb-icon>ID Reporte</th>
                <th [mdbTableSort]="elements" sortBy="date"><mdb-icon fas icon="sort" class="icon-sort"></mdb-icon>Fecha de Inicio</th>
                <th [mdbTableSort]="elements" sortBy="date"><mdb-icon fas icon="sort" class="icon-sort"></mdb-icon>Fecha de Termino</th>
                <th [mdbTableSort]="elements" sortBy="date"><mdb-icon fas icon="sort" class="icon-sort"></mdb-icon>Rangos Aceptables</th>
                <th [mdbTableSort]="elements" sortBy="date"><mdb-icon fas icon="sort" class="icon-sort"></mdb-icon>Acciones</th>

            </tr>
          </thead>
          <tbody class="body-tables">
            <tr *ngFor="let el of elements; let i = index">
              <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                  scope="row">{{ el.id_report }}</th>
              <!-- <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{ el.shift }}</td> -->
              <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{ el.date }}</td>
              <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{ el.date }}</td>

              <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" class="div-button-range pt-2">
                <button mdbBtn type="button" class="button-range"
                    (click)="range(el.uid)">
                    <mdb-icon fas icon="check-square"></mdb-icon>
                </button>
              </td>

              <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" class="div-button-range pt-2">
                <!-- <button mdbBtn type="button" class="button-range"
                    (click)="range(el.uid)">
                    <mdb-icon fas icon="eye" style="color: green;"></mdb-icon>
                </button> -->
                <a (click)="range(el.uid)">
                  <mdb-icon fas icon="eye" class="button-range" style="color: green;"></mdb-icon>
                </a>
                <a (click)="range(el.uid)">
                  <mdb-icon fas icon="file-pdf" class="button-range" style="color: red;"></mdb-icon>
                </a>
                <a (click)="range(el.uid)">
                  <mdb-icon fas icon="envelope" class="button-range" style="color: gray;"></mdb-icon>
                </a>
                <a (click)="range(el.uid)">
                  <mdb-icon fas icon="edit" class="button-range" style="color: blue;"></mdb-icon>
                </a>
                <a (click)="range(el.uid)">
                  <mdb-icon fas icon="trash" class="button-range" style="color: brown;"></mdb-icon>
                </a>           
              </td>

            </tr>
          </tbody>
          <tfoot class="lighten-5 w-120 footer-tables">
            <tr>
              <td colspan="5">
                <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="elements"></mdb-table-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
    </div>  
    </mdb-card-body>
  </mdb-card>
  <!-- Default form contact -->
</div>

