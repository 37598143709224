
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, map } from 'rxjs';
import { Instrument } from 'src/app/models/instrument';
import { Ita2v } from 'src/app/models/ita2v';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class A2vService {

  constructor(private http: HttpClient) { }

  getInstruments(): Observable<Instrument[]>{
    const url = `${ base_url_fast }/instrumentos`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Instrument[] }) => resp.objects )
              );
  }

  getInfaestructuretiByMine(id: string): Observable<any[]>{

    const url = `${ base_url_fast }/faena/infraestructura/${id}`;
    return this.http.post( url, null, httpOptions)
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  // creatConnectionLink(form: any) {
  //   const url = `${ base_url_fast }/enlaces`;
  //   const req = this.http.post(url, form);
  //   return req;
  // }

  // getLinkLossesByMine(id: string): Observable<Itinfraestructurelosses[]>{
  //   const url = `${ base_url_fast }/faena/a2v/perdidas/all/${id}`;
  //   return this.http.post( url, null )
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: Itinfraestructurelosses[] }) => resp.objects )
  //             );
  // }

  getLinkLossesByMine(id: string): Observable<Ita2v[]>{
    const url = `${ base_url_fast }/faena/a2v/perdidas/all/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Ita2v[] }) => 
                resp.objects.map( data => {
                  return {
                    id: data.id,
                    a2v_id: data.a2v_id,
                    tipo: data.tipo,
                    fecha_inicio: moment(data.fecha_inicio).format('DD-MM-YYYY HH:mm'),
                    fecha_termino: moment(data.fecha_termino).format('DD-MM-YYYY HH:mm'),
                    tiempo_caido: data.tiempo_caido,
                    observacion: data.observacion,
                  }
                })
                )
              )
  }


  getA2vByMine(id: string): Observable<Ita2v[]>{
    const url = `${ base_url_fast }/faena/a2v/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Ita2v[] }) => resp.objects )
              );
  }


  createa2vLosses(form: any, id: string) {

    const dateInit = form.fecha_inicio_date ? new Date(`${form.fecha_inicio_date}T${form.fecha_inicio_hora}`).toISOString(): null;
    const dateEnd = form.fecha_fin_date ? new Date(`${form.fecha_fin_date}T${form.fecha_fin_hora}`).toISOString(): null;

    let body = {
        "a2v_id": id,
        "tipo": form.tipo,
        "fecha_inicio": dateInit,
        "fecha_termino": dateEnd,
        "observacion": form.observacion,
      }

    const url = `${base_url_fast}/a2v/perdidas`;
    const req = this.http.post(url, body, httpOptions);
    return req;
  }



}

