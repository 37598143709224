
import { UsersService } from '../../../services/backoffice/users/users.service';
import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import { ModalInfo } from 'src/app/models/modal-info';

@Component({
  selector: 'app-user-mine-back',
  templateUrl: './user-mine-back.component.html',
  styleUrls: ['./user-mine-back.component.scss']
})

export class UserMineBackComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();


  modalInfo: ModalInfo = new ModalInfo();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  titleModal: string = 'Usuarios de Faena'

  idObject: string = '';
  servicesMine = [];
  users = [];
  mine: any = [];

  types = [{"id": 1, "nombre":"Vigilancia"}, {"id": 2, "nombre":"Monitoreo"}]

  objectsSubscription: Subscription;
  objectsMinesSubscription: Subscription;


  constructor( private fb: FormBuilder, private minesbackService: MinesbackService, private usersService: UsersService, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id'); 
   }

  ngOnInit(): void {

    this.initForm();
    this.getUsers();
    this.getMineById(this.idMine);
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
    this.objectsMinesSubscription?.unsubscribe();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'usuarios': ['', Validators.required],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'usuarios': this.mine[0].usuarios,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");

      this.addModal.show();
      this.objectForm.reset();
      this.patchForm();
      this.reponseStateModalForm.emit(false);
    }
  }

  onResponseAlert(event){

    this.stateModalAlert = event;
  }


  onSubmitObject(){

    console.log('ON SUBMIT');
    if(this.objectForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');   
      return
    }
    this.updateMine();
  }


  updateMine(){

    this.minesbackService.updateMine(this.objectForm.value, this.idMine).subscribe({
      next: (resp: any) =>{  
      this.reponseRefresh.emit(false); 
      this.getMineById(this.idMine); 
      this.messageAlert(true, 'success', 'Usuarios de faena actualizados correctamente');

    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar usuarios de faena');
    },
    complete: () => {  
        console.log('Completed');
        this.objectForm.reset();
        this.addModal.hide();

    }
    });
  }
     
  getUsers(){
  
    this.objectsSubscription = this.usersService.getUsersFast().subscribe({
      next: (resp) => { 
         this.users = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }
 
  getMineById(id: string){
  
    this.objectsMinesSubscription = this.minesbackService.getMineById(id).subscribe({
      next: (resp) => { 
         this.mine = resp;
         this.objectForm.get('usuarios').setValue(this.mine[0].usuarios);
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

}
