<!-- <app-navbar></app-navbar> -->

<!--Double navigation-->
<header>
    <app-navbar></app-navbar>
</header>
<!--/.Double navigation-->

<!--Main Layout-->
<main>
    <div class="px-0 mx-0 container-fluid">
        <router-outlet></router-outlet>
    </div>
</main>
<!--/Main layout-->