import { SectorMine } from './../../../models/sector-mine';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstrumentsService } from 'src/app/services/backoffice/instruments/instruments.service';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss']
})

export class TypeComponent implements OnInit, OnDestroy {

  types = [];
  mineForm: FormGroup;

  headElements = ['N°', 'nombre', 'Herramientas'];
  headTable = ['nombre', 'tools'];
  colspanLarge: number = 3;

  modalInfo: ModalInfo = new ModalInfo();
  stateModalType: boolean = false;
  stateModalAlert: boolean = false;
  stateModalTable: boolean = false;

  typeType: string = 'new';
  dataType: any;
  objectSelected: any;

  objectsSubscription: Subscription;

  constructor( private instrumentsService: InstrumentsService, public authService: AuthService ) { }

  ngOnInit(): void {
    
    this.getType();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
  }

  onreponsDeleteObjectTable($event){

    console.log('COMPANY1: '+$event.uid);
    this.objectSelected = $event;
    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+$event.nombre); 
  }

  onreponseUpdateObjectTable($event){
  
    console.log('UPDATE: ');
    this.typeType = 'edit';
    this.stateModalType = true;
    this.dataType = $event;
  }
  
  onResponseAlert(event){
    this.stateModalAlert = false;
  }

  onReponseDeleteObjectModal($event){

    this.deleteObject(this.objectSelected.id);
  }

  onReponseStateModalFormMine(event){
    this.stateModalType = event;
  }
  
  addObject(){
    this.typeType = 'new';
    this.stateModalType = true;
  }
  
  onResponseRefresh(){
    console.log('REFRESH');
    this.getType();
  }
  
  getType(){
  
  this.objectsSubscription = this.instrumentsService.getTypesInstrument( ).subscribe({
      next: (resp) => { 
         this.types = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  deleteObject(id: string){

    this.instrumentsService.deleteType(id).subscribe({
      next: (resp) => { 
         //this.getMines();  
         this.types = this.types.filter(objeto => objeto.id !== this.objectSelected.id);
         this.messageAlert(true, 'success', 'El tipo fue eliminada satisfactoriamente'); 
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }


  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
