
<div class="padding-view">
  <div class="breadcrumb-style">
    <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link pl-4">
      <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/home">Backoffice</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="breadcrumbs-link">Usuarios</mdb-breadcrumb-item>
    </mdb-breadcrumb>
  </div>
  <div class="container z-depth-1 p-0 my-5">
    <div class="header-forms white-text header-div">
      <div></div>
      <h5 class="header-title-forms">
        Usuarios
      </h5>
        <div class="add-icon">
            <a (click)="addObject()"><mdb-icon fas icon="plus-circle" style="color: white;" *ngIf="authService.hasRole(['admin','desarrollo'])" mdbTooltip="Agregar" placement="top"></mdb-icon></a>
        </div>
      </div>
      <div class="table-body">
        <app-table-users-back [data]="users" [stateModal]="stateModalTable" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
         (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
         (reponseUpdateTable)="onreponseUpdateObjectTable($event)"></app-table-users-back>
     </div>
  </div>
  </div>
  
  <app-user-back [modalInfo]="modalInfo" [stateModal]="stateModalType" [data]="dataObject" [typeModal]="typeObject" (reponseRefresh)="onResponseRefresh()"
  (reponseStateModalForm) = "onReponseStateModalFormMine($event)"></app-user-back>

  <!-- <app-table-instument-back [data]="brands" [stateModal]="stateModalTable" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
  (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
  (reponseUpdateTable)="onreponseUpdateObjectTable($event)"></app-table-instument-back> -->

	<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
		(reponseStateModalAccept) = "onResponseAlert($event)"></app-modal-accept>
