import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackComponent } from './back/back.component';
import { BackofficeComponent } from './backoffice.component';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { BackIncidencesComponent } from './back-incidences/back-incidences.component';
import { BackMinesComponent } from './back-mines/back-mines.component';
import { BackMineComponent } from './back-mine/back-mine.component';import { BackUsersComponent } from './back-users/back-users.component';
import { BrandComponent } from './back-instruments/brand/brand.component';
import { ModelComponent } from './back-instruments/model/model.component';
import { TypeComponent } from './back-instruments/type/type.component';
import { ResumeComponent } from './back-instruments/resume/resume.component';
import { HomeComponent } from './back-instruments/home/home.component';
import { TrainingsComponent } from './back-trainings/trainings/trainings.component';
import { RoleGuard } from '../guards/role.guard';

const routes: Routes = [

  { 
    path: 'back', 
    component: BackofficeComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { expectedRole: ['admin', 'geotecnico', 'desarrollo', 'supervisor'] },

    children: [
      // { path: '', component: HomeComponent},
      { path: 'home', component: BackComponent},
      { path: 'backIncidences', component: BackIncidencesComponent},
      { path: 'backMines', component: BackMinesComponent},
      { path: 'backMine/:id', component: BackMineComponent},
      { path: 'backUsers', component: BackUsersComponent},
      { path: 'brand', component: BrandComponent},
      { path: 'model', component: ModelComponent},
      { path: 'type', component: TypeComponent},
      { path: 'resume', component: ResumeComponent},
      { path: 'backInstruments', component: HomeComponent},
      { path: 'backTrainings', component: TrainingsComponent},
    ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackofficeRoutingModule { }
