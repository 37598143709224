import { StateLoss } from '../../../models/state-loss';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Instrument } from '../../../models/instrument';
import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { TimesInstrument } from '../../../models/times-instrument';
import { PositionInstrument } from '../../../models/position-instrument';
import { TypeLoss } from '../../../models/type-loss';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class InstrumentsService {

  constructor(private http: HttpClient) { }

  getResumeInstruments(): Observable<any[]>{

    const url = `${ base_url_fast }/instrumento/resumen`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  getInstruments(): Observable<Instrument[]>{

    const url = `${ base_url_fast }/instrumentos`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Instrument[] }) => resp.objects )
              );
  }

  deleteInstrument(id: string): Observable<Instrument[]>{

    const url = `${ base_url_fast }/instrumentos/${id}`;
    return this.http.delete( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Instrument[] }) => resp.objects )
              );
  }

  getTypesInstrument( ): Observable<any[]>{

    const url = `${ base_url_fast }/instrumento/tipo`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  createTypesInstrument( form: any ): Observable<any[]>{

    const url = `${ base_url_fast }/instrumento/tipo`;
    return this.http.post( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  updateTypesInstrument( form: any, id: string ): Observable<any[]>{

    const url = `${ base_url_fast }/instrumento/tipo/${id}`;
    return this.http.put( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  deleteType(id: string): Observable<any[]>{
    const url = `${ base_url_fast }/instrumento/tipo/${id}`;
    return this.http.delete( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }


  getBrandInstrument( ): Observable<any[]>{

    const url = `${ base_url_fast }/marcas`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  getBrandByType(id: number): Observable<any[]>{

    const url = `${ base_url_fast }/instrumentos/tipo/marcas/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  createBrandInstrument( form: any ): Observable<any[]>{

    const url = `${ base_url_fast }/marcas`;
    return this.http.post( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  updateBrandInstrument( form: any, id: string ): Observable<any[]>{

    const url = `${ base_url_fast }/marcas/${id}`;
    return this.http.put( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  deleteBrand(id: string): Observable<any[]>{
    const url = `${ base_url_fast }/marcas/${id}`;
    return this.http.delete( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  getModels(): Observable<any[]>{
    const url = `${ base_url_fast }/instrumento/modelo`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  getModelByBrand(id: number): Observable<any[]>{

    const url = `${ base_url_fast }/marcas/modelos/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  createModelInstrument( form: any ): Observable<any[]>{

    const url = `${ base_url_fast }/instrumento/modelo`;
    return this.http.post( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  updateModelInstrument( form: any, id: string ): Observable<any[]>{

    const url = `${ base_url_fast }/instrumento/modelo/${id}`;
    return this.http.put( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  deleteModel(id: string): Observable<any[]>{
    const url = `${ base_url_fast }/instrumento/modelo/${id}`;
    return this.http.delete( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  

  getInstrumentById(id: string): Observable<any[]>{

    const url = `${ base_url_fast }/instrumentos/${id}`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  getInstrumentsByMine(id: string): Observable<any[]>{

    console.log('ID MINE IN INSTRUMENT: '+id);
    const url = `${ base_url_fast }/faena/instrumentos/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }


  updateInstrument(form: any, idMine: string, typeRadar: boolean){

    let body = {};
    if(typeRadar == true){
      const dateStart = moment(form.fecha_inicio_date).format('YYYY-MM-DD');
      const dateFinish = moment(form.fecha_fin_date).format('YYYY-MM-DD');
      let dateInit = new Date(`${dateStart}T${form.fecha_inicio_hora}`).toISOString();
      let dateEnd = new Date(`${dateFinish}T${form.fecha_fin_hora}`).toISOString();
      
      body = {
        "faena_id": idMine,
        "codigo": form.codigo,
        "tipo_id": form.tipo_id,
        "marca_id": form.marca_id,
        "modelo_id": form.modelo_id,
        "fecha_comienzo": new Date().toISOString(),
        "este": form.este,
        "norte": form.norte,
        "cota": form.cota,
        "proyecto": {
            "nombre": form.nombre,
            "fecha_inicio": dateInit,
            "fecha_fin": dateEnd,
            "sectores": form.sectores,
            "zonas": form.zonas,
            "fases": form.fases,
            "banco": form.banco,
            "paredes": form.paredes,
            "orientacion": form.orientacion,
            "azimut": form.azimut,
            "inclinacion": form.inclinacion,
            "areas": form.areas,
            "areas_cobertura": "todas2",
        }
      }
    }else{

      body = {
        "faena_id": idMine,
        "codigo": form.codigo,
        "tipo_id": form.tipo_id,
        "marca_id": form.marca_id,
        "modelo_id": form.modelo_id,
        "este": form.este,
        "norte": form.norte,
        "cota": form.cota,
      }
    }

    const url = `${ base_url_fast }/instrumentos/${idMine}`;
    return this.http.put( url, body, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  
  createInstrument(form: any, idMine: string, typeRadar: boolean) {

    let dateEnd;
    let dateInit;
    let body = {};

    if(typeRadar == true){
      dateInit = form.fecha_inicio_date ? new Date(`${form.fecha_inicio_date}T${form.fecha_inicio_hora}`).toISOString(): null;
      dateEnd = form.fecha_fin_date ? new Date(`${form.fecha_fin_date}T${form.fecha_fin_hora}`).toISOString(): null;
      body = {
        "faena_id": idMine,
        "codigo": form.codigo,
        "tipo_id": form.tipo_id,
        "marca_id": form.marca_id,
        "modelo_id": form.modelo_id,
        "fecha_comienzo": new Date().toISOString(),
        "este": form.este,
        "norte": form.norte,
        "cota": form.cota,
        "proyecto": {
            "nombre": form.nombre,
            "fecha_inicio": dateInit,
            "fecha_fin": dateEnd,
            "sectores": form.sectores,
            "zonas": form.zonas,
            "fases": form.fases,
            "banco": form.banco,
            "paredes": form.paredes,
            "orientacion": form.orientacion,
            "azimut": form.azimut,
            "inclinacion": form.inclinacion,
            "areas": form.areas,
            "areas_cobertura": "todas2",
        }
      }
    }else{

      body = {
        "faena_id": idMine,
        "codigo": form.codigo,
        "tipo_id": form.tipo_id,
        "marca_id": form.marca_id,
        "modelo_id": form.modelo_id,
        "fecha_comienzo": new Date().toISOString(),
        "este": form.este,
        "norte": form.norte,
        "cota": form.cota,
      }
    }

    const url = `${base_url_fast}/instrumentos`;
    const req = this.http.post(url, body, httpOptions);
    return req;
  }

  // getLostTimesByInstrument(id: string): Observable<TimesInstrument[]>{
  //   const url = `${ base_url_fast }/all/instrumentos/estado/${id}`;
  //   return this.http.post( url, null )
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: TimesInstrument[] }) => resp.objects )
  //             );
  // }


  getLostTimesByInstrument(id: string): Observable<TimesInstrument[]>{
    const url = `${ base_url_fast }/all/instrumentos/estado/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: TimesInstrument[] }) => 
                resp.objects.map( data => {
                  return {
                    id: data.id,
                    instrumento_id: data.instrumento_id,
                    fecha_inicio: moment(data.fecha_inicio).format('DD-MM-YYYY HH:mm'),
                    fecha_termino:  moment(data.fecha_termino).format('DD-MM-YYYY HH:mm'),
                    tipo_perdida: data.tipo_perdida,
                    tiempo_caido: data.tiempo_caido,
                    observacion: data.observacion
                  }
                })
                )
              )
  }


    
  createLostTime(form: any, id: string) {


    let dateInit = new Date(`${form.fecha_inicio_date}T${form.fecha_inicio_hora}`).toISOString();
    let dateEnd = new Date(`${form.fecha_fin_date}T${form.fecha_fin_hora}`).toISOString();

    let body = {
        "instrumento_id": id,
        "tipo_perdida": form.tipo_perdida,
        "tiempo_caido": form.tiempo_caido,
        "fecha_inicio": dateInit,
        "fecha_termino": dateEnd,
        "perdida_id": form.perdida_id,
        "estado_id": form.estado_id,
        "observacion": form.observacion,
      }

    const url = `${base_url_fast}/instrumentos/estado`;
    return this.http.post( url, body, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }



    getChangePositionByInstrument(id: string): Observable<PositionInstrument[]>{
   
    const url = `${ base_url_fast }/all/instrumentos/posicion/${id}`;
   
    return this.http.post( url, null, httpOptions )
    .pipe(
      map( (resp: {ok: boolean, objects: PositionInstrument[] }) => 
      resp.objects.map( data => {
        return {
          id: data.id,
          instrumento_id: data.instrumento_id,
          nombre: data.nombre,
          codigo: data.codigo,
          tipo: data.tipo,
          tipo_id: data.tipo_id,
          fecha: moment(data.fecha).format('DD-MM-YYYY HH:mm'),
          fecha_inicio: moment(data.fecha_inicio).format('DD-MM-YYYY HH:mm'),
          fecha_fin: moment(data.fecha_fin).format('DD-MM-YYYY HH:mm'),
          este: data.este,
          norte: data.norte,
          cota: data.cota,
          nombre_proyecto: data.nombre_proyecto,
          sectores: data.sectores,
          areas: data.areas,
          fases: data.fases,
          paredes: data.paredes,
          banco: data.banco,
          azimut: data.azimut,
          inclinacion: data.inclinacion,
          sectores_nombre: data.sectores_nombre,
          zonas_nombre: data.zonas_nombre,
          fases_nombre: data.fases_nombre,
          areas_nombre: data.areas_nombre,
        }
      })
      )
    )
  }


  

  
  getLossesState(): Observable<StateLoss[]>{
    const url = `${ base_url_fast }/estadoperdida`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: StateLoss[] }) => resp.objects )
              );
  }
  
  getLossesTypeByState(id: string): Observable<TypeLoss[]>{
    const url = `${ base_url_fast }/estadoperdida/tipoperdida/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: TypeLoss[] }) => resp.objects )
              );
  }

  getLossesType(): Observable<TypeLoss[]>{
    const url = `${ base_url_fast }/tipoperdida`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: TypeLoss[] }) => resp.objects )
              );
  }


    
  getLastPositionByInstrument(id: string): Observable<PositionInstrument[]>{

    console.log('ID: '+id);
    const url = `${ base_url_fast }/all/instrumentos/ultimaposicion/5`;
    return this.http.post( url, 0, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: PositionInstrument[] }) => resp.objects )
              );
  }



  createChangePosition(form: any, idInstrument: string, type: string) {

    let dateEnd;
    let dateInit;
    let body = {};

    dateInit = form.fecha_inicio_date ? new Date(`${form.fecha_inicio_date}T${form.fecha_inicio_hora}`).toISOString(): null;

    if(type == 'Radar'){
      dateEnd = form.fecha_fin_date ? new Date(`${form.fecha_fin_date}T${form.fecha_fin_hora}`).toISOString(): null;
      body = {
        "instrumento_id": idInstrument,
        "fecha": new Date().toISOString(),
        "este": form.este,
        "norte": form.norte,
        "cota": form.cota,
        "proyecto": {
            "nombre": form.nombre,
            "fecha_inicio": dateInit,
            "fecha_fin": dateEnd,
            "sectores": form.sectores,
            "zonas": form.zonas,
            "fases": form.fases,
            "paredes": form.paredes,
            "banco": form.banco,
            "azimut": form.azimut,
            "inclinacion": form.inclinacion,
            "areas": form.areas,
            "areas_cobertura": "todas2",
        }
      }
    }else{

      body = {
        "instrumento_id": idInstrument,
        "fecha": dateInit,
        "este": form.este,
        "norte": form.norte,
        "cota": form.cota,
      }
    }

    const url = `${base_url_fast}/instrumentos/posicion`;
    const req = this.http.post(url, body, httpOptions);
    return req;
  }

}


