
<div class="padding-view">
    <div class="breadcrumb-style">
      <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link pl-4">
        <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/home">Backoffice</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="breadcrumbs-link">Capacitaciones</mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
    <div class="mt-3 px-3">
        <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="addNote()" *ngIf="authService.hasRole(['admin','desarrollo'])"
            mdbWavesEffect><i class="fas fa-plus-circle mr-2"></i>Nota
        </button> 
        <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="addState()"
            mdbWavesEffect><i class="fas fa-plus-circle mr-2"></i>Estado
        </button> 
    </div>
    <div class="container z-depth-1 p-0 my-5">
      <div class="header-forms white-text header-div">
        <h5 class="header-title-forms">
          Capacitaciónes/Certificaciónes
        </h5>
        </div>
       <div class="container">
        <div class="row">
          <div class="md-form md-outline col-md-3 col-6">
            <input class="form-control" [(ngModel)]="nameFilter" type="text" mdbInput style="background-color: white;">
            <label for="form-lg" class="pl-4">Nombre</label>
            <!-- <mdb-error *ngIf="max_range.invalid && (max_range.dirty || max_range.touched)" style="color: red;">Valor ingresado no es valido</mdb-error>
            <mdb-success *ngIf="max_range.valid && (max_range.dirty || max_range.touched)" style="color: green;">Valor ingresado es valido</mdb-success> -->
          </div>
          <div class="md-form md-outline col-md-3 col-6">
            <input class="form-control" [(ngModel)]="lastNameFilter" type="text" mdbInput style="background-color: white;">
            <label for="form-lg" class="pl-4">Apellido</label>
            <!-- <mdb-error *ngIf="max_range.invalid && (max_range.dirty || max_range.touched)" style="color: red;">Valor ingresado no es valido</mdb-error>
            <mdb-success *ngIf="max_range.valid && (max_range.dirty || max_range.touched)" style="color: green;">Valor ingresado es valido</mdb-success> -->
          </div>
          <div class="md-form md-outline col-md-3 col-6">
            <mdb-select-2 [outline]="true" class="input-style" placeholder="Elegir Faena" label="Faena"
              >
              <mdb-select-option *ngFor="let option of mines; let i = index" [value]="option.nombre" (click)="getSelectedCamera(option)">{{ option.nombre_largo }}
              </mdb-select-option>
            </mdb-select-2>
          </div>
          <div class="md-form md-outline col-md-3 col-6">
            <button type="button" mdbBtn class="waves-light button-accept-modals save-button m-0"  (click)="filter()"
              mdbWavesEffect>Filtrar
            </button> 
            <button type="button" mdbBtn class="waves-light button-accept-modals cancel-button m-0 ml-3"  (click)="resetFilter()"
              mdbWavesEffect>Reset
            </button> 
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="table-container table-responsive">
            <table mdbTable bordered="true" small="true"  class="z-depth-1 table-body table-striped table-bordered table-hover text-nowrap">
              <thead>

                <tr>
                  <ng-container *ngFor="let el of structure_table.level1">
                    <th scope="col" [attr.colspan]="el.qcolumn" class="cap-title text-center" *ngIf="el.qcolumn>0">{{ el.nombre | titlecase }}</th>
                  </ng-container>
                  <th scope="col" [attr.colspan]="1" class="cap-title text-center">Herramientas</th>
                </tr>
                <tr>
                  <ng-container *ngFor="let el of structure_table.level2">
                    <th scope="col" [attr.colspan]="el.qcolumn" class="cap-title text-center cap-two-title" *ngIf="el.qcolumn>0" >{{ el.nombre | titlecase }}</th>
                  </ng-container>
                  <th scope="col" [attr.colspan]="1" class="cap-title text-center cap-two-title"></th>
                </tr>

                <tr>
                  <ng-container *ngFor="let el of structure_table.level3">
                    <th scope="col" [attr.colspan]="1" class="cap-title text-center cap-three-title">{{ el }}</th>
                  </ng-container>
                  <th scope="col" [attr.colspan]="1" class="cap-title text-center cap-three-title"></th>
                </tr>
                
                

              </thead>
              <tbody>
                <tr mdbTableCol *ngFor="let el of elements">
                  <!-- <th scope="row">{{ el.id }}</th> -->
          
                  <!-- <td>{{ el.first }}</td>
                  <td>{{ el.last }}</td>
                  <td>{{ el.handle }}</td>
                  <td>{{ el.handle2 }}</td> -->
          
          
                  <td class="table-text">{{ el.numero }}</td>
                  <td class="table-text">{{ el.nombre }}</td>
                  <td class="table-text">{{ el.apellido_p }}</td>
                  <td class="table-text">{{ el.nivel_usuario }}</td>
                  <td class="table-text">{{ el.faenas_nombres }}</td>
                  <td class="table-text">{{ el.evaluacion	}}</td>
                  <td class="table-text">{{ el.a2v }}</td>
                  <td class="table-text">{{ el.prot }}</td>
                  <td class="table-text">{{ el.geotecnia }}</td>
                  <td class="table-text">{{ el.guardian }}</td>
                  <td class="table-text">{{ el.hmi }}</td>
                  <td class="table-text">{{ el.ssr }}</td>
                  <td class="table-text">{{ el.ranger }}</td>
                  <td class="table-text">{{ el.geomos }}</td>
                  <td class="table-text">{{ el.t4d }}</td>                  
                  <td class="table-text">{{ el.arcgis }}</td>
                  <td class="table-text">{{ el.bosh }}</td>
                  
                  <td class="table-text">     
                    <button class="btn button-edit-table" (click)="editeObject(el)" mdbTooltip="editar" placement="top"><i class="fas fa-edit"></i></button>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
      </div>
      </div>
    </div>
    
    <app-back-training-type [modalInfo]="modalInfo" [stateModal]="stateModalType" [data]="dataObject" [typeModal]="typeObject" (reponseRefresh)="onResponseRefresh()"
    (responseStateModalForm) = "onReponseStateModalType($event)"></app-back-training-type>
  
    <app-back-training-state [modalInfo]="modalInfo" [stateModal]="stateModalState" [mines]="mines" (reponseRefresh)="onResponseRefresh()"
    (responseStateModalForm) = "onReponseStateModalState($event)"></app-back-training-state>

    <app-back-training-note [modalInfo]="modalInfo" [stateModal]="stateModalNote" [data]="dataObjectNote" [typeModal]="typeObjectNote" (reponseRefresh)="onResponseRefresh()"
    (responseStateModalForm) = "onReponseStateModalNote($event)"></app-back-training-note>

  
    <app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal"
    (reponseStateModalAccept) = "onResponseNormal($event)"></app-modal-accept>
