
import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstrumentsService } from 'src/app/services/backoffice/instruments/instruments.service';

@Component({
  selector: 'app-brand-back',
  templateUrl: './brand-back.component.html',
  styleUrls: ['./brand-back.component.scss']
})

export class BrandBackComponent implements OnInit, OnDestroy {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() modalInfo: any;
  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  titleModal: string = 'Agregar Marca'

  idObject: string = '';
  servicesMine = [];
  
  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  types = [];
  objectsSubscription: Subscription;

  // types = [{"id": 1, "nombre":"Vigilancia"}, {"id": 2, "nombre":"Monitoreo"}]

  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService, private router: Router, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id'); //a las rutas
   }

  ngOnInit(): void {

    this.initForm();
    this.getTypes();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'tipo_id': ['', Validators.required],
      'nombre': ['', Validators.required],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'tipo_id': this.data.tipo_id,
      'nombre': this.data.nombre,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");
      this.addModal.show();
      this.reponseStateModalForm.emit(false);

      if(this.objectForm){
        if(this.typeModal == 'edit'){
  
          console.log('EDIT BRAND: '+this.data.tipo_id);
          this.titleModal = 'Editar Marca';
          this.idObject = this.data.id;
          this.patchForm();
        }else{
          this.titleModal = 'Agregar Marca';
          this.objectForm.reset();
        }
      }
    }
  }


  showAddModal(){

    this.addModal.show();
  }

  onResponseAlert(event){

    this.stateModalAlert = event;
  }


  onSubmitObject(){

    console.log('ON SUBMIT');

    if(this.objectForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');   
      return
    }

    this.typeModal == 'new' ? this.createBrand() : this.updateBrand();
  }


  createBrand(){

    this.instrumentsService.createBrandInstrument(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
        this.reponseRefresh.emit(false);   
        this.addModal.hide();
        this.objectForm.reset()
        this.messageAlert(true, 'success', 'Marca creada correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al crear marca');
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }

  updateBrand(){

    this.instrumentsService.updateBrandInstrument(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{  
      this.reponseRefresh.emit(false);  
      this.objectForm.reset();
      this.addModal.hide();
      this.messageAlert(true, 'success', 'Marca actualizada correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar marca');
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }
     
  getTypes(){

    this.objectsSubscription = this.instrumentsService.getTypesInstrument( ).subscribe({
      next: (resp) => { 
         this.types = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }
  
  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
