
<header>
	<section class="view intro background-login">
	  <div class="full-bg-img rgba-stylish-strong mask flex-center">
	    <div class="container container-login z-depth-5">
	      <div class="row">
			<div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto pt-sm-5 pb-sm-2">
				<div class="d-flex justify-content-center">
					<img src="/assets/emt2.png" width="270px" heigth="210px" alt="image">
				</div>
			</div>
	        <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto pt-2">

	          <!--Form with header-->
	          <mdb-card bgColor="login-card" class="wow fadeIn z-depth-1" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
	            <mdb-card-body>
					<h2 class="text-center title-login pb-3">Restablecer Contraseña</h2>
                    <p class="text-center text-recover">Ingresa tu nueva contraseña.</p>
					<form [formGroup]="recoverForm" (ngSubmit)="recover()"> 
						<div class="md-form md-outline pb-5 input-login">
                          <mdb-icon fas icon="lock" class="prefix"></mdb-icon>
                          <input mdbInput mdbValidate formControlName="password1" type="password"
                                 class="form-control" id="form10">
                          <label for="form10" style="color: black;">Contraseña</label>
                          <mdb-error *ngIf="password1.invalid && (password1.dirty || password1.touched)" style="color: red;">Input invalid
                          </mdb-error>
                          <mdb-success *ngIf="password1.valid && (password1.dirty || password1.touched)" style="color: green;">Input valid
                          </mdb-success>
                        </div>
						<div class="md-form md-outline pb-2 input-login">
						  <mdb-icon fas icon="lock" class="prefix"></mdb-icon>
						  <input mdbInput mdbValidate formControlName="password" type="password"
								 class="form-control" id="form12">
						  <label for="form10" style="color: black;">Repite tu contraseña</label>
						  <mdb-error *ngIf="password.invalid && (password.dirty || password.touched)" style="color: red;">Input invalid
						  </mdb-error>
						  <mdb-success *ngIf="password.valid && (password.dirty || password.touched)" style="color: green;">Input valid
						  </mdb-success>
						</div>
						<div class="pt-3">
							<button class="btn-color" mdbBtn type="submit" block="true" mdbWavesEffect>RESTABLECER</button>
							<p class="text-center text-recover pt-3"><a (click)="returnLogin()">Volver al Inicio</a></p>							
						</div>
					  </form>

	            </mdb-card-body>
	          </mdb-card>
	          <!--/Form with header-->

	        </div>
	      </div>
	    </div>
	  </div>
	</section>
</header>


<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
	(reponseStateModalAccept) = "onResponseNormal($event)"></app-modal-accept>

