
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';
import { Zone } from '../../../../models/zone';

import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  constructor(private http: HttpClient) { }

  // getZones(): Observable<Zone[]>{
  //   const url = `${ base_url }/mineZone`;
  //   return this.http.get( url )
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: Zone[] }) => resp.objects )
  //             );
  // }

  getZonesBySector( id: string): Observable<Zone[]>{
    const url = `${ base_url_fast }/sector/zona/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Zone[] }) => resp.objects )
              );
  }

  getZoneById(id: string): Observable<Zone[]>{

    const url = `${ base_url_fast }/zona/${id}`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Zone[] }) => resp.objects )
              );
  }


  updateZone(form: any, id: string): Observable<any[]>{

    const url = `${ base_url_fast }/zona/${id}`;
    return this.http.put( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }


  createZone(form: any) {
    const url = `${ base_url_fast }/zona`;
    const req = this.http.post(url, form, httpOptions);
    return req;
  }


  // getSectors(): Observable<SectorZone[]>{
  //   const url = `${ base_url }/mineSector`;
  //   return this.http.get( url )
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: SectorZone[] }) => resp.objects )
  //             );
  // }

}

