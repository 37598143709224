
import { MonitoringService } from '../../../services/backoffice/mine/monitoring/monitoring.service';
import { ZonesService } from '../../../services/backoffice/mine/zones/zones.service';
import { SectorsService } from '../../../services/backoffice/mine/sectors/sectors.service';

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { InstrumentsService } from 'src/app/services/backoffice/instruments/instruments.service';
import * as moment from 'moment';


@Component({
  selector: 'app-instrument',
  templateUrl: './instrument.component.html',
  styleUrls: ['./instrument.component.scss']
})

export class InstrumentComponent implements OnInit {

  instruments: any = [];

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateTable: boolean = false;

  instrumentForm: FormGroup;
  disabledSubmitButton: boolean = true;

  idInstrument: string;
  idMine: string;

  brands = [];
  models = [];
  sectors = [];
  zones = [];
  areas = [];
  phases = [];

  typeSubscription: Subscription;
  brandSubscription: Subscription;
  modelsSubscription: Subscription;
  sectorSubscription: Subscription;
  zoneSubscription: Subscription;
  areasSubscription: Subscription;

   paredes = [
    { value: 'N', label: 'N' },
    { value: 'O', label: 'O' },
    { value: 'S', label: 'S' },
    { value: 'E', label: 'E' },
    { value: 'NW', label: 'NW' },
    { value: 'NE', label: 'NE' },
    { value: 'SW', label: 'SW' },
    { value: 'SE', label: 'SE' },
  ];

  typesInstrument = [];
  typeRadar: boolean = false;
  instrumentSubscription: Subscription;

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };
  
  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService,  private sectorsService: SectorsService, private monitoringService: MonitoringService, private zonesService: ZonesService, private router: Router, private route: ActivatedRoute ) {
    
    this.idInstrument = this.route.snapshot.paramMap.get('id'); //a las rutas
    this.idMine =  JSON.parse(localStorage.getItem("mine")).id;
    console.log('ID MINE: '+this.idMine);
  }

  ngOnInit(): void {

    this.initForm();
  
    this.getTypesInstrument();
    this.getSectorsByMine(this.idMine);
    console.log('ID INSTRUMENT: '+this.idInstrument);
    if( this.idInstrument != 'new') this.getInstrumentById(this.idInstrument);
  }

  initForm(){

    this.instrumentForm = this.fb.group({
      'faena_id': [''],
      'codigo': ['', Validators.required],
      'tipo_id': ['', Validators.required],
      'marca_id': ['', Validators.required],
      'modelo_id': ['', Validators.required],
      'este': ['', Validators.required],
      'norte': ['', Validators.required],
      'cota': ['', Validators.required],
      'nombre': [''],
      'fecha_inicio_hora': [''],
      'fecha_inicio_date': [''],
      'fecha_fin_hora': [''],
      'fecha_fin_date': [''],
      'sectores': [''],
      'zonas': [''],
      'areas': [''],
      'fases': [''],
      'paredes': [''],
      'banco': [''],
      'azimut': [''],
      'inclinacion': [''],
    });
  }


  patchFormNormal(){

    console.log('patchFormNormal');
    this.instrumentForm.patchValue({
      'faena_id': this.instruments.faena_id,
      'codigo': this.instruments.codigo,
      'tipo_id': this.instruments.tipo_id,
      'marca_id': this.instruments.marca_id,
      'modelo_id': this.instruments.modelo_id,
      'este': this.instruments.este,
      'norte': this.instruments.norte,
      'cota': this.instruments.cota,
    });

    this.typeRadar = false;

  }

  patchFormRadar(){

    console.log('patchFormRadar');
    this.typeRadar = true;
    this.instrumentForm.patchValue({
      'faena_id': this.instruments.faena_id,
      'codigo': this.instruments.codigo,
      'tipo_id': this.instruments.tipo_id,
      'marca_id': this.instruments.marca_id,
      'modelo_id': this.instruments.modelo_id,
      'este': this.instruments.este,
      'norte': this.instruments.norte,
      'cota': this.instruments.cota,
      'nombre': this.instruments.proyecto.nombre,
      'fecha_inicio_hora': moment(this.instruments.proyecto.fecha_inicio).format("HH:mm"),     
      'fecha_inicio_date': this.instruments.proyecto.fecha_inicio,
      'fecha_fin_hora': moment(this.instruments.proyecto.fecha_fin).format("HH:mm"),
      'fecha_fin_date': this.instruments.proyecto.fecha_fin,
      // 'fecha_inicio_hora': moment(this.instruments.proyecto.fecha_inicio).format("HH:mm:ss"),     
      // 'fecha_inicio_date': moment(this.instruments.proyecto.fecha_inicio).format("DD-MM-YYYY HH:mm:ss"),
      // 'fecha_fin_hora': moment(this.instruments.proyecto.fecha_inicio).format("DD-MM-YYYY HH:mm:ss"),
      // 'fecha_fin_date': moment(this.instruments.proyecto.fecha_inicio).format("DD-MM-YYYY HH:mm:ss"),
      'sectores': this.instruments.proyecto.sectores,
      'zonas': this.instruments.proyecto.zonas,
      'areas': this.instruments.proyecto.areas,
      'fases': this.instruments.proyecto.fases,
      'paredes': this.instruments.proyecto.paredes,
      'banco': this.instruments.proyecto.banco,
      'azimut': this.instruments.proyecto.azimut,
      'inclinacion': this.instruments.proyecto.inclinacion
    });

    this.getZonesBySector(this.instruments.proyecto.sectores);
    this.getPhasesBySector(this.instruments.proyecto.sectores);
    this.getAreasByZones(this.instruments.proyecto.zonas);
  }

  resetProject(){

    this.instrumentForm.patchValue({
      'modelo_id': null,
      'nombre': null,
      'fecha_inicio_hora': null,
      'fecha_inicio_date': null,
      'fecha_fin_hora': null,
      'fecha_fin_date': null,
      'zonas': null,
      'areas': null,
      'fases': null,
      'paredes': null,
      'banco': null,
      'azimut': null,
      'inclinacion': null
    });
    
  }

  ngOnDestroy(){

     this.instrumentSubscription?.unsubscribe();
  }

  onSubmit() {

    console.log('ON SUBMIT');
    if(this.instrumentForm.invalid){

      console.log('INSTRUMENT FORM: '+this.instrumentForm.value)
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');    
      return
    }

    this.idInstrument == 'new' ? this.createInstrument() : this.updateInstrument();
  }

  onResponseNormal(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }

  goInstruments(){
  
    this.router.navigateByUrl('/emt/instruments' );
  }
  
   
  onTimeChange(event){

  console.log('EVENT: '+event);
  }


  changedType(event){
  
    console.log('TYPE: '+event.nombre);
    event.nombre.toLowerCase() == 'radar' ? (this.typeRadar = true, this.resetProject()) : this.typeRadar = false;

    this.getBrandByType(event.id);
  }

  changedBrand(event){
  
    console.log('TYPE: '+event);
    this.getModelByBrand(event);
  }

  changedSector(event){
  
    console.log('TYPE SECTOR: '+event);
    this.getZonesBySector(event);
    this.getPhasesBySector(event);
  
    this.instrumentForm.get('areas').setValue([]);
  }
  
  changedZone(event){
  
    console.log('TYPE ZONE: '+event);
    // this.getMonitoringsByZoneConcat(event);
    this.getAreasByZones(event);
  }
  
  changedPhase(event){
  
    console.log('TYPE ZONE: '+event);
    // this.getMonitoringsByZoneConcat(event);
    // this.getAreasBySector(event);
  }
  
  getInstrumentById(id: string){
  
    this.instrumentSubscription = this.instrumentsService.getInstrumentById(id).subscribe({
      next: (resp) => { 
         this.instruments = resp[0];
         console.log('TYPE: '+this.instruments.tipo);
         this.instruments.tipo == 'Radar' ? this.patchFormRadar() : this.patchFormNormal();
         this.getBrandByType(this.instruments.tipo_id);
         console.log('Response1: ' + resp[0].cota); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 

  
        // this.instrumentForm.get('codigo').setValue(this.instruments.codigo);
        // this.instrumentForm.get('tipo_id').setValue(this.instruments.tipo_id);
        // this.instrumentForm.get('marca_id').setValue(this.instruments.marca_id);
        // this.instrumentForm.get('modelo_id').setValue(this.instruments.modelo_id);        
        // this.instrumentForm.get('este').setValue(this.instruments.este);
        // this.instrumentForm.get('norte').setValue(this.instruments.norte);
        // this.instrumentForm.get('cota').setValue(this.instruments.cota);
  
        // if( this.instruments.tipo_id == 5) {
  
        //   let datex_inicio = moment(this.instruments.proyecto.fecha_inicio).format('HH:mm');
        //   let datex_fin = moment(this.instruments.proyecto.fecha_fin).format('HH:mm');
    
        //   console.log('datex init: '+datex_inicio);
        //   console.log('datex end: '+datex_fin);
    
        //   this.instrumentForm.get('nombre').setValue(this.instruments.proyecto.nombre);
        //   this.instrumentForm.get('fecha_inicio_hora').setValue(datex_inicio);
        //   this.instrumentForm.get('fecha_inicio_date').setValue(this.instruments.proyecto.fecha_inicio);
    
        //   this.instrumentForm.get('fecha_fin_hora').setValue(datex_fin);
        //   this.instrumentForm.get('fecha_fin_date').setValue(this.instruments.proyecto.fecha_fin);
    
        //   // this.instrumentForm.get('fecha_fin').setValue(this.instruments[0].proyecto.fecha_fin);
        //   this.instrumentForm.get('sector').setValue(this.instruments.proyecto.sector);
        //   this.instrumentForm.get('fase_pared').setValue(this.instruments.proyecto.fase_pared);
        //   this.instrumentForm.get('areas').setValue(this.instruments.proyecto.areas);
        //   this.instrumentForm.get('banco').setValue(this.instruments.proyecto.banco);
        //   // this.instrumentForm.get('pared').setValue(this.instruments.proyecto.pared);
        //   // this.instrumentForm.get('orientacion').setValue(this.instruments.proyecto.orientacion);
        //   this.instrumentForm.get('azimut').setValue(this.instruments.proyecto.azimut);
        //   this.instrumentForm.get('inclinacion').setValue(this.instruments.proyecto.inclinacion);
  
        //   this.getZonesBySector(this.instruments.proyecto.sector);
        //   //this.getMonitoringsByZoneConcat(this.instruments.proyecto.fase_pared);
  
        //   // this.spinner.hide();
        //   this.typeRadar = true;
  
        // }
        
      }
    });
  
  }

  createInstrument(){
  
    // console.log('TIPO: '+this.instrumentForm.value.tipo); 
    this.instrumentsService.createInstrument(this.instrumentForm.value, this.idMine, this.typeRadar ).subscribe({
      next: (resp: any) =>{  
  
        this.instrumentForm.reset();
        this.disabledSubmitButton = true;   
        this.messageAlert(true, 'success', 'Instrumento creado correctamente');  
        setTimeout(() => {
          this.goInstruments();
        }, 2000);   
      },
      error: (err) => { 
        console.log('Received an error: ' + err);
        this.messageAlert(true, 'error', 'Error al crear instrumento');
      },
      complete: () => {  
          console.log('Completed');
      }
    });
  }
  
  updateInstrument(){
  
    this.instrumentsService.updateInstrument(this.instrumentForm.value, this.idInstrument, this.typeRadar).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        this.messageAlert(true, 'success', 'Instrumento actualizado correctamente');     
        setTimeout(() => {
          this.goInstruments();
        }, 2000);  
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar instrumento');
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }

  getTypesInstrument(){

    this.instrumentSubscription = this.instrumentsService.getTypesInstrument( ).subscribe({
      next: (resp) => { 
         this.typesInstrument = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getBrandByType(id: number){

  this.instrumentSubscription = this.instrumentsService.getBrandByType(id).subscribe({
    next: (resp) => { 
       this.brands = resp;
       console.log('Response: ' + resp); 
      },
    error: (err) => { console.log('Received an error: ' + err.message)},
    complete: () => { 
      console.log('Completed'); 
    }
  });
  }

  getModelByBrand(id: number){

  this.modelsSubscription = this.instrumentsService.getModelByBrand(id).subscribe({
    next: (resp) => { 
       this.models = resp;
       console.log('Response: ' + resp); 
      },
    error: (err) => { console.log('Received an error: ' + err.message)},
    complete: () => { 
      console.log('Completed'); 
    }
  });
  }

  getSectorsByMine( idMine: string ){

  this.instrumentSubscription = this.sectorsService.getSectorsByMine( idMine ).subscribe({
    next: (resp) => { 
       this.sectors = resp;
       console.log('Response: ' + resp); 
      },
    error: (err) => { console.log('Received an error: ' + err.message)},
    complete: () => { 
      console.log('Completed'); 
    }
  });
  }

  getZonesBySector( sectorId: string ){

  this.instrumentSubscription = this.zonesService.getZonesBySector( sectorId ).subscribe({
    next: (resp) => { 
       this.zones = resp;
       console.log('Response: ' + resp); 
      },
    error: (err) => { console.log('Received an error: ' + err.message)},
    complete: () => { 
      console.log('Completed'); 
    }
  });
  }

  getAreasByZones( sectorId: string ){

    this.instrumentSubscription = this.monitoringService.getAreasByZones( sectorId ).subscribe({
      next: (resp) => { 
         this.areas = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getPhasesBySector( sectorId: string ){

    this.instrumentSubscription = this.monitoringService.getPhasesBySector( sectorId ).subscribe({
        next: (resp) => { 
           this.phases = resp;
           console.log('Response: ' + resp); 
          },
        error: (err) => { console.log('Received an error: ' + err.message)},
        complete: () => { 
          console.log('Completed'); 
        }
      });
  }

  getFieldControl(fieldName: string) {
    return this.instrumentForm.get(fieldName);
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModal = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
