import { UsersService } from 'src/app/services/backoffice/users/users.service';
import { DailyShiftService } from './../../services/daily-shift.service';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IMyOptions, MDBDatePickerComponent, LocaleService } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';


@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  @ViewChild('datePicker', { static: true }) datePicker!: MDBDatePickerComponent;

  
  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  
  user: any;
  userForm: FormGroup;
  disabledSubmitButton: boolean = true;

  @HostListener('input') oninput() {
    if (this.userForm.valid) {
      this.disabledSubmitButton = false;
    }
  }



  constructor( private fb: FormBuilder, private usersService: UsersService, private localeService: LocaleService ) { 

    this.userForm = fb.group({
      'name1': ['', Validators.required],
      'name2': [ '', Validators.compose([Validators.required])],
      'last_name1': ['', Validators.required],
      'last_name2': ['', Validators.required],
      'telephone': ['', Validators.required],
      'email': ['', Validators.required],

    });
  }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user'));

    this.getUserById();
  }

  get id() {
    return this.userForm.get('id_report');
  }

  get name1() {
    return this.userForm.get('name1');
  }

  get name2() {
    return this.userForm.get('name2');
  }

  get last_name1() {
    return this.userForm.get('last_name1');
  }

  get last_name2() {
    return this.userForm.get('last_name2');
  }

  get email() {
    return this.userForm.get('email');
  }

  get telephone() {
    return this.userForm.get('telephone');
  }


  updateUser$;
  onSubmit() {

    this.updateUser$ = this.usersService.updateUser(this.userForm.value, this.user.uid).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp);
        this.user = resp;       
      },
      error: (err) => { console.log('Received an error: ' + err)},
      complete: () => {  
          console.log('Completed');
          this.chargedUser();
  }
  });

  }


  userObserver;
  async getUserById() {

    console.log('ID USER: '+this.user.uid);

    this.userObserver = this.usersService.getUserById( this.user.uid ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp.name1);
        this.user = resp;
    },
    error: (err) => { 
      console.log('Received an error: ' + err)},
    complete: () => { 
        console.log('Completed');
        this.chargedUser();
      }
  });
  }

  chargedUser(){

    this.userForm = this.fb.group({
      'name1': [this.user.name1, Validators.required],
      'name2': [ this.user.name2, Validators.compose([Validators.required])],
      'last_name1': [this.user.last_name1, Validators.required],
      'last_name2': [this.user.last_name2, Validators.required],
      'telephone': [this.user.telephone, Validators.required],
      'email': [this.user.email, Validators.required],

    });

  }

  onResponseNormal(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }

}
