
import { KpiService } from '../../services/configuration/kpi/kpi.service';
import { InstrumentsService } from '../../services/backoffice/instruments/instruments.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';
import { ConnectionLinksService } from 'src/app/services/configuration/links/connection-links.service';
import { Subscription } from 'rxjs';

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import * as moment from 'moment';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  kpiForm: FormGroup;
  idMine: string;

  modalInfo: ModalInfo = new ModalInfo();

  dateInit: string = '';
  dateEnd: string = '';

  stateModalAlert: boolean = false;
  stateModal: boolean = false;

  elements = [];

  headElements = ['Ítem', 'Ponderación', 'Disponibilidad[%]', 'Utilización [%]', 'Observaciones'];

  dateNow = moment();
  public myDatePickerOptions: IMyOptions = {
    // Your options
    // dateFormat: 'dd-mm-yyyy'
    disableSince: { year:  this.dateNow.year(), month: this.dateNow.month() + 1, day: this.dateNow.date() + 1 }
  };

  types: any = [
    { value: 'radar' },
    { value: 'estación total' },
    { value: 'cámara' },
  ];

  valueRadar: boolean = false;

  instruments: any = [];
  kpi = [];
  links: any = [];

  instrumentsSubscription: Subscription;
  kpiSubscription: Subscription;
  instrumentsKpiSubscription: Subscription;
  connectionLinksSubscription: Subscription;

  fileName= 'ExcelSheet.xlsx';

  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService, private kpiservice: KpiService, private connectionLinksService: ConnectionLinksService ) { 
    this.idMine = JSON.parse(localStorage.getItem("mine")).id;
  }
  
  ngOnInit(): void {

  this.initForm();
  this.getConnectionLinksByMine(this.idMine);
  }

  ngOnDestroy(): void {

    this.instrumentsSubscription?.unsubscribe();
    this.kpiSubscription?.unsubscribe();
    this.instrumentsKpiSubscription?.unsubscribe();
    this.connectionLinksSubscription?.unsubscribe();
  }

  initForm(){

    this.kpiForm = this.fb.group({
      'id_faena': [''],
      'instrumentos': [''],
      'ponderacion_radar':[''],
      'ponderacion_estacion':[''],
      'ponderacion_camara':[''],
      'fecha_inicio_date':[''],
      'fecha_inicio_hora':[''],
      'fecha_termino_date':[''],
      'fecha_termino_hora':[''],
      'enlace_id':[''],
    })
  }

  onSubmit(){
    
    if(this.kpiForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');
      return
    }

    this.kpiForm.get('id_faena').setValue(this.idMine);

    this.dateInit = this.kpiForm.value.fecha_inicio_date +' '+ this.kpiForm.value.fecha_inicio_hora;
    this.dateEnd = this.kpiForm.value.fecha_termino_date + ' '+this.kpiForm.value.fecha_termino_hora;

    this.getKpiByFilter(this.kpiForm.value);
  }

  modalKpi(){

    this.addModal.show();
  }

  onResponseNormal(event){
    
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }


  createPdf(){

    const doc = new jsPDF()
    doc.setFontSize(25);
    doc.text("KPI", doc.internal.pageSize.width/2,20,undefined, 'center');
    doc.setFontSize(10);
    doc.text("Tabla KPI", doc.internal.pageSize.width/2,30,undefined, 'center');

    // var logo = new Image();
    // logo.src = '../../assets/logo.png';
    // doc.addImage(logo.src, 'PNG', 10, 10, 25, 12);

    // autoTable(doc, { html: '#htmlData', columns: [ '#', 'Nombre', 'Total HH Planificadas', 'Total HH Registradas', 'Fecha inicio', 'Fecha termino', 'Codigo' ],
    autoTable(doc, { html: '#htmlData',
      columnStyles: { 0: { halign: 'center', fillColor: [0, 131, 143] } },  margin: { top: 40 } })
    doc.save('table.pdf')
  }

  createExcel(){

    let element = document.getElementById('htmlData');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    XLSX.writeFile(wb, this.fileName);
  }

  createPng(){

    const element = document.getElementById('htmlTable');
      
      html2canvas(element).then(function (canvas) {
        const imageDataURL = canvas.toDataURL('image/png');

        const downloadLink = document.createElement('a');
        downloadLink.href = imageDataURL;
        downloadLink.download = 'table.png'; 
        downloadLink.click();
      });
  }


  getInstrumentsByMine(idmine: string){

    this.instrumentsSubscription = this.instrumentsService.getInstrumentsByMine(idmine).subscribe({
      next: (resp) => { 
         this.instruments = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getKpiByFilter(kpiForm: any){

    this.kpiSubscription = this.kpiservice.getKpiByFilter( kpiForm ).subscribe({
      next: (resp) => { 
         this.elements = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
        this.addModal.hide();
      }
    });
  }

  getInstrumentKpiByMine( id: string ){

    this.instrumentsKpiSubscription = this.kpiservice.getInstrumentKpiByMine( id, this.kpiForm.value.instrumentos ).subscribe({
      next: (resp) => { 
         this.instruments = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getConnectionLinksByMine(id: string){

    this.connectionLinksSubscription = this.connectionLinksService.getConnectionLinksByMine( id ).subscribe({
      next: (resp) => { 
         this.links = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  changedTypes(event) {
    console.log('EVENT: ' + event);
  
    const enableField = (field: string, found: boolean) => {
      const formControl = this.kpiForm.get(field);
      found ? formControl.enable() :  formControl.disable();
    };
  
    const foundRadar = event.includes('radar');
    const foundEstacion = event.includes('estación total');
    const foundCamara = event.includes('cámara');
  
    enableField('ponderacion_radar', foundRadar);
    enableField('ponderacion_estacion', foundEstacion);
    enableField('ponderacion_camara', foundCamara);
  
    this.getInstrumentKpiByMine(this.idMine);
  }
  
  
  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

}
