import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/backoffice/users/users.service';

import { ModalDirective } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';


@Component({
  selector: 'app-resend',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.scss']
})
export class ResendComponent implements OnInit {

  @ViewChild('modalAccept') modalAccept: ModalDirective;

  resendForm: FormGroup;
  modalInfo: ModalInfo = new ModalInfo();
  stateModalAlert: boolean = false;

  constructor(public formBuilder: FormBuilder, private router: Router, private userService: UsersService) { }

  ngOnInit(): void {

    this.initForm();
  }

  initForm(){

    this.resendForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email, Validators.minLength(8), Validators.maxLength(25)]],
    });
  }

  resend() {
  
    if (this.resendForm.invalid) {
  
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');
      return;
    }
  
    this.resendUser();
  }
  
  onResponseNormal(event){
    this.stateModalAlert = false;
  }
  
  
  returnLogin(){
  
    this.router.navigateByUrl('/login');
  }
  
  resendUser(){
  
    this.userService.resend( this.resendForm.value ).subscribe({
      next: (resp) =>{  
        console.log('Response: ' + resp.ok);
        this.messageAlert(true, 'success', resp.msg);
    },
    error: (err) => { 
      this.messageAlert(true, 'error', err.error.msg);
      console.log('Received an error: ' + err);
    },
    complete: () => { 
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 3000);
        console.log('Completed'); }
  });
  }

  get email() { return this.resendForm.get('email'); }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}