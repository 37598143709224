
<router-outlet></router-outlet>

    <!-- Start your project here-->
<!-- <div style="height: 100vh">
  <div class="flex-center flex-column">
    <h1 class="animated fadeIn mb-4">Material Design for Bootstrap (Angular)</h1>

    <h5 class="animated fadeIn mb-3">Thank you for using our product. We're glad you're with us.</h5>
    <h6 class="animated fadeIn mb-3">Start browsing <a href="https://mdbootstrap.com/angular">documentation</a>.</h6>

    <p class="animated fadeIn text-muted">MDB Team</p>
  </div>
</div> -->

    