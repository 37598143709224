
<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">{{ titleModal }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body px-4">
                <div class="row">

                    <div class="md-form md-outline col-md-2">
                      <mdb-select-2 [outline]="true" class="input-style" placeholder="Elegir Faena" label="Faena" style="background-color: white;">
                        <mdb-select-option *ngFor="let option of mines; let i = index" [value]="option.nombre" (click)="getSelectedMine(option)">{{ option.nombre_largo }}
                        </mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="md-form md-outline col-md-2">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Elegir Nivel Usuario" label="Nivel de Usuario" [(ngModel)]="levelFilter" style="background-color: white;">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of optionsLevel; let i = index" [value]="option" >{{ option }}</mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="md-form md-outline col-md-2">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Elegir Tipo" label="Tipo" [(ngModel)]="typeFilter" style="background-color: white;">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of optionsState; let i = index" [value]="option.id" >{{ option.value }}</mdb-select-option>
                      </mdb-select-2>
                    </div>
                    <div class="md-form md-outline col-md-2">
                      <input class="form-control" [(ngModel)]="nombreFilter" type="text" mdbInput style="background-color: white;">
                      <label for="form-lg" class="pl-4">Nombre</label>
                      <!-- <mdb-error *ngIf="max_range.invalid && (max_range.dirty || max_range.touched)" style="color: red;">Valor ingresado no es valido</mdb-error>
                      <mdb-success *ngIf="max_range.valid && (max_range.dirty || max_range.touched)" style="color: green;">Valor ingresado es valido</mdb-success> -->
                    </div>

                    <div class="md-form md-outline col-md-2">
                        <input class="form-control" [(ngModel)]="apellidoFilter" type="text" mdbInput style="background-color: white;">
                        <label for="form-lg" class="pl-4">Apellido</label>
                        <!-- <mdb-error *ngIf="max_range.invalid && (max_range.dirty || max_range.touched)" style="color: red;">Valor ingresado no es valido</mdb-error>
                        <mdb-success *ngIf="max_range.valid && (max_range.dirty || max_range.touched)" style="color: green;">Valor ingresado es valido</mdb-success> -->
                    </div>                  

                      <button type="button" mdbBtn class="waves-light button-accept-modals save-button md-form md-outline"  (click)="filter()"
                          mdbWavesEffect>Filtrar
                      </button> 
                      <button type="button" mdbBtn class="waves-light cancel-button save-button md-form md-outline"  (click)="reset()"
                          mdbWavesEffect>Reset
                      </button> 
                      <!-- <button type="button" mdbBtn class="waves-light button-accept-modals save-button md-form md-outline"  (click)="download()"
                          mdbWavesEffect>Descargar
                      </button>  -->

                </div>
              <div class="mt-0">
                <div class="table-container table-responsive" id="htmlTable">
                    <table mdbTable bordered="true" #htmlTable small="true"  class="z-depth-1 table-body table-striped table-bordered table-hover text-nowrap">
                      <thead>

                        <tr>
                          <ng-container>
                            <th scope="col" [attr.colspan]="16" class="cap-title-table text-center" *ngIf="!disabledButton">Tabla de Capacitaciones y Certificaciones</th>
                          </ng-container>
                        </tr>
                        <tr>
                          <ng-container *ngFor="let el of structure_table.level1">
                            <th scope="col" [attr.colspan]="el.qcolumn" class="cap-title text-center" *ngIf="el.qcolumn>0">{{ el.nombre | titlecase }}</th>
                          </ng-container>
                        </tr>
                        <tr>
                          <ng-container *ngFor="let el of structure_table.level2">
                            <th scope="col" [attr.colspan]="el.qcolumn" class="cap-title text-center cap-two-title" *ngIf="el.qcolumn>0">{{ el.nombre | titlecase }}</th>
                          </ng-container>
                        </tr>

                        <tr>
                          <ng-container *ngFor="let el of structure_table.level3">
                            <th scope="col" [attr.colspan]="1" class="cap-title text-center cap-three-title">{{ el }}</th>
                          </ng-container>
                        </tr>

                      </thead>
                      <tbody>
                        <tr mdbTableCol *ngFor="let el of elements">
                          <!-- <th scope="row">{{ el.id }}</th> -->
                  
                          <!-- <td>{{ el.first }}</td>
                          <td>{{ el.last }}</td>
                          <td>{{ el.handle }}</td>
                          <td>{{ el.handle2 }}</td> -->
                  
                  
                          <td class="table-text">{{ el.numero }}</td>
                          <td class="table-text">{{ el.nombre }}</td>
                          <td class="table-text">{{ el.apellido_p }}</td>
                          <td class="table-text">{{ el.nivel_usuario }}</td>


                          <ng-container *ngIf="el.evaluacion">
                            <td [ngClass]="getModClassAndIcon(el.evaluacion).className">
                              <i [ngClass]="getModClassAndIcon(el.evaluacion).icon"></i>
                            </td>
                          </ng-container>

                          <ng-container *ngIf="el.a2v">
                            <td [ngClass]="getModClassAndIcon(el.a2v).className">
                              <i [ngClass]="getModClassAndIcon(el.a2v).icon"></i>
                            </td>
                          </ng-container>

                          <ng-container *ngIf="el.prot">
                          <td [ngClass]="getModClassAndIcon(el.prot).className">
                            <i [ngClass]="getModClassAndIcon(el.prot).icon"></i>
                          </td>
                        </ng-container>

                        <ng-container *ngIf="el.geotecnia">
                          <td [ngClass]="getModClassAndIcon(el.geotecnia).className">
                            <i [ngClass]="getModClassAndIcon(el.geotecnia).icon"></i>
                          </td>
                        </ng-container>

                          <ng-container *ngIf="el.guardian">
                          <td [ngClass]="getModClassAndIcon(el.guardian).className">
                            <i [ngClass]="getModClassAndIcon(el.guardian).icon"></i>
                          </td>
                        </ng-container>

                          <ng-container *ngIf="el.hmi">
                          <td [ngClass]="getModClassAndIcon(el.hmi).className">
                            <i [ngClass]="getModClassAndIcon(el.hmi).icon"></i>
                          </td>
                        </ng-container>

                          <ng-container *ngIf="el.ssr">
                          <td [ngClass]="getModClassAndIcon(el.ssr).className">
                            <i [ngClass]="getModClassAndIcon(el.ssr).icon"></i>
                          </td>
                        </ng-container>

                          <ng-container *ngIf="el.ranger">
                          <td [ngClass]="getModClassAndIcon(el.ranger).className">
                            <i [ngClass]="getModClassAndIcon(el.ranger).icon"></i>
                          </td>
                        </ng-container>

                          <ng-container *ngIf="el.geomos">
                          <td [ngClass]="getModClassAndIcon(el.geomos).className">
                            <i [ngClass]="getModClassAndIcon(el.geomos).icon"></i>
                          </td>
                        </ng-container>

                          <ng-container *ngIf="el.t4d">
                          <td [ngClass]="getModClassAndIcon(el.t4d).className">
                            <i [ngClass]="getModClassAndIcon(el.t4d).icon"></i>
                          </td>
                        </ng-container>

                          <ng-container *ngIf="el.arcgis">
                          <td [ngClass]="getModClassAndIcon(el.arcgis).className">
                            <i [ngClass]="getModClassAndIcon(el.arcgis).icon"></i>
                          </td>
                        </ng-container>

                          <ng-container *ngIf="el.bosh">
                          <td [ngClass]="getModClassAndIcon(el.bosh).className">
                            <i [ngClass]="getModClassAndIcon(el.bosh).icon"></i>
                          </td>
                        </ng-container>


                          
                          <!-- <ng-container *ngIf="typeFilter == 'Certificación' || typeFilter == 'Todos'">

                            <td *ngIf="el.protocolo_procedimiento == 'activo'" class="table-text icon-active"><i class="fas fa-check-circle"></i></td>
                            <td *ngIf="el.protocolo_procedimiento == 'alert'" class="table-text icon-alert"><i class="fas fa-exclamation-triangle"></i></td>
                            <td *ngIf="el.protocolo_procedimiento == 'caducado'" class="table-text icon-finalized"><i class="fas fa-exclamation-circle"></i></td>
  
                            <td *ngIf="el.hmi_reutech == 'activo'" class="table-text icon-active"><i class="fas fa-check-circle"></i></td>
                            <td *ngIf="el.hmi_reutech == 'alert'" class="table-text icon-alert"><i class="fas fa-exclamation-triangle"></i></td>
                            <td *ngIf="el.hmi_reutech == 'caducado'" class="table-text icon-finalized"><i class="fas fa-exclamation-circle"></i></td>
  
                            <td *ngIf="el.ssr_viewer == 'activo'" class="table-text icon-active"><i class="fas fa-check-circle"></i></td>
                            <td *ngIf="el.ssr_viewer == 'alert'" class="table-text icon-alert"><i class="fas fa-exclamation-triangle"></i></td>
                            <td *ngIf="el.ssr_viewer == 'caducado'" class="table-text icon-finalized"><i class="fas fa-exclamation-circle"></i></td>
  
                            <td *ngIf="el.ranger_gbsar == 'activo'" class="table-text icon-active"><i class="fas fa-check-circle"></i></td>
                            <td *ngIf="el.ranger_gbsar == 'alert'" class="table-text icon-alert"><i class="fas fa-exclamation-triangle"></i></td>
                            <td *ngIf="el.ranger_gbsar == 'caducado'" class="table-text icon-finalized"><i class="fas fa-exclamation-circle"></i></td>
  
                            <td *ngIf="el.geomos_leica == 'activo'" class="table-text icon-active"><i class="fas fa-check-circle"></i></td>
                            <td *ngIf="el.geomos_leica == 'alert'" class="table-text icon-alert"><i class="fas fa-exclamation-triangle"></i></td>
                            <td *ngIf="el.geomos_leica == 'caducado'" class="table-text icon-finalized"><i class="fas fa-exclamation-circle"></i></td>
  
                            <td *ngIf="el.t4d_control == 'activo'" class="table-text icon-active"><i class="fas fa-check-circle"></i></td>
                            <td *ngIf="el.t4d_control == 'alert'" class="table-text icon-alert"><i class="fas fa-exclamation-triangle"></i></td>
                            <td *ngIf="el.t4d_control == 'caducado'" class="table-text icon-finalized"><i class="fas fa-exclamation-circle"></i></td>
  
                            <td *ngIf="el.arisg == 'activo'" class="table-text icon-active"><i class="fas fa-check-circle"></i></td>
                            <td *ngIf="el.arisg == 'alert'" class="table-text icon-alert"><i class="fas fa-exclamation-triangle"></i></td>
                            <td *ngIf="el.arisg == 'caducado'" class="table-text icon-finalized"><i class="fas fa-exclamation-circle"></i></td>
  
                            <td *ngIf="el.bosh == 'activo'" class="table-text icon-active"><i class="fas fa-check-circle"></i></td>
                            <td *ngIf="el.bosh == 'alert'" class="table-text icon-alert"><i class="fas fa-exclamation-triangle"></i></td>
                            <td *ngIf="el.bosh == 'caducado'" class="table-text icon-finalized"><i class="fas fa-exclamation-circle"></i></td>
                          </ng-container> -->

                        </tr>
                      </tbody>
                    </table>
                    <div class="labels-icons my-3" *ngIf="!disabledButton">
                      <p class="m-0"><i class="fas fa-circle normal-circle-label pr-2"></i>Activo</p>
                      <p class="m-0 pl-3"><i class="fas fa-circle alert-circle-label pr-2"></i>Por Vencer</p>
                      <p class="m-0 pl-3"><i class="fas fa-circle alarm-circle-label pr-2"></i>Vencido</p>
                    </div>
                  </div>
              </div>
              
            </div>
            <div class="modal-footer modals-footer">
                <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
                <button type="button" mdbBtn class="waves-light button-accept-modals save-button md-form md-outline"  (click)="download()" [disabled]="disabledButton"
                    mdbWavesEffect>Descargar
                </button> 
            </div>
        </div>
    </div>
</div>

<!-- <p>{{ objectForm.value|json }}</p> -->
<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
(reponseStateModalAccept)="onResponseNormal($event)"></app-modal-accept>


<!-- <p>{{ structure_table|json }}</p> -->
