
<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">{{ titleModal }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body">
              <div class="container mt-4 px-4">
                <form class="text-center forms-text" [formGroup]="objectForm">
            
                    <div class="mt-5" style="background-color: white;">
          
                        <div class="sub-headers-forms">
                          <span class="title-forms">General</span>
                        </div>
          
                        <div class="px-4">
                          <div class="row">
                            <div class="md-form md-outline mt-5 col-md-4">
                              <mdb-select-2 [outline]="true" placeholder="Seleccionar Compañia" label="Compañia (*)" [formControlName]="'compania'">
                                  <mdb-select-option *ngFor="let option of companies" [value]="option.name">{{ option.name }}</mdb-select-option>
                              </mdb-select-2>
                            </div>

                            <div class="md-form md-outline mt-5 col-md-4">
                              <input type="text" formControlName="nombre_largo" id="mineFormId1" class="form-control" mdbInput
                                mdbValidate />
                              <label for="mineFormId1" class="pl-4">Nombre Faena (*)</label>
                              <mdb-error *ngIf="getFieldControl('nombre_largo')?.invalid && (getFieldControl('nombre_largo')?.dirty || getFieldControl('nombre_largo')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                              <mdb-success *ngIf="getFieldControl('nombre_largo')?.valid && (getFieldControl('nombre_largo')?.dirty || getFieldControl('nombre_largo')?.touched)" class="pl-3">Entrada válida</mdb-success>                              
                            </div>
        
                            <div class="md-form md-outline mt-5 col-md-4">
                                <input type="text" formControlName="nombre_corto" id="mineFormId2" class="form-control" mdbInput
                                  mdbValidate />
                                <label for="mineFormId2" class="pl-4">Nombre faena(sigla) (*)</label>
                                <mdb-error *ngIf="getFieldControl('nombre_corto')?.invalid && (getFieldControl('nombre_corto')?.dirty || getFieldControl('nombre_corto')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                                <mdb-success *ngIf="getFieldControl('nombre_corto')?.valid && (getFieldControl('nombre_corto')?.dirty || getFieldControl('nombre_corto')?.touched)" class="pl-3">Entrada válida</mdb-success>                                
                            </div>
                 
                              <div class="md-form md-outline mt-5 col-md-4">
                                <input type="text" formControlName="email_svr" id="mineFormId3" class="form-control" mdbInput
                                  mdbValidate />
                                <label for="mineFormId3" class="pl-4">Email de contacto en SVR (*)</label>
                                <mdb-error *ngIf="getFieldControl('email_svr')?.invalid && (getFieldControl('email_svr')?.dirty || getFieldControl('email_svr')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                                <mdb-success *ngIf="getFieldControl('email_svr')?.valid && (getFieldControl('email_svr')?.dirty || getFieldControl('email_svr')?.touched)" class="pl-3">Entrada válida</mdb-success>
                              </div>
        
                              <div class="md-form md-outline mt-5 col-md-4">
                                <input type="text" formControlName="telefono_svr" id="mineFormId4" class="form-control" mdbInput
                                  mdbValidate />
                                <label for="mineFormId4" class="pl-4">Teléfono de contacto en SVR (*)</label>
                                <mdb-error *ngIf="getFieldControl('telefono_svr')?.invalid && (getFieldControl('telefono_svr')?.dirty || getFieldControl('telefono_svr')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                                <mdb-success *ngIf="getFieldControl('telefono_svr')?.valid && (getFieldControl('telefono_svr')?.dirty || getFieldControl('telefono_svr')?.touched)" class="pl-3">Entrada válida</mdb-success>
                              </div>
        
                              <div class="md-form md-outline mt-5 col-md-4">
                                <input type="text" formControlName="direccion_oficinas" id="mineFormId5" class="form-control" mdbInput
                                  mdbValidate />
                                <label for="mineFormId5" class="pl-4">Dirección de oficinas faena (*)</label>
                                <mdb-error *ngIf="getFieldControl('direccion_oficinas')?.invalid && (getFieldControl('direccion_oficinas')?.dirty || getFieldControl('direccion_oficinas')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                                <mdb-success *ngIf="getFieldControl('direccion_oficinas')?.valid && (getFieldControl('direccion_oficinas')?.dirty || getFieldControl('direccion_oficinas')?.touched)" class="pl-3">Entrada válida</mdb-success>
                              </div>
        
                              <div class="md-form md-outline mt-5 col-md-3">
                                <input type="text" formControlName="este" id="mineFormId6" class="form-control" mdbInput
                                  mdbValidate />
                                <label for="mineFormId6" class="pl-4">Coordenada Este (*)</label>
                                <mdb-error *ngIf="getFieldControl('este')?.invalid && (getFieldControl('este')?.dirty || getFieldControl('este')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                                <mdb-success *ngIf="getFieldControl('este')?.valid && (getFieldControl('este')?.dirty || getFieldControl('este')?.touched)" class="pl-3">Entrada válida</mdb-success>
                              </div>
                
                              <div class="md-form md-outline mt-5 col-md-3">
                                <input type="text" formControlName="norte" id="mineFormId7" class="form-control" mdbInput
                                  mdbValidate />
                                <label for="mineFormId7" class="pl-4">Coordenada Norte (*)</label>
                                <mdb-error *ngIf="getFieldControl('norte')?.invalid && (getFieldControl('norte')?.dirty || getFieldControl('norte')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                                <mdb-success *ngIf="getFieldControl('norte')?.valid && (getFieldControl('norte')?.dirty || getFieldControl('norte')?.touched)" class="pl-3">Entrada válida</mdb-success>
                              </div>

                              <div class="md-form md-outline mt-5 col-md-3">
                                  <input type="text" formControlName="cota" id="mineFormId8." class="form-control" mdbInput
                                    mdbValidate />
                                  <label for="mineFormId8" class="pl-4">Coordenada Cota (*)</label>
                                  <mdb-error *ngIf="getFieldControl('cota')?.invalid && (getFieldControl('cota')?.dirty || getFieldControl('cota')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                                  <mdb-success *ngIf="getFieldControl('cota')?.valid && (getFieldControl('cota')?.dirty || getFieldControl('cota')?.touched)" class="pl-3">Entrada válida</mdb-success>
                                </div>
        
                              <div class="input-time md-form md-outline mt-5 col-md-3">
                                <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                                  <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                                    label="Fecha de Inicio del mes faena (*)"  formControlName="inicio_mes" required>
                                  </mdb-date-picker>    
                                          
                                </div>
                              </div>     
                                                    
                          </div>
                        </div>  
                    </div>         

              </form>
              </div>
              
            </div>
            <div class="modal-footer modals-footer">
                <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
                <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitObject()" mdbWavesEffect>Guardar</button>
            </div>
        </div>
    </div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
(reponseStateModalAccept)="onResponseNormal($event)"></app-modal-accept>
