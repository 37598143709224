import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import { ModalInfo } from 'src/app/models/modal-info';

@Component({
  selector: 'app-sector-back',
  templateUrl: './sector-back.component.html',
  styleUrls: ['./sector-back.component.scss']
})
export class SectorBackComponent implements OnInit, OnDestroy {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();
  @Output() reponseObject = new EventEmitter();
  @Output() responseAddObject = new EventEmitter();

  modalInfo: ModalInfo = new ModalInfo();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  idObject: string = '';
  titleModal: string = 'Agregar Sector';
  
  servicesMine = [];

  types = [{"id": 1, "nombre":"Vigilancia"}, {"id": 2, "nombre":"Monitoreo"}];

  utime = [{"id": 1, "nombre":"dias"},{"id": 2, "nombre":"horas"}, {"id": 3, "nombre":"minutos"}, {"id": 4, "nombre":"segundos"}];
  udistance = [{"id": 1, "nombre":"mm"},{"id": 2, "nombre":"cm"}, {"id": 3, "nombre":"m"}];

  objectsSubscription: Subscription;

  constructor( private fb: FormBuilder, private minesbackService: MinesbackService, private router: Router, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id');
   }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'servicio_id': ['', Validators.required],
      'faena_id': [''],
      'nombre': ['', Validators.required],
      'unidad_tiempo': ['', Validators.required],
      'unidad_distancia': ['', Validators.required],
      'tipo': [''],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'servicio_id': this.data.servicio_id,
      'nombre': this.data.nombre,
      'unidad_tiempo': this.data.unidad_tiempo,
      'unidad_distancia': this.data.unidad_distancia,
      'tipo': this.data.tipo,
    });
  }


  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");

      this.addModal.show();
      this.reponseStateModalForm.emit(false);
      this.getServiciosByMine(this.idMine);

      if(this.objectForm){
        if(this.typeModal == 'edit'){
  
          console.log('EDIT ZONE: '+this.data.id);
          this.titleModal = 'Editar Sector'
          this.idObject = this.data.id;
          this.patchForm();
        }else{
          this.titleModal = 'Agregar Sector'
          this.objectForm.reset();
        }
      }  
    }  
  }

  onResponseAlert(event){

    this.stateModalAlert = event;
  }

  onSubmitObject(){

    console.log('ON SUBMIT');

    if(this.objectForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes'); 
      return
    }

    console.log('id in sector: '+this.idMine);
    this.objectForm.get('faena_id').setValue(this.idMine);

    this.typeModal == 'new' ? this.createSector() : this.updateSector();
  }
  
  getServiciosByMine(id: string){
  
    this.objectsSubscription = this.minesbackService.getServiciosByMine(id).subscribe({
      next: (resp) => { 
         this.servicesMine = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  createSector(){

    console.log('OBJECT: '+this.objectForm.value.tipo);
    this.minesbackService.createSector(this.objectForm.value, this.idMine).subscribe({
      next: (resp: any) =>{  
        const formValue = { ...this.objectForm.value };
        formValue['id'] = this.idObject;
        this.responseAddObject.emit(resp)
        this.reponseRefresh.emit(false);
        this.addModal.hide();
        this.objectForm.reset();
        this.messageAlert(true, 'success', 'Sector creado correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al crear sector');
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }

  updateSector(){

    this.minesbackService.updateSector(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{  
        const formValue = { ...this.objectForm.value };
        formValue['id'] = this.idObject;
        this.reponseRefresh.emit(false); 
        this.reponseObject.emit(formValue); 
        this.objectForm.reset();
        this.addModal.hide();
        this.messageAlert(true, 'success', 'Sector actualizado correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err)
      this.messageAlert(true, 'error', 'Error al actualizar sector');
    },
    complete: () => {  
        console.log('Completed');
    }
    });
  }

  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

}
