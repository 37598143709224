

<div class="padding-view">
    <!-- Default form contact -->
    <div class="breadcrumb-style">
      <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link pl-4">
        <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/home">Backoffice</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/backMines">Faenas</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="breadcrumbs-link">{{ dataParam }}</mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
    <div class="container z-depth-1 p-0 my-5">
      <div class="header-forms white-text header-div">
        <h5 class="header-title-forms">
            {{ dataParam }}
        </h5>
      </div>
      <div>
        <div class="mt-3 px-3">
            <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="addService()" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])"
                mdbWavesEffect><i class="fas fa-plus-circle mr-2"></i>Servico
            </button> 
            <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="addSector()" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])"
                mdbWavesEffect><i class="fas fa-plus-circle mr-2"></i>Sector
            </button> 
            <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="addZone()" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])"
                mdbWavesEffect><i class="fas fa-plus-circle mr-2"></i>Zona
            </button> 
            <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="addPhase()" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])"
                mdbWavesEffect><i class="fas fa-plus-circle mr-2"></i>Fase
            </button> 
            <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="addArea()" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])"
                mdbWavesEffect><i class="fas fa-plus-circle mr-2"></i>Área
            </button> 
            <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="addUsers()" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])"
                mdbWavesEffect><i class="fas fa-plus-circle mr-2"></i>Usuarios
            </button>             
            <button class="btn button-edit-table btn-effect" (click)="editMine()" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])" mdbTooltip="Editar Faena" placement="top">
                <i class="fas fa-edit"></i>
            </button>
            <button class="btn button-file btn-effect" (click)="showImages()">
                <i class="far fa-images"></i>
            </button>
            <button class="btn button-map btn-effect" (click)="showMap()" mdbTooltip="Mapa" placement="top">
                <i class="fas fa-map-marked"></i>
            </button>
        </div>
        <div class="my-3" *ngIf="viewMap == true">
            <app-map></app-map>
        </div>
        <div class="mt-3">
            <mdb-accordion [multiple]="false">
                <mdb-accordion-item [collapsed]="false" *ngFor="let service of services; let i = index">
                  <mdb-accordion-item-head class="accordion-head"><span class="title-accordion">{{ service.nombre }}</span></mdb-accordion-item-head>
                  <mdb-accordion-item-body class="service-body">
                    <div class="btn-div col-12 mt-2">
                        <!-- <button class="btn button-tools-table btn-effect" (click)="tools()" *ngIf="stateTools == false && authService.hasRole(['admin', 'geotecnico', 'supervisor'])" mdbTooltip="Herramientas" placement="bottom">
                            <i class="fas fa-tools"></i>
                        </button> -->
                        <button class="btn button-edit-table btn-effect" (click)="updateObjectService(service)" mdbTooltip="Editar" placement="bottom">
                            <i class="fas fa-edit"></i>
                        </button>
                        <button class="btn button-delete-table" (click)="deleteObjectService(service)" mdbTooltip="Eliminar" placement="bottom">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </div>
                    <div class="mt-2">
                        <mdb-accordion [multiple]="false">
                            <mdb-accordion-item [collapsed]="false"  *ngFor="let sector of service.mines; let i = index">
                              <mdb-accordion-item-head class="accordion-head-mine"><span class="title-accordion">{{ sector.nombre }}</span></mdb-accordion-item-head>
                              <mdb-accordion-item-body>
                                <div class="row">
                                    <div class="btn-div col-12 mt-2">
                                        <!-- <button class="btn button-tools-table btn-effect" (click)="tools()" *ngIf="stateTools == false && authService.hasRole(['admin', 'geotecnico', 'supervisor'])" mdbTooltip="Herramientas" placement="bottom">
                                            <i class="fas fa-tools"></i>
                                        </button> -->
                                        <button class="btn button-edit-table btn-effect" (click)="updateObjectSector(sector)" mdbTooltip="Editar" placement="bottom">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                        <button class="btn button-delete-table" (click)="deleteObjectSector(sector)" mdbTooltip="Eliminar" placement="bottom">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                    <div class="col-6">                            
                                        <div class="mt-2">
                                            <mdb-accordion [multiple]="false">
                                                <mdb-accordion-item [collapsed]="false" *ngFor="let zona of sector.zonas; let i = index">
                                                    <mdb-accordion-item-head class="accordion-head"><span class="title-accordion">{{ zona.nombre }}</span>                                                      
                                                    </mdb-accordion-item-head>
                                                    <mdb-accordion-item-body class="zone-body">
                                                        <div class="btn-div mt-2">
                                                            <!-- <button class="btn button-tools-table btn-effect" (click)="tools()" *ngIf="stateTools == false && authService.hasRole(['admin', 'geotecnico', 'supervisor'])" mdbTooltip="Herramientas" placement="bottom">
                                                                <i class="fas fa-tools"></i>
                                                            </button> -->
                                                            <button class="btn button-edit-table btn-effect" (click)="updateObjectZone(zona)" mdbTooltip="Editar" placement="bottom">
                                                                <i class="fas fa-edit"></i>
                                                            </button>
                                                            <button class="btn button-delete-table" (click)="deleteObjectZone(zona)" mdbTooltip="Eliminar" placement="bottom">
                                                                <i class="fas fa-trash-alt"></i>
                                                            </button>
                                                        </div>
                                                        <div class="table-container mt-2">
                                                            <table mdbTable bordered="true" id="htmlData" #htmlData small="true"
                                                                class="z-depth-1 table-body">
                                                                <thead class="table-head">
                                                                    <tr>
                                                                        <th *ngFor="let head of headElements" scope="col" class="head-table">{{ head }}
                                                                        </th>
                                                                    </tr>
                                    
                                                                </thead>
                                                                <tbody>
                                                                    <tr mdbTableCol *ngFor="let area of zona.areas">
                                    
                                                                        <td class="table-text">{{ area.nombre }}</td>
                                                                        <td class="table-text">
                                                                            <button class="btn button-edit-table" (click)="updateObjectArea(area)" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])" mdbTooltip="Editar" placement="bottom">
                                                                                <i class="fas fa-edit"></i>
                                                                            </button>
                                                                            <button class="btn button-delete-table" (click)="deleteObjectArea(area)" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])" mdbTooltip="Eliminar" placement="bottom">
                                                                                <i class="fas fa-trash-alt"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </mdb-accordion-item-body>
                                                </mdb-accordion-item>
                                    
                                            </mdb-accordion>
                                        </div>                                                                           
                                    </div>
                                    <div class="col-6">                            
                                        <div class="mt-2">
                                            <mdb-accordion [multiple]="false">
                                                <mdb-accordion-item [collapsed]="false">
                                                    <mdb-accordion-item-head class="accordion-head"><span class="title-accordion">Fases</span></mdb-accordion-item-head>
                                                    <mdb-accordion-item-body class="zone-body">
                                                        <div class="table-container mt-3">
                                                            <table mdbTable bordered="true" id="htmlData" #htmlData small="true"
                                                                class="z-depth-1 table-body">
                                                                <thead class="table-head">
                                                                    <tr>
                                                                        <th *ngFor="let head of headElementsFases" scope="col" class="head-table">{{ head }}
                                                                        </th>
                                                                    </tr>
                                    
                                                                </thead>
                                                                <tbody>
                                                                    <tr mdbTableCol *ngFor="let fase of sector.fases; let i = index">
                                    
                                                                        <td class="table-text">{{ fase.fase }}</td>
                                                                        <td class="table-text">{{ fase.areas_nombres }}</td>
                                                                        <td class="table-text">
                                                                            <button class="btn button-edit-table" (click)="updateObjectPhase(fase)" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])" mdbTooltip="Editar" placement="bottom">
                                                                                <i class="fas fa-edit"></i>
                                                                            </button>
                                                                            <button class="btn button-delete-table" (click)="deleteObjectPhase(fase)" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])" mdbTooltip="Eliminar" placement="bottom">
                                                                                <i class="fas fa-trash-alt"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </mdb-accordion-item-body>
                                                </mdb-accordion-item>
                                    
                                            </mdb-accordion>
                                        </div>                                                                           
                                    </div>
                                </div>

                              </mdb-accordion-item-body>
                            </mdb-accordion-item>
                          
                          </mdb-accordion>
                        </div>
                  </mdb-accordion-item-body>
                </mdb-accordion-item>
            </mdb-accordion>
        </div>
    </div>
    </div>


</div>
  
	<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert" (reponseDeleteObjectModal) = "onReponseDeleteObjectModal($event)" 
		(reponseStateModalAccept) = "onResponseAlertModal($event)"></app-modal-accept>

    <app-image-back [stateModal]="stateImages"
		(reponseStateModalForm) = "onResponseModalImage($event)"></app-image-back>


    <app-mine-back [stateModal]="stateModalMine" [data]="dataMine" [typeModal]="typeMine" (reponseRefresh)="onResponseRefresh()"
		(reponseStateModalForm) = "onReponseStateModalFormMine($event)"></app-mine-back>

    <app-service-back [stateModal]="stateModalService" [data]="dataService" [typeModal]="typeService" (reponseRefresh)="onResponseRefresh()" (reponseObject)="onReponseObjectService($event)" (responseAddObject)="onResponseAddObjectService($event)"
		(reponseStateModalForm) = "onReponseStateModalFormService($event)"></app-service-back>

    <app-sector-back [stateModal]="stateModalSector" [data]="dataSector" [typeModal]="typeSector" (reponseRefresh)="onResponseRefresh()" (reponseObject)="onResponseObjectSector($event)" (responseAddObject)="onReponseAddObjectSector($event)"
		(reponseStateModalForm) = "onReponseStateModalFormSector($event)"></app-sector-back>

    <app-zone-back [stateModal]="stateModalZone" [data]="dataZone" [typeModal]="typeZone" (reponseRefresh)="onResponseRefresh()" (reponseObject)="onResponseObjectZone($event)" (responseAddObject)="onReponseAddObjectZone($event)"
		(reponseStateModalForm) = "onReponseStateModalFormZone($event)"></app-zone-back>

    <app-area-back [stateModal]="stateModalArea" [data]="dataArea" [typeModal]="typeArea" (reponseRefresh)="onResponseRefresh()" (reponseObject)="onResponseObjectArea($event)" (responseAddObject)="onReponseAddObjectArea($event)"
        (reponseStateModalForm) = "onReponseStateModalFormArea($event)"></app-area-back>
        
    <app-phase-back [stateModal]="stateModalPhase" [data]="dataPhase" [typeModal]="typePhase" (reponseRefresh)="onResponseRefresh()" (reponseObject)="onResponseObjectPhase($event)" (responseAddObject)="onReponseAddObjectPhase($event)"
        (reponseStateModalForm) = "onReponseStateModalFormPhase($event)"></app-phase-back>

    <app-user-mine-back [stateModal]="stateModalUsers" [data]="idMine" [typeModal]="typePhase" (reponseRefresh)="onResponseRefresh()"
        (reponseStateModalForm) = "onReponseStateModalFormUsers($event)"></app-user-mine-back>