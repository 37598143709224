
<!-- <div class="table-body container">
    <app-table [data]="mines" [stateModal]="stateModal" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
     (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
     (reponseUpdateTable)="onreponseUpdateObjectTable($event)"></app-table>
</div> -->


<div class="container-fluid mt-3 px-0 mb-2">
    <!-- Default form contact -->
    <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link mt-3 p-0 mb-0 pb-0">
      <mdb-breadcrumb-item class="active">Faenas</mdb-breadcrumb-item>
    </mdb-breadcrumb>
    <mdb-card>
      <mdb-card-header class="header-forms white-text header-div">
        <h5 class="header-title-forms">
          Faenas
        </h5>
        <div class="add-icon">
            <a href="/emt/sectors/new"><mdb-icon fas icon="plus-circle" style="color: white;"></mdb-icon></a>
        </div>
      </mdb-card-header>
      <div class="table-body container">
        <app-table [data]="mines" [stateModal]="stateModalTable" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
         (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
         (reponseUpdateTable)="onreponseUpdateObjectTable($event)"></app-table>
     </div>
    </mdb-card>
    <!-- Default form contact -->
  </div>
  

   <div>
	<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal"
		(reponseStateModalAccept) = "onResponseNormal($event)"></app-modal-accept>
</div>