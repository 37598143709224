import { TrainingsService } from '../../../services/backoffice/training/trainings.service';

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';

@Component({
  selector: 'app-back-training-type',
  templateUrl: './back-training-type.component.html',
  styleUrls: ['./back-training-type.component.scss']
})
export class BackTrainingTypeComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() modalInfo: any;
  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() responseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idObject: string = '';
  titleModal: string = 'Agregar Capacitación/Certificación'
  

  state = [{"id": 1, "value":"Capacitación"}, {"id": 2, "value":"Certificación"}]


  constructor( private fb: FormBuilder, private minesbackService: MinesbackService, private trainingsService: TrainingsService, private router: Router, private route: ActivatedRoute) {  }

  ngOnInit(): void {

    this.initializeForm();
  }

    // async ngOnChanges(changes: SimpleChanges) {
  //   console.log("changes ", changes);
  //   // this.elements = this.data;
  //   // this.mdbTable.setDataSource(this.elements);
  //   // this.elements = this.mdbTable.getDataSource();
  //   // this.previous = this.mdbTable.getDataSource();
  //   // this.ngAfterViewInit();

  //   if (this.stateModal) {
  //     console.log("MESSAGE STATEMODAL TRUE CHANGES");

  //     this.addModal.show();

  //     this.responseStateModalForm.emit(false);
  //   }

  //   if(this.objectForm){
  //     if(this.typeModal == 'edit'){

  //       console.log('EDIT ZONE: '+this.data);
  //       console.log('EDIT ZONE: '+this.data.inicio_mes);
  //       this.titleModal = 'Editar Capacitación/Certificación';

  //       this.idObject = this.data.uid;
  //       // this.objectForm.get('sector_faena_id').setValue(this.data.sector_faena_id);

  //       this.objectForm.patchValue({
  //         categoria: this.data.categoria,
  //         tipo: this.data.tipo,
  //         nombre: this.data.nombre,
  //         periodo_validez: this.data.periodo_validez,
  //       });

  //       // this.objectForm.get('matriz').setValue(this.data.matriz);
  //     }else{
  //       this.objectForm.reset();
  //       this.titleModal = 'Agregar Capacitación/Certificación'
  //     }
  //   }
  // }

  private initializeForm(): void {
    this.objectForm = this.fb.group({
      categoria: ['', Validators.required],
      tipo: ['', Validators.required],
      nombre: ['', Validators.required],
      periodo_validez: ['', Validators.required],
      limite: [''],
    });
  }

  showAddModal(){

    this.addModal.show();
  }

  onResponseNormal(event){

    this.stateModalAlert = event;
  }


  onSubmitObject(){

    console.log('ON SUBMIT');

    if(this.objectForm.invalid){

      this.stateModalAlert = true;  
      this.modalInfo.messageModal = 'Debe completar los campos correspondientes';
      this.modalInfo.type = 'error';
      return
    }

    if(this.typeModal == 'new'){

      this.createUser();
    }else{
      // this.updateUser();
    }
 
  }


  createUser(){
    
    this.objectForm.get('limite').setValue("60");
    
    this.trainingsService.createTypeTraining(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
        this.reponseRefresh.emit(false);
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
        this.objectForm.reset();
        this.addModal.hide();
        this.stateModalAlert = true;  
        this.modalInfo.messageModal = 'Usuario creado correctamente';
        this.modalInfo.type = 'success'  
    }
  });
  }

  // updateUser(){

  //   // const start = this.objectForm.value.inicio_mes.toString()+ "T" +"00:00:00".toString();
  //   // this.objectForm.get('inicio_mes').setValue(start);

  //   this.usersService.updateUser(this.objectForm.value, this.idObject).subscribe({
  //     next: (resp: any) =>{  
  //       this.reponseRefresh.emit(false);
  //     },
  //   error: (err) => { console.log('Received an error: ' + err)},
  //   complete: () => {  
  //       console.log('Completed');
  //       this.objectForm.reset();
  //       this.addModal.hide();
  //       this.stateModalAlert = true;  
  //       this.modalInfo.messageModal = 'Usuario actualizado correctamente';
  //       this.modalInfo.type = 'success'  
  //   }
  // });
  // }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModal = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
  
}




