
import { ConnectionLinksService } from '../../../services/configuration/links/connection-links.service';
import { InstrumentsService } from '../../../services/backoffice/instruments/instruments.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-losses',
  templateUrl: './losses.component.html',
  styleUrls: ['./losses.component.scss']
})
export class LossesComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  timesForm: FormGroup;

  idLinkConnection: string = '';
  linkCode: string = '';

  headElements = ['N°', ' F/H Inicio', 'F/H Termino', 'Duración[H]', 'Observación'];
  headTable = [ 'fecha_inicio', 'fecha_termino', 'tiempo_caido', 'observacion'];
  colspanLarge: number = 7;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateModalTable: boolean = false;

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  stateModalAlert: boolean = false;

  linkLossesSubscription: Subscription;
  getLossesSubscription: Subscription;
  typeLosses = [];
  times = [];

  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService, private connectionLinksService: ConnectionLinksService, private router: Router, private route: ActivatedRoute, public authService: AuthService) { 

      this.idLinkConnection = this.route.snapshot.paramMap.get('id');
      this.linkCode = this.route.snapshot.paramMap.get('data');
  }

  ngOnInit(): void {

    this.initForm();
    this.getLinkLossesByMine(this.idLinkConnection);
    this.getLossesType();
  }

  ngOnDestroy(): void {
    this.linkLossesSubscription?.unsubscribe();
    this.getLossesSubscription?.unsubscribe();
  }

  initForm(){

    this.timesForm = this.fb.group({
      'fecha_inicio_date': [''],
      'fecha_inicio_hora': [''],
      'fecha_fin_date': [''],
      'fecha_fin_hora': [''],
      'faena_id': [''],
      'observacion': [''],
    });
  }


  onResponseAlert(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }

  showAddModal(){

    this.addModal.show();
  }


  onSubmitLinkLosses(){

    console.log('ON SUBMIT');

    if(this.timesForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');  
      return
    }

    this.createLinkLosses();
 
  }

  goLink(){

    this.router.navigateByUrl('/emt/connectionLinks' );
  }


  createLinkLosses(){

    this.connectionLinksService.createLinkLosses(this.timesForm.value, this.idLinkConnection).subscribe({
      next: (resp: any) =>{  
  
        this.timesForm.reset();
        this.addModal.hide();   
        this.getLinkLossesByMine(this.idLinkConnection);             
        this.messageAlert(true, 'success', 'Perdida de tiempo creado correctamente');   
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
    }
  });
  }
  
  getLossesType(){
  
    this.getLossesSubscription = this.instrumentsService.getLossesType( ).subscribe({
      next: (resp) => { 
         this.typeLosses = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getLinkLossesByMine(id: string){

    this.linkLossesSubscription = this.connectionLinksService.getLinkLossesByMine( id ).subscribe({
      next: (resp) => { 
         this.times = resp;
         console.log('Response: ' + resp); 
         this.stateModalTable = true;  
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
