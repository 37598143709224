
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import { ModalInfo } from 'src/app/models/modal-info';

@Component({
  selector: 'app-service-back',
  templateUrl: './service-back.component.html',
  styleUrls: ['./service-back.component.scss']
})
export class ServiceBackComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() stateModal: boolean;
  @Input() stateModalAlert: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();
  @Output() reponseObject = new EventEmitter();
  @Output() responseAddObject = new EventEmitter();

  modalInfo: ModalInfo = new ModalInfo();
  objectForm: FormGroup;

  idObject: string;
  idMine: string = '';

  stateModalTable: boolean = false;
  
  titleModal: string = 'Vigilancia y Monitoreo'

  constructor( private fb: FormBuilder, private minesbackService: MinesbackService, private router: Router, private route: ActivatedRoute) { 
      this.idMine = this.route.snapshot.paramMap.get('id'); 
  }

  ngOnInit(): void {
      
    this.initForm();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'faena_id': [''],
      'nombre': ['', Validators.required],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'faena_id': this.idMine,
      'nombre': this.data.nombre,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");

      this.addModal.show();
      this.reponseStateModalForm.emit(false);

      if(this.objectForm){
        if(this.typeModal == 'edit'){
    
          this.titleModal = 'Editar Servicio'
          this.idObject = this.data.id;
          this.patchForm();
        }else{
          this.titleModal = 'Agregar Servicio'
          this.objectForm.reset();
        }
      }
    }
  }

  onResponseAlert(event){
    this.stateModalAlert = false;
    console.log('RESPONSE MINE NORMAL');
  }

  onSubmitObject(){

    console.log('ON SUBMIT');
    if(this.objectForm.invalid){
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');      
      return

    }
    this.typeModal == 'new' ? this.createService() : this.updateService();
  }

  createService(){
  
    this.objectForm.get('faena_id').setValue(this.idMine);
    console.log(this.objectForm.value.faena_id);
    this.minesbackService.createService( this.objectForm.value ).subscribe({
      next: (resp) => { 
        this.responseAddObject.emit(resp)
        this.reponseRefresh.emit(false); 
         console.dir('Response: ' + resp); 
         this.objectForm.reset();
         this.addModal.hide();
         this.messageAlert(true, 'success', 'Servicio creado correctamente');          
        },
      error: (err) => { 
        console.log('Received an error: ' + err.message);
        this.messageAlert(true, 'error', 'Error al actualizar servicio');      
      },
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  updateService(){

    console.log('OBJECT ID: '+ this.idObject);
    this.minesbackService.updateService(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{
        const formValue = { ...this.objectForm.value };
        formValue['id'] = this.idObject;
        this.reponseObject.emit(formValue);
        this.reponseRefresh.emit(false);  
        this.objectForm.reset();
        this.addModal.hide();
        this.messageAlert(true, 'success', 'Servicio actualizado correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar servicio');
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }

  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }
  
  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
