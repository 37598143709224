import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-training-table-state',
  templateUrl: './back-training-table-state.component.html',
  styleUrls: ['./back-training-table-state.component.scss']
})
export class BackTrainingTableStateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
