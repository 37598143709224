
<div class="container-fluid mt-3 px-0 mb-2" style="z-index: 100;">
    <!-- Default form contact -->
    <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link mt-3 p-0 mb-0 pb-0">
      <mdb-breadcrumb-item (click)="goInstruments()">Instrumentos</mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">Incidencias</mdb-breadcrumb-item>
    </mdb-breadcrumb>
    <mdb-card>
      <mdb-card-header class="header-forms white-text header-div">
        <div></div>
        <div>
          <h5 class="header-title-forms">
            Listado de Incidencias de {{ instrumentCode }}
          </h5>
        </div>
        <div class="add-icon">
            <a (click)="showAddModal()"><mdb-icon fas icon="plus-circle" (click)="showAddModal()" style="color: white;" *ngIf="authService.hasRole(['admin', 'supervisor', 'vigilante'])" mdbTooltip="Agregar" placement="top"></mdb-icon></a>
        </div>
      </mdb-card-header>
      <div class="table-body container">
        <app-table-times [data]="times" [stateModal]="stateModalTable" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
         (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
         (reponseUpdateTable)="onreponseUpdateObjectTable($event)"
         (reponseTimeTable)="onreponseTimeObjectTable($event)"
         (reponseChangeTable)="onreponseChangeObjectTable($event)">
        </app-table-times>
     </div>
    </mdb-card>
    <!-- Default form contact -->
  </div>
  

   <div>
	<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal"
		(reponseStateModalAccept) = "onResponseNormal($event)"></app-modal-accept>
</div>



<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">Registro de incidencias de {{ instrumentCode }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body">
              <div class="container mt-4 px-4">
                <form class="text-center row forms-text" [formGroup]="timesForm">

        
                  <div class="col-12 mx-auto" style="background-color: white;">
                    <div class="row">
        
                      <div class="col-12 sub-headers-forms">
                        <span class="title-forms modals-subtitle">General</span>
                      </div>
        
                      <div class="col-12">
                        <div class="row px-3">

                          <div class="md-form md-outline mt-5 col-6">
                            <mdb-select-2 [outline]="true" placeholder="Seleccionar" label="Escala de tiempo (*)" [formControlName]="'estado_id'" (ngModelChange)="changedType($event)">
                                <mdb-select-option *ngFor="let option of stateLosses" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                            </mdb-select-2>
                        </div>

                          <div class="md-form md-outline mt-5 col-6">
                            <mdb-select-2 [outline]="true" placeholder="Seleccionar" label="Listado de incidencia (*)" [formControlName]="'perdida_id'">
                                <mdb-select-option *ngFor="let option of typeLosses" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                            </mdb-select-2>
                        </div>

                          <div class="input-time md-form md-outline mt-5 col-2">
                            <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                              <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                                label="Fecha de Inicio (*)"  formControlName="fecha_inicio_date" required>
                              </mdb-date-picker>            
                            </div>
                          </div>
        
        
                          <div class="md-form  md-form md-outline mt-5 col-2">
                            <input
                              type="time"
                              id="input"
                              name="appt-time"
                              class="form-control"
                              formControlName="fecha_inicio_hora"
                              value="00:00"
                              mdbInput
                            />
                            <label for="input" class="pt-1 pr-3">Hora de Inicio (*)</label>
                          </div>

                          <div class="input-time md-form md-outline mt-5 col-2">
                            <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                              <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                                label="Fecha de Término (*)"  formControlName="fecha_fin_date" required>
                              </mdb-date-picker>            
                            </div>
                          </div>
        
        
                          <div class="md-form md-outline mt-5 col-2">
                            <input
                              type="time"
                              id="input"
                              name="appt-time"
                              class="form-control"
                              formControlName="fecha_fin_hora"
                              value="00:00"
                              mdbInput
                            />
                            <label for="input" class="pt-1 pr-3">Hora de Término (*)</label>
                          </div>
        
        
                          <div class="md-form md-outline mt-2 col-12 text-div">
                            <textarea class="md-textarea form-control" formControlName="observacion" rows="3" id="input_text" type="text" length="120" mdbCharCounter mdbInput></textarea>
                            <label for="input_text" class="pl-4">Observación</label>
                            <mdb-error *ngIf="getFieldControl('observacion')?.invalid && (getFieldControl('observacion')?.dirty || getFieldControl('observacion')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                            <mdb-success *ngIf="getFieldControl('observacion')?.valid && (getFieldControl('observacion')?.dirty || getFieldControl('observacion')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                          </div>
        
        
                        </div>
        
                        <!-- <div class="modal-footer modals-footer pt-3">
                            <button type="button" mdbBtn class="waves-light cancel-button" (click)="goInstruments()" mdbWavesEffect>Cancelar</button>
                              <button type="submit" mdbBtn class="waves-light save-button" mdbWavesEffect>Guardar</button>
                          </div> -->
                      </div>
                    </div>
                  </div>
        
        
         
              </form>
              </div>
              
            </div>
            <div class="modal-footer modals-footer">
                <!-- <button type="button" mdbBtn color="secondary" size="sm" class="bwaves-light" data-dismiss="modal" (click)="addModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect>Save changes</button> -->
                <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
                <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitTime()" mdbWavesEffect>Guardar</button>
            </div>
        </div>
    </div>
</div>