import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  constructor() { }
  zoom = 12;
  center: google.maps.LatLngLiteral;
  
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };

  ngOnInit(): void {

    this.center = {
      lat: -33.065933,
      lng: -71.572742,
    };

    navigator.geolocation.getCurrentPosition((position) => {
      // this.center = {
      //   lat: -33.065933,
      //   lng: -71.572742,
      // };
    });
  }

    zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++;
  }
 
  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--;
  }

}
