import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from 'src/app/services/backoffice/users/users.service';

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { TrainingsService } from 'src/app/services/backoffice/training/trainings.service';

@Component({
  selector: 'app-back-training-state',
  templateUrl: './back-training-state.component.html',
  styleUrls: ['./back-training-state.component.scss']
})

export class BackTrainingStateComponent implements OnInit, OnDestroy {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() modalInfo: any;
  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Input() mines: any;
  @Output() responseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idObject: string = '';
  titleModal: string = 'Capacitaciones/Certificaciones'
  
  disabledButton: boolean = true;

  state = [{"id": 1, "value":"Capacitación"}, {"id": 2, "value":"Certificación"}];

  optionsState = [{"id": "evaluacion", "value": "Plan Interno de Capacitación"}, 
        {"id": "A2V", "value": "A2V"},
        {"id": "Prot Proc", "value": "Protocolo y Procedimiento	"},
        {"id": "Geotecnia", "value": "Geotecnia"},
        {"id": "Guardian(IBIS)", "value": "Guardian (IBIS)"},
        {"id": "HMI (Reutech)	", "value": "HMI(Reutech)"},
        {"id": "SSR Viewer (GP FX O XT)", "value": "A2V"},
        {"id": "Ranger (Fast GBSAR)", "value": "Ranger (Metasensing)"},
        {"id": "Geomos EETT Leica", "value": "Geomos ET Leica	"},
        {"id": "T4D Control", "value": "T4D Control ET Trimble	"},
        {"id": "ArcGis", "value": "ArisG"},
        {"id": "Bosh", "value": "Bosh"}
  ];
    
  optionsLevel = ["admin","supervisor", "vigilante"];

  structure_table: any = [];
  originalElements = [];
  elements: any = [];

  nombreFilter: string = '';
  apellidoFilter: string = '';
  typeFilter: string = 'Todos';
  levelFilter: string = '';
  mineFilter: string = '';

  trainningSateSubscription: Subscription; 
  
  constructor( private fb: FormBuilder, private trainingsService: TrainingsService, private usersService: UsersService, private router: Router, private route: ActivatedRoute) {  }

  ngOnInit(): void {

  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES: "+this.stateModal);

      this.addModal.show();
      this.responseStateModalForm.emit(false);
    }
  }

  ngOnDestroy(){

    this.trainningSateSubscription?.unsubscribe();
 }

  showAddModal(){

    this.addModal.show();
  }

  onResponseNormal(event){

    this.stateModalAlert = event;
  }

  async onSubmitObject(){

    console.log('GET TRAINING STATE');

    this.structure_table = await this.getTrainingState();
    this.originalElements = this.structure_table;
  }


  filterStructure_table = [];
  // beforeNameFilter: string;
  // beforeLastNameFilter: string;

  async filter(){
    
    // this.beforeNameFilter = this.nombreFilter;
    // this.beforeLastNameFilter = this.apellidoFilter;

    this.structure_table = await this.getTrainingState();
    this.originalElements = this.structure_table.elements;
    this.elements = this.originalElements;
    
    console.log('APELLIDO FILTER: '+this.apellidoFilter);
    // this.elements = this.originalElements;

    if(this.nombreFilter.length > 0) this.elements = this.elements.filter((object) => object.nombre.toLowerCase() == this.nombreFilter.toLowerCase());
    if(this.apellidoFilter.length > 0) this.elements = this.elements.filter((object) => object.apellido_p.toLowerCase() == this.apellidoFilter.toLowerCase() );
    if(this.mineFilter.length > 0) this.elements = this.elements.filter((object) => object.faenas_nombres.includes(this.mineFilter));

    // if(this.levelFilter.length > 0) this.elements = this.elements.filter((object) => object.level.toLowerCase() == this.levelFilter.toLowerCase() );
    this.disabledButton = false;
  }

  reset(){

    this.elements =  this.originalElements;
  }

  download(){

    const element = document.getElementById('htmlTable');
      
      html2canvas(element).then(function (canvas) {
        const imageDataURL = canvas.toDataURL('image/png');

        const downloadLink = document.createElement('a');
        downloadLink.href = imageDataURL;
        downloadLink.download = 'table.png'; 
        downloadLink.click();
      });
  }


  getModClassAndIcon(modValue: string): { className: string, icon: string } {
    let className = '';
    let icon = '';
    if (modValue === 'vigente') {
      className = 'table-text icon-active';
      // icon = 'fas fa-check-circle';
      icon = 'fas fa-circle';
    } else if (modValue === 'alert') {
      className = 'table-text icon-alert';
      // icon = 'fas fa-exclamation-triangle';
      icon = 'fas fa-circle';
    } else if (modValue === 'caducado') {
      className = 'table-text icon-finalized';
      // icon = 'fas fa-exclamation-circle';
      icon = 'fas fa-circle';
    } else {
      className = 'table-text icon-circle';
      // icon = 'fas fa-minus-circle';
      icon = 'fas fa-circle';
    }

    
    return { className, icon };
  }

  getSelectedMine(event: any){

    this.mineFilter = event.nombre_largo;
  }

  getTrainingState() {
    return new Promise(
      (resolve, reject) =>
        (this.trainningSateSubscription = this.trainingsService.getTrainingState(this.typeFilter, this.levelFilter).subscribe({
          next: (resp) => {
            console.dir("Training: " + resp);
            resolve(resp);
            // this.responseColors.emit(resp);
          },
          error: (err) => {
            console.log("Received an error: " + err.message);
            this.messageAlert(true, 'error',  err.message);
            reject(err);
          },
          complete: () => {
            console.log("Completed Get Training State");
            // this.spinner.hide();
          },
        }))
    );
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModal = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

  

}




