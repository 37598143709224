// auth.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private isAuthenticatedValue = false;
  private userRoles: string[] = [];

  login(): void {
    // Lógica para autenticar al usuario y obtener roles del servidor
    // ...
    console.log('login: ');
    this.isAuthenticatedValue = true;
    //this.userRoles = ['admin', 'user']; // Simulación de roles obtenidos del servidor

    localStorage.setItem('isAuthenticated', this.isAuthenticatedValue.toString());
    //localStorage.setItem('userRoles', JSON.stringify(this.userRoles));
  }

  logout(): void {
    // Lógica para cerrar sesión
    // ...
    this.isAuthenticatedValue = false;
    this.userRoles = [];
  }

  isAuthenticated(): boolean {

    this.isAuthenticatedValue = JSON.parse(localStorage.getItem('isAuthenticated'));
    //console.log('isAuthenticated: '+this.isAuthenticatedValue);
    return this.isAuthenticatedValue;
  }

  hasRole(expectedRoles: string[]): boolean {
    //console.log('hasRole: ' + expectedRoles);
  
    const storedUserRolesString = localStorage.getItem('userRoles').toString();
    //console.log('storedUserRolesString: ' + storedUserRolesString);
  
    this.userRoles = storedUserRolesString ? JSON.parse(storedUserRolesString) : [];
    
    const hasAtLeastOneRole = expectedRoles.some(role => this.userRoles.includes(role));
  
    //console.log('ROLES INCLUDE: ' + hasAtLeastOneRole);
    return hasAtLeastOneRole;
  }
}
