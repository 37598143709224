import { Subscription } from 'rxjs';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstrumentsService } from 'src/app/services/backoffice/instruments/instruments.service';
import { IncidentsService } from 'src/app/services/backoffice/incidence/incidents.service';

@Component({
  selector: 'app-incidence-back',
  templateUrl: './incidence-back.component.html',
  styleUrls: ['./incidence-back.component.scss']
})
export class IncidenceBackComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() modalInfo: any;
  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() dataTimeLine: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  titleModal: string = 'Agregar Incidencia'

  idObject: string = '';

  constructor( private fb: FormBuilder, private incidentsService: IncidentsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.initForm();
  }

  initForm(){
    this.objectForm = this.fb.group({
      'nombre': ['', Validators.required],
      'estado_id': ['', Validators.required]
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");
      this.addModal.show();
      this.reponseStateModalForm.emit(false);
    }

    if(this.objectForm){
      if(this.typeModal == 'edit'){

        this.titleModal = 'Editar Incidencia';
        this.idObject = this.data.id;
        console.log('ID: '+this.data.estado_id);
        this.objectForm.get('nombre').setValue(this.data.nombre);
        this.objectForm.get('estado_id').setValue(this.data.estado_id);
      }else{
        this.titleModal = 'Agregar Incidencia';
        this.objectForm.reset();
      }
    }
  }

  showAddModal(){

    this.addModal.show();
  }

  onResponseNormal(event){
    
    this.stateModalAlert = event;
  }


  onSubmitObject(){

    console.log('ON SUBMIT');

    if(this.objectForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondiente'); 
      return
    }

    this.typeModal == 'new' ? this.createIncidence() : this.updateIncidence();

  }


  createIncidence(){

    this.incidentsService.createIncidence(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
        this.reponseRefresh.emit(false);      
        this.messageAlert(true, 'success', 'Incidencia creada correctamente'); 
        this.objectForm.reset();
      },
      error: (err) => {
         console.log('Received an error: ' + err);
         this.messageAlert(true, 'error',  err.message);
        },
      complete: () => {  
          console.log('Completed');
          this.addModal.hide();
      }
  });
  }

  updateIncidence(){

    this.incidentsService.updateIncidence(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{  
      this.reponseRefresh.emit(false);  
      this.messageAlert(true, 'success', 'Incidencia actualizada correctamente');
      this.objectForm.reset();
      },
      error: (err) => { 
        console.log('Received an error: ' + err);
        this.messageAlert(true, 'error',  err.message);
  
      },
      complete: () => {  
          console.log('Completed');
          this.addModal.hide();
      }
    });
  }

  get input() { return this.objectForm.get('nombre'); }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
