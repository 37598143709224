import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import { UsersService } from 'src/app/services/backoffice/users/users.service';

@Component({
  selector: 'app-user-back',
  templateUrl: './user-back.component.html',
  styleUrls: ['./user-back.component.scss']
})
export class UserBackComponent implements OnInit, OnDestroy {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() modalInfo: any;
  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();

  objectForm: FormGroup;
  sectorsMine = [];

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  idObject: string = '';
  titleModal: string = 'Editar Usuario'
  
  objectsSubscription: Subscription;
 
  public myDatePickerOptions: IMyOptions = {
    // Your options
    //  dateFormat: 'DD/MM/YYYY'
  };

  level = [{"id": 1, "name":"super_admin"}, {"id": 2, "name":"geotecnico"},{"id": 3, "name":"admin_contrato"}, {"id": 4, "name":"desarrollo"},{"id": 3, "name":"supervisor"}, {"id": 4, "name":"vigilante"}]
  state = [{"id": 1, "value": true, "name":"Activo"}, {"id": 2, "value": false, "name":"Inactivo"}]

  constructor( private fb: FormBuilder, private minesbackService: MinesbackService, private usersService: UsersService, private router: Router, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id');
   }

  ngOnInit(): void {

    this.initForm();
    this.getSectors();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'name1': ['', Validators.required],
      'last_name1': ['', Validators.required],
      'last_name2': ['', Validators.required],
      'rut': ['', Validators.required],
      'email': ['', [Validators.required, Validators.email]],
      'telephone': [ '', Validators.required],
      'address': ['', Validators.required],
      'user_level': ['', Validators.required],
      'educational_level': ['', Validators.required],
      'role': ['', Validators.required],
      'active': ['', Validators.required],
      'start_date': ['', Validators.required],
      'end_date': [''],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'name1': this.data.name1,
      'last_name1': this.data.last_name1,
      'last_name2': this.data.last_name2,
      'rut': this.data.rut,
      'telephone': this.data.telephone,
      'address': this.data.address,
      'user_level': this.data.user_level,
      'educational_level': this.data.educational_level,
      'company': this.data.companyId,
      'active': this.data.active,
      'email': this.data.email,
      'role': this.data.role,
      'start_date': this.data.start_date,
      'end_date': this.data.end_date
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {

      console.log("MESSAGE STATEMODAL TRUE CHANGES");
      this.addModal.show();
      this.reponseStateModalForm.emit(false);

      if(this.objectForm){
        if(this.typeModal == 'edit'){
  
          console.log('EDIT ZONE: '+this.data);
  
          this.titleModal = 'Editar Usuario';
          this.idObject = this.data.uid;
          this.patchForm();

        }else{
          this.objectForm.reset();
          this.titleModal = 'Agregar Usuario'
        }
      }
    }
  }

  showAddModal(){

    this.addModal.show();
  }

  onResponseAlert(event){

    this.stateModalAlert = event;
  }

  onSubmitObject(){

    console.log('ON SUBMIT');

    if(this.objectForm.invalid){

      this.messageAlert( true, 'error',  'Debe completar los campos correspondientes');
      return
    }

    // if(this.objectForm.value.start_date > this.objectForm.value.end_date){
    //   this.messageAlert( true, 'error',  'La fecha de salida debe ser menor que la fecha de entrada');
    //   return
    // }

    this.typeModal == 'new' ? this.createUser() : this.updateUser(); 
  }


  createUser(){

    console.log('END DATE: '+this.objectForm.value.end_date);
    this.objectForm.get('start_date').patchValue(new Date(this.objectForm.value.start_date).toISOString());
    this.objectForm.value.end_date != null ? this.objectForm.get('end_date').patchValue(new Date(this.objectForm.value.end_date).toISOString()) : this.objectForm.get('end_date').patchValue(null) ;

    this.usersService.createUser(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
        this.reponseRefresh.emit(false);
        this.objectForm.reset();
        this.addModal.hide(); 
        this.messageAlert( true, 'success', 'Usuario creado correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      console.dir('Received an error: ' + err);

      this.messageAlert( true, 'error', err.error.message);
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }

  updateUser(){

    this.objectForm.get('start_date').patchValue(new Date(this.objectForm.value.start_date).toISOString());
    this.objectForm.value.end_date != null ? this.objectForm.get('end_date').patchValue(new Date(this.objectForm.value.end_date).toISOString()) : this.objectForm.get('end_date').patchValue(null) ;

    this.usersService.updateUser(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{  
        this.reponseRefresh.emit(false);
        this.objectForm.reset();
        this.addModal.hide();
        this.messageAlert( true, 'success', 'Usuario actualizado correctamente');
      },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert( true, 'error', 'Error al actualizar usuario');
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }
  
 
  getSectors(){
  
    this.objectsSubscription = this.minesbackService.getSectors( ).subscribe({
      next: (resp) => { 
         this.sectorsMine = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }
  
  messageAlert(state: boolean, type: string, messageModal: string){
    
    this.stateModalAlert = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}

