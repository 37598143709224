
<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">{{ titleModal }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body">
              <div class="container my-4 px-4">
                <div class="text-center forms-text">
            
                    <div class="mt-5 z-depth-2" style="background-color: white;">
          
                        <div class="sub-headers-forms">
                          <span class="title-forms">General</span>
                        </div>
          
                        <div class="px-4">
                          <div class="row">

                            <div class="md-form md-outline col-md-4">
                                <mdb-select-2 [outline]="true" placeholder="Seleccionar Usuario" label="Usuario" [(ngModel)]="userFilter" [disabled]="disabledButton">
                                    <mdb-select-option *ngFor="let option of users" [value]="option.id">{{ option.nombre + ' ' + option.apellido_p }}</mdb-select-option>
                                </mdb-select-2>
                              </div>

                              <div class="md-form md-outline col-md-4">
                                <mdb-select-2 [outline]="true" placeholder="Seleccionar Cap/Cert" label="Cap/Cert" [(ngModel)]="typeFilter">
                                    <mdb-select-option *ngFor="let option of state; let i = index" [value]="option">{{ option }}</mdb-select-option>
                                </mdb-select-2>
                              </div>

                              <button type="button" mdbBtn class="waves-light button-accept-modals save-button md-form md-outline" (click)="filter()"
                                  mdbWavesEffect>Filtrar
                              </button> 
                                                       
                          </div>
                        </div>  
                    </div> 

                    <!-- Nav Tabs -->
                    <div class="mt-4" *ngIf="stateFilter == true"> 
                      <mdb-tabset #staticTabs [vertical]="true" [buttonClass]="'md-tabs tabs-3 orange darken-3'" [contentClass]="'card'">
                        <!--Panel 1-->
                        <ng-container *ngFor="let item of notes; let i = index">
                          <mdb-tab heading="{{item.nombre_titulo}}" (select)="onTabClick(item)">
                            <div class="row">
                              <div class="col-12">
                                <div class="container">
                                  <form class="text-center forms-text" [formGroup]="objectForm">
                              

                                      <div style="background-color: white;">
                            
                                          <!-- <div class="sub-headers-forms">
                                            <span class="title-forms">General</span>
                                          </div> -->
                            
                                          <div class="px-4">
                                            <div class="row">
                  
                                              <div class="input-time md-form md-outline col-md-6">
                                                  <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                                                    <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'" formControlName="fecha"
                                                      label="Fecha de Ingreso">
                                                    </mdb-date-picker>            
                                                  </div>
                                              </div>   
                       
                                              <div class="md-form md-outline col-md-6">
                                                <input type="number" class="form-control" mdbInput formControlName="nota"
                                                  mdbValidate />
                                                <label for="mineFormId1" class="pl-4">Nota</label>
                                                <!-- <mdb-error *ngIf="id.invalid && (id.dirty || id.touched)">Input Invalido</mdb-error>
                                                <mdb-success *ngIf="id.valid && (id.dirty || id.touched)">Input Valido</mdb-success> -->
                                              </div>
                  
                                              <div class="md-form md-outline mt-2 col-md-12">
                                                  <textarea type="text" id="form7" formControlName="comentario" class="md-textarea md-textarea-auto form-control" mdbInput></textarea>
                                                  <label for="form7" class="pl-4">Comentario</label>
                                              </div>
                                              
                                              <div class="note-button col-md-12">
                                                <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="submit(item)" mdbWavesEffect>Guardar</button> 
                                            </div>  
                                            </div>
                                          </div>  
                                      </div>         
                  
                                </form>
                                </div>
                              </div>
                            </div>
                          </mdb-tab>
                        </ng-container>
                        <!--Panel 2-->
                      </mdb-tabset>
                    </div>
                
                  </div>
              </div>
              
            </div>
            <!-- <div class="modal-footer modals-footer">
                <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
            </div> -->
        </div>
    </div>
</div>


<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
(reponseStateModalAccept)="onResponseNormal($event)"></app-modal-accept>

