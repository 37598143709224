// role.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/backoffice/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const expectedRole = route.data.expectedRole;

    if (this.authService.hasRole(expectedRole)) {
      return true;
    } else {
      // No tiene el rol esperado, redirige a una página de acceso no autorizado o a otra página
      this.router.navigate(['/unauthorized']);
      return false;
    }
  }
}
