
import { Ita2v } from '../../../models/ita2v';

import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { Instrument } from '../../../models/instrument';

import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LinkLosses } from '../../../models/link-losses';
import * as moment from 'moment';
import { Itinfraestructure } from '../../../models/itinfraestructure';
import { Itinfraestructurelosses } from '../../../models/itinfraestructurelosses';
import { Kpi } from '../../../models/kpi';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class KpiService {

  constructor(private http: HttpClient) { }

  getKpiByFilter( kpiForm ): Observable<Kpi[]>{

    console.log('DATE: '+kpiForm.fecha_inicio_date)
    console.log('DATE: '+kpiForm.fecha_inicio_hora)

    let start = kpiForm.fecha_inicio_date.toString()+ "T" +kpiForm.fecha_inicio_hora.toString();
    let end = kpiForm.fecha_termino_date.toString()+ "T" +kpiForm.fecha_termino_hora.toString();
    console.log('START: '+start);
    console.log('END: '+end);

    const body = {
      'id_faena': 16,
      'instrumentos': kpiForm.instrumentos,
      'enlace_id': kpiForm.enlace_id,
      'ponderacion_radar': kpiForm.ponderacion_radar | 0,
      'ponderacion_estacion': kpiForm.ponderacion_estacion | 0,
      'ponderacion_camara': kpiForm.ponderacion_camara | 0,
      'fecha_inicio': start,
      'fecha_termino': end,
    }

    const url = `${ base_url_fast }/instrumentos/kpi`;
    return this.http.post( url, body, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Kpi[] }) => resp.objects )
              );
  }

  
  getInstrumentKpiByMine( id: string, instrumentos: any ): Observable<Kpi[]>{

    const body = {
      "tipos_instrumentos": instrumentos
    };

    const url = `${ base_url_fast }/instrumentos/kpi/faena/${id}`;
    return this.http.post( url, body, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Kpi[] }) => resp.objects )
              );
  }
  // getInfaestructuretiByMine(id: string): Observable<any[]>{

  //   const url = `${ base_url_fast }/faena/infraestructura/${id}`;
  //   return this.http.post( url, null)
  //             .pipe(
  //               map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
  //             );
  // }

}