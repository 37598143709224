
import { IncidentsService } from '../../services/backoffice/incidence/incidents.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalInfo } from 'src/app/models/modal-info';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-back-incidences',
  templateUrl: './back-incidences.component.html',
  styleUrls: ['./back-incidences.component.scss']
})

export class BackIncidencesComponent implements OnInit, OnDestroy {

  timeLine = [];
  originalTimeLine = [];
  dataTable = [];
  headElements = ['N°', 'Nombre', 'Herramientas'];
  headTable = ['nombre', 'tools'];
  colspanLarge: number = 3;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateModalTable: boolean = false;
  stateModalType: boolean = false;
  typeObject: string = 'new';
  dataObject: any;

  minesSubscription: Subscription;
  typesSubscription: Subscription;

  timeLineSelected: string = '';
  objectSelected: any;
  stateModalAlert: boolean = false;


  constructor( private incidentsService: IncidentsService, public authService: AuthService ) { }

  ngOnInit(): void {

    this.getTimeLine();
  }

  ngOnDestroy(){

    this.minesSubscription?.unsubscribe();
    this.typesSubscription?.unsubscribe();
   }
  
   onResponseAlert(event){
    this.stateModalAlert = false;
  }

  onReponseStateModalFormMine(event){
    this.stateModalType = event;
  }
 

  onResponseRefresh(){
    console.log('REFRESH');
    this.getIncidencesByTimeLine(this.timeLineSelected);
  }

  onReponseDeleteTable(event){

    console.log('DELETE RESPONSE');
    this.objectSelected = event;
    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+event.nombre); 
  }

  onReponseDeleteObjectModal(event){

    this.deleteObject(this.objectSelected.id);
  }

  addObject(){
    this.typeObject = 'new';
    this.stateModalType = true;
  }

  filter(){

    this.getIncidencesByTimeLine(this.timeLineSelected);
    // this.resume = this.originalResumen.filter((object) => object.tipo == this.dataFilter);
  }


  changeTimeLine(event: any){

    this.timeLineSelected = event.id;
    console.log('TIMELINE: '+event.nombre);
  }


  onreponseUpdateObjectTable(event: any){

    console.log('EVENT: '+event.id)
    this.typeObject = 'edit';
    this.dataObject = event;
    this.stateModalType = true;
  }

  getTimeLine(){

    this.minesSubscription = this.incidentsService.getTimeLine( ).subscribe({
      next: (resp) => { 
         this.originalTimeLine = resp;
         this.timeLine = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getIncidencesByTimeLine(id: string){

    this.minesSubscription = this.incidentsService.getIncidencesByTimeLine(id).subscribe({
      next: (resp) => { 
         this.dataTable = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  deleteObject(id: string){

    this.incidentsService.deleteIncidence(id).subscribe({
      next: (resp) => { 
         //this.getMines();  
         this.messageAlert(true, 'success', 'La incidencia fue eliminada satisfactoriamente'); 
         this.dataTable = this.dataTable.filter(objeto => objeto.id !== this.objectSelected.id);
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}

