
<div class="padding-view">
    <div class="breadcrumb-style">
      <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link pl-4">
        <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/home">Backoffice</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/backInstruments">Instrumentos</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="breadcrumbs-link">Marca</mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
    <div class="container z-depth-1 p-0 mt-5">
      <div class="header-forms white-text header-div">
        <div></div>
        <h5 class="header-title-forms">
          Marca
        </h5>
        <div class="add-icon">
            <a (click)="addObject()" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])"><mdb-icon fas icon="plus-circle" class="add-button" mdbTooltip="Agregar" placement="top"></mdb-icon></a>
        </div>
    </div>
      <div class="table-body container">
        <app-table-instument-back [data]="brands" [stateModal]="stateModalTable" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
         (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
         (reponseUpdateTable)="onreponseUpdateObjectTable($event)"></app-table-instument-back>
     </div>
    </div>
  </div>
  
<app-brand-back [modalInfo]="modalInfo" [stateModal]="stateModalType" [data]="dataObject" [typeModal]="typeObject" (reponseRefresh)="onResponseRefresh()"
   (reponseStateModalForm) = "onReponseStateModalFormMine($event)"></app-brand-back>


<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert" (reponseDeleteObjectModal) = "onReponseDeleteObjectModal($event)"
		(reponseStateModalAccept) = "onResponseAlert($event)"></app-modal-accept>
