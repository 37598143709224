import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BackofficeService } from 'src/app/services/backoffice/home/backoffice.service';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent implements OnInit, OnDestroy {

  elements = [];
  type: string = 'home';
  backSubscription: Subscription;

  constructor(private router: Router, private backofficeService: BackofficeService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  
    this.spinner.show();
    this.getBackoffice();
  
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }

  goElement( idx: number ){
    this.router.navigate( ['home',idx] );
  }

  ngOnDestroy(){

    this.backSubscription?.unsubscribe();
   }

  getBackoffice(){

    this.backSubscription = this.backofficeService.getBackoffice( this.type ).subscribe({
      next: (resp) => { 
         this.elements = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }
}
