import { MinesService } from '../../services/backoffice/mine/mines/mines.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';

@Component({
  selector: 'app-mines',
  templateUrl: './mines.component.html',
  styleUrls: ['./mines.component.scss']
})
export class MinesComponent implements OnInit {

  mines: any[] = [];

  headElements = ['N°', 'Sigla', 'Nombre Servicio', 'Dirección de Oficinas', 'Herramientas'];
  headTable = ['nombre_corto', 'nombre_largo', 'direccion_oficinas', 'tools'];
  colspanLarge: number = 6;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateModalTable: boolean = false;

  constructor( private minesService: MinesService, private router: Router) { }

  ngOnInit(): void {

    this.getMines();
  }


  companySelected: any;
  onreponsDeleteObjectTable($event){
    console.log('COMPANY1: '+$event.uid);
    this.companySelected = $event;
    this.stateModal = true;  
    this.modalInfo.messageModal = 'Esta seguro de eliminar '+$event.nombre_corto;
    this.modalInfo.type = 'warning'    
  }

  onreponseUpdateObjectTable($event){
    // console.log('COMPANY2: '+$event.uid);
    this.companySelected = $event;
    this.router.navigateByUrl('/emt/sectors/'+ $event.id );

    // localStorage.setItem('mine', $event.id );

    // this.stateCompany = 'update';
    // this.modalAddCompany.show();

  }

  onResponseNormal(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }



  minesSubscription: Subscription;
  getMines(){

    this.minesSubscription = this.minesService.getMines( ).subscribe({
      next: (resp) => { 
         this.mines = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
        this.stateModalTable = true;  
        // this.spinner.hide();
      }
    });
  }



}
