import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';


const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(private http: HttpClient) { }

  getImage(idFaena: string){
    const url = `${ base_url_fast }/faena/images/${idFaena}`;
    return this.http.get( url, httpOptions )
  }

  getLogo(idFaena: string){

    const url = `${ base_url_fast }/faena/logo/${idFaena}`;
    return this.http.get( url, httpOptions )
  }

  updateImage( form: any ): Observable<any[]>{

    const url = `${ base_url_fast }/faena/images/base`;
    return this.http.post( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

}
