import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';
import { UsersService } from 'src/app/services/backoffice/users/users.service';

@Component({
  selector: 'app-navbar-preview',
  templateUrl: './navbar-preview.component.html',
  styleUrls: ['./navbar-preview.component.scss']
})

export class NavbarPreviewComponent implements OnInit {

  userName: string = ''
  role: string = '';

  constructor( private router: Router, private usersService: UsersService, public authService: AuthService ) { }

  ngOnInit(): void {
    this.userName = localStorage.getItem("google");
    this.role = this.usersService.getRole();
    console.log('ROLE: '+ this.role);
  }

  logout(){

    localStorage.removeItem('user');
    localStorage.removeItem('token');

    this.router.navigateByUrl('/login');
  }

}
