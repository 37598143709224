
	<div class="view intro background-login">
	  <div class="full-bg-img rgba-stylish-strong mask flex-center">
	    <div class="container container-card z-depth-5">
	      <div class="row">
			<div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto pt-sm-5 pb-sm-2">
				<div class="d-flex justify-content-center">
					<img src="/assets/emt2.png" width="270px"  heigth="210px" alt="image">
				</div>
			</div>
	        <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto pt-2">

	          <mdb-card bgColor="login-card" class="wow fadeIn z-depth-1" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
	            <mdb-card-body>
					<h2 class="text-center title-login pb-5">Iniciar Sesión</h2>
					<form [formGroup]="loginForm" (ngSubmit)="login()"> 
						<div class="md-form md-outline pb-2 input-login">
						  <mdb-icon fas icon="envelope" class="prefix"></mdb-icon>
						  <input mdbInput mdbValidate formControlName="email" type="email" class="form-control" id="form9">
						  <label for="form9" style="color: black;">Email</label>
						  <mdb-error *ngIf="email.invalid && (email.dirty || email.touched)" style="color: red;">Email ingresado no es valido</mdb-error>
						  <mdb-success *ngIf="email.valid && (email.dirty || email.touched)" style="color: green;">Email ingresado es valido</mdb-success>
						</div>
						<div class="md-form md-outline pb-2 input-login">
						  <mdb-icon fas icon="lock" class="prefix"></mdb-icon>
						  <input mdbInput mdbValidate formControlName="password" type="password" class="form-control" id="form10">
						  <label for="form10" style="color: black;">Contraseña</label>
						  <mdb-error *ngIf="password.invalid && (password.dirty || password.touched)" style="color: red;">Contraseña ingresada no es valida
						  </mdb-error>
						  <mdb-success *ngIf="password.valid && (password.dirty || password.touched)" style="color: green;">Contraseña ingresada es valida
						  </mdb-success>
						</div>
						<div class="pt-3">
							<button class="btn-color" mdbBtn type="submit" block="true" mdbWavesEffect>INGRESAR</button>
							<p class="text-center text-recover pt-3"><a (click)="resendPass()">¿Recuperar Contraseña?</a></p>							
						</div>
					  </form>
					  <div class="gmail-div">
						<button class="gmail-button" mdbBtn type="button" block="false" (click)="onClick()" mdbWavesEffect><mdb-icon fab icon="google"></mdb-icon></button>
					  </div>			  
	            </mdb-card-body>
	          </mdb-card>

	        </div>
	      </div>
	    </div>
	  </div>
	</div>


<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
	(reponseStateModalAccept) = "onResponseNormal($event)"></app-modal-accept>



