import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { trainingFull } from '../../../interfaces/training';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class TrainingsService {

  constructor(private http: HttpClient) { }

  getTrainingsFull(): Observable<trainingFull[]>{
    
    const  body = {
      "niveles": [
        "all"
      ],
      "tipos": [
        "all"
      ]
    }

    const url = `${ base_url_fast }/capacitaciones/tabla/users/`;
    return this.http.post( url, body, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: trainingFull[] }) => resp.objects )
              );
  }
  
  getTrainingState(type, level): Observable<any[]>{
    
    const  body = {
      "niveles": level,
      "tipos": type
    }

    console.log('TYPE: '+type);
    console.log('TYPE: '+level);

    const url = `${ base_url_fast }/capacitaciones/tabla/estados/`;
    return this.http.post( url, body, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  createTypeTraining(form: any) {

    const url = `${ base_url_fast }/capacitaciones/front`;
    const req = this.http.post(url, form, httpOptions);
    return req;
  }

  updateTraining(form: any, id: string): Observable<trainingFull[]>{

    const url = `${ base_url_fast }/sector/${id}`;
    return this.http.put( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: trainingFull[] }) => resp.objects )
              );
  }


  getNotesByUser(id: string): Observable<any[]>{

    const url = `${ base_url_fast }/capacitaciones/notas/${id}`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  createNoteTraining(form: any) {

    console.log('VALUE: '+form.nota);
    const url = `${ base_url_fast }/capacitaciones/registros`;
    const req = this.http.post(url, form, httpOptions);
    return req;
  }

  updateNoteTraining(id: string, form: any): Observable<any[]>{

    const url = `${ base_url_fast }/capacitaciones/registros/${id}`;
    return this.http.put( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }



}
