
import { InstrumentsService } from '../../../services/backoffice/instruments/instruments.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-times',
  templateUrl: './times.component.html',
  styleUrls: ['./times.component.scss']
})
export class TimesComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  timesForm: FormGroup;

  times: any[] = [];
  idInstrument: string;
  instrumentCode: string = "";

  headElements = ['N°', 'Tipo perdida', ' F/H Inicio', 'F/H Termino', 'Duración[H]', 'Observación'];
  headTable = ['tipo_perdida', 'fecha_inicio', 'fecha_termino', 'tiempo_caido', 'observacion'];
  colspanLarge: number = 7;

  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  stateModalTable: boolean = false;

  instrumentSelected: any;

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  typeLosses = [];
  stateLosses = [];

  instrumentSubscription: Subscription;
  lostTimeSubscription: Subscription;
  getLossesSubscription: Subscription;
  getStateSubscription: Subscription;


  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService, private router: Router, private route: ActivatedRoute, public authService: AuthService) { 

      this.idInstrument = this.route.snapshot.paramMap.get('id'); 
      console.log('ID MINE: '+this.idInstrument);
  }

  ngOnInit(): void {

    this.getLostTimesByInstrument(this.idInstrument);
    this.getInstrumentById(this.idInstrument);
    this.getLossesState();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.instrumentSubscription?.unsubscribe();
    this.lostTimeSubscription?.unsubscribe();
    this.getLossesSubscription?.unsubscribe();
    this.getStateSubscription?.unsubscribe();
  }

  initForm(){

    this.timesForm = this.fb.group({
      'fecha_inicio_date': ['', Validators.required],
      'fecha_inicio_hora': ['', Validators.required],
      'fecha_fin_date': [''],
      'fecha_fin_hora': [''],
      'estado_id': ['', Validators.required],
      'perdida_id': ['', Validators.required],
      'observacion': [''],
    });
  }


  onreponsDeleteObjectTable($event){}

  onreponseUpdateObjectTable($event){}

  onreponseTimeObjectTable($event){}

  onreponseChangeObjectTable($event){
    // this.instrumentSelected = $event;
    // this.router.navigateByUrl('/emt/positions/'+ $event.id );
    // localStorage.setItem('instrument', $event.id );
  }

  onResponseNormal(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }


  showAddModal(){

    this.addModal.show();
  }

  goInstruments(){

    this.router.navigateByUrl('/emt/instruments' );
  }

  onSubmitTime(){

    console.log('ON SUBMIT');

    if(this.timesForm.invalid){
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');
      return
    }

    this.createLostTime();
  }

  changedType(event){

    console.log('TYPE ZONE: '+event);
    this.getLossesTypeByState(event);
   }
  
  createLostTime(){

    this.instrumentsService.createLostTime(this.timesForm.value, this.idInstrument).subscribe({
      next: (resp: any) =>{  
        this.timesForm.reset();
        // this.disabledSubmitButton = true;   
        this.addModal.hide();    
        this.getLostTimesByInstrument(this.idInstrument);  
        this.messageAlert(true, 'success', 'Perdida de tiempo creado correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al crear la perdida de tiempo');
    },
    complete: () => {  
        console.log('Completed');
    }
    });
  }
  
  getLossesTypeByState(id: string){
  
    this.getLossesSubscription = this.instrumentsService.getLossesTypeByState(id).subscribe({
      next: (resp) => { 
         this.typeLosses = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getLossesState(){
  
    this.getStateSubscription = this.instrumentsService.getLossesState( ).subscribe({
      next: (resp) => { 
         this.stateLosses = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getLostTimesByInstrument(id: string){

    this.lostTimeSubscription = this.instrumentsService.getLostTimesByInstrument( id ).subscribe({
      next: (resp) => { 
         this.times = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }


getInstrumentById(id: string){

  this.instrumentSubscription = this.instrumentsService.getInstrumentById(id).subscribe({
    next: (resp) => { 
       this.instrumentCode = resp[0].codigo;
      },
    error: (err) => { console.log('Received an error: ' + err.message)},
    complete: () => { 
      console.log('Completed'); 
    } 
  })
}

getFieldControl(fieldName: string) {
  return this.timesForm.get(fieldName);
}

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModal = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
