import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { User } from '../../../models/user';

import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { LoginForm } from '../../../interfaces/login-form';
import { ResendLogin } from '../../../interfaces/resend-form';
import { RecoverLogin } from '../../../interfaces/recover-login';
import { UserFast } from '../../../models/user-fast';


const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  role: string = '';

  constructor(private http: HttpClient) { }

  getUsers(): Observable<User[]>{
    const url = `${ base_url }/users`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: User[] }) => resp.objects )
              );
  }

  getUserById(IdUser: string): Observable<User[]>{
    const url = `${ base_url }/users/${IdUser}`;
    return this.http.get( url )
              .pipe(
                map( (resp: { user: User[] }) => resp.user )
              );
  }

  getUserByEmail(email: string): Observable<any[]> {
    console.log('getUserByEmail: ', email);

    const body = {
      "correo_key": email
    }
    const url = `${base_url_fast}/users/correo/`;
    return this.http.post(url, body, httpOptions)
      .pipe(
        tap((resp: { objects: any[] }) => {
          console.log('Role:', resp.objects[0].nivel_usuario);
          //localStorage.setItem('role', resp.objects[0].role);
          localStorage.setItem('userRoles', JSON.stringify(resp.objects[0].nivel_usuario));
          this.role = resp.objects[0].role;
        }),
        map((resp: { objects: User[] }) => resp.objects)
      );
  }
  
  createUser(form: any) {
    const url = `${ base_url }/users`;
    const req = this.http.post(url, form );
    return req;
  }

  updateUser(user: any, userId: string) {
    const url = `${ base_url }/users/${ userId }`;
    return this.http.put(url, user).pipe(
      map( (resp: { user: User[] }) => resp.user )
    );
};

getUsersFast(): Observable<UserFast[]>{
  const url = `${ base_url_fast }/users`;
  return this.http.get( url, httpOptions )
            .pipe(
              map( (resp: {ok: boolean, objects: UserFast[] }) => resp.objects )
            );
}
  

  login( formData: LoginForm ) {
    
    return this.http.post(`${ base_url }/auth/login`, formData )
                .pipe(
                  tap( (resp: any) => {
                    console.log(resp);
                    console.log(resp.user);
                    this.guardarLocalStorage( resp.token, resp.user );
                  })
                );
  }

  guardarLocalStorage( token: string, user: string ) {

    localStorage.setItem('token', token );
    localStorage.setItem('user', JSON.stringify(user) );

    // localStorage.setItem('menu', JSON.stringify(menu) );

  }

  
  validarToken(): Observable<boolean> {
    const token = localStorage.getItem('token') || '';

    return this.http.get(`${ base_url }/auth/renew`, {
      headers: {
        'x-token': token
      }
    }).pipe(
      tap( (resp: any) => {
        localStorage.setItem('token', resp.token.token );
      }),
      map( resp => true),
      catchError( error => of(false) )
    );

  }


  resend( formData: ResendLogin ) {
    
    return this.http.post(`${ base_url }/recover/resend`, formData )
                .pipe(
                  tap( (resp: any) => {
                    console.log(resp);
                  })
                );
  }

  recover( formData: RecoverLogin, token: string ) {
    
    const body = { 
      "data": formData,
      "token": token
    }
    
    return this.http.post(`${ base_url }/recover`, body )
                .pipe(
                  tap( (resp: any) => {
                    console.log(resp);
                  })
                );
  }

  getRole(): string{
    
    //this.role = this.decryptRole(localStorage.getItem("role"));
    this.role = localStorage.getItem("role");
    console.log('ROLE DES: '+this.role);
    return this.role
  }

  decryptRole(encryptedText: string): string {
    const secretKey = '123qweldm0214lmqwertmbsdl124plt5';
    const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
  


}

