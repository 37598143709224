import { Mine } from 'src/app/models/mine';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/backoffice/users/users.service';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  userName: string = ''
  mine: Mine;
  role: string = '';

  constructor( private router: Router, private usersService: UsersService, public authService: AuthService ) { }

  ngOnInit(): void {

    this. role= this.usersService.getRole();
    console.log('ROLE: '+ this.role);
    this.mine = JSON.parse(localStorage.getItem("mine"));
    this.userName = localStorage.getItem("google");

  }

  logout(){

    localStorage.removeItem('user');
    localStorage.removeItem('token');

    this.router.navigateByUrl('/login');
  }

}


