import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InstrumentsService } from 'src/app/services/backoffice/instruments/instruments.service';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})

export class ResumeComponent implements OnInit, OnDestroy {

  resume = [];
  originalResumen = [];
  types = [];

  headElements = ['N°', 'Tipo', 'Marca', 'Modelo', 'Cantidadd'];
  headTable = ['tipo', 'marca', 'modelo', 'cantidad'];
  colspanLarge: number = 5;

  stateModalTable: boolean = false;

  valueFilter: number;
  dataFilter: string = '';
  stateDownload: boolean = false;

  objectsSubscription: Subscription;
  typesSubscription: Subscription;

  constructor( private instrumentsService: InstrumentsService, private router: Router ) { }

  ngOnInit(): void {

    this.getResume();
    this.getTypes();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
    this.typesSubscription?.unsubscribe();
 }

  OnReponseStateDownload(event){
    this.stateDownload = false;
  }

  download(){
    this.stateDownload = true;
  }

  getSelectedFilter(event:any){
    console.log('SELECTED CAMERA: '+event);
    this.dataFilter = event;
  }

  filter(){

    this.resume = this.originalResumen.filter((object) => object.tipo == this.dataFilter);
  }

  resetFilter(){

    this.resume = this.originalResumen;
  }

  getResume(){

    this.objectsSubscription = this.instrumentsService.getResumeInstruments( ).subscribe({
      next: (resp) => { 
         this.originalResumen = resp;
         this.resume = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getTypes(){

    this.typesSubscription = this.instrumentsService.getTypesInstrument( ).subscribe({
      next: (resp) => { 
         this.types = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }
}

