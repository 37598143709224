import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cardbackoffice',
  templateUrl: './cardbackoffice.component.html',
  styleUrls: ['./cardbackoffice.component.scss']
})
export class CardbackofficeComponent implements OnInit {

  @Input() element: any = {};
  @Input() index: any;

  icon: string = "fa-solid fa-mountain-sun";
  @Output() elementSelect: EventEmitter<number>;


  constructor(private router: Router) {
    this.elementSelect = new EventEmitter();
  }

  ngOnInit() {
  }

  // edit( id: any ) {
  //   // console.log(  this.index );
  //   this.router.navigate( ['/home/project', id] );

  //   // this.heroeSeleccionado.emit( this.index );
  // }

  // activity( proyecto_id: any, nombre_proyecto: any ){

  //   localStorage.setItem('proyecto_id', proyecto_id  );
  //   localStorage.setItem('nombre_proyecto', nombre_proyecto  );
  //   this.router.navigate( ['/home/admin'] );
    
  // }

  // users( proyecto_id: any, nombre_proyecto: any  ){

  //   localStorage.setItem('proyecto_id', proyecto_id  );
  //   localStorage.setItem('nombre_proyecto', nombre_proyecto  );
  //   this.router.navigate( ['/home/assign'] );

  // }

  // seeMine( idx:number ){
  //   this.router.navigate( ['/home'] );
  // }

  home(event){

    console.log('EVENT:'+event);
    this.router.navigateByUrl(event.url);
    localStorage.setItem('mine', JSON.stringify(event));
    
  }

}

