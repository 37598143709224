import { DailyShiftService } from './../../services/daily-shift.service';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-daily-shifts',
  templateUrl: './daily-shifts.component.html',
  styleUrls: ['./daily-shifts.component.scss']
})
export class DailyShiftsComponent implements OnInit, AfterViewInit {

  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, {static: true}) mdbTable: MdbTableDirective;

  elements: any = [];
  headElements = ['id_report', 'shift', 'date', 'handle'];
  searchText: string = '';
  previous: string;

  constructor( private cdRef: ChangeDetectorRef, private dailyShiftService: DailyShiftService ) {}

  @HostListener('input') oninput() {
    this.searchItems();
  }

  ngOnInit() {
    // for (let i = 1; i <= 10; i++) {
    //   this.elements.push({
    //     id:i.toString(),
    //     first: 'Wpis' + (Math.floor(Math.random() * i * 10)).toString(),
    //     last: 'Last' + (Math.floor(Math.random() * i * 10)).toString(),
    //     handle: 'Handle' + (Math.floor(Math.random() * i * 10)).toString()
    //   });
    // }

    // for (let i = 1; i <= 10; i++) {
    //   this.elements.push({
    //     id: i.toString(),
    //     first: 'Wpis ' + i,
    //     last: 'Last ' + i,
    //     handle: 'Handle ' + i
    //   });
    // }



    this.getDailyShift();
   

  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }



  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  dailyShiftsObserver$;
  async getDailyShift() {
    console.log('ENTRO getDailyShift');

    this.dailyShiftsObserver$ = this.dailyShiftService.getDailyShift(  ).subscribe({
      next: (resp: any) =>{  
        this.elements = resp;
        console.log('Response: ' + resp.dailyShift);
    },
    error: (err) => { 
      console.log('Received an error: ' + err)},
    complete: () => { 
        console.log('Completed');
        this.mdbTable.setDataSource(this.elements);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
        this.ngAfterViewInit();
        // this.previousSearch = this.mdbTable.getDataSource(); 
      }
  });
    
  }


  range(id: string){

    console.log('Button Table');

  }
  
  icon(id: string){

  }

}