
import { InstrumentsService } from '../../../services/backoffice/instruments/instruments.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PositionInstrument } from 'src/app/models/position-instrument';
import { SectorsService } from 'src/app/services/backoffice/mine/sectors/sectors.service';
import { MonitoringService } from 'src/app/services/backoffice/mine/monitoring/monitoring.service';
import { ZonesService } from 'src/app/services/backoffice/mine/zones/zones.service';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})

export class PositionsComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  timesForm: FormGroup;
  currentPositionForm: FormGroup;

  lastPositionChange: PositionInstrument;

  idInstrument: string;
  instrumentCode: string = '';
  typeInstrument: string = '';
  idMine: string;

  headElements = ['N°', 'Fecha/Hora del cambio', 'Este', 'Norte', 'Cota'];
  headTable = ['fecha', 'este', 'norte', 'cota'];
  colspanLarge: number = 5;

  paredes = [
    { value: 'N', label: 'N' },
    { value: 'O', label: 'O' },
    { value: 'S', label: 'S' },
    { value: 'E', label: 'E' },
    { value: 'NW', label: 'NW' },
    { value: 'NE', label: 'NE' },
    { value: 'SW', label: 'SW' },
    { value: 'SE', label: 'SE' },
  ];

  modalInfo: ModalInfo = new ModalInfo();
  stateModalAlert: boolean = false;
  stateModalTable: boolean = false;

  types = [
    { value: 'Cambio posicion', label: 'Cambio posición' },
    { value: 'Renovacion', label: 'Renovación' },
  ];

  public myDatePickerOptions: IMyOptions = {
    // Your options
  };

  instrumentSelected: any;

  times = [];
  typeLosses = [];
  sectors = [];
  zones = [];
  areas = [];
  phases= [];

  instrumentsSubscription: Subscription;
  instrumentSubscription: Subscription;
  getLossesSubscription: Subscription;
  sectorSubscription: Subscription;
  zoneSubscription: Subscription;
  areasSubscription: Subscription;

  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService, private sectorsService: SectorsService, private monitoringService: MonitoringService, private zonesService: ZonesService, private router: Router, private route: ActivatedRoute, public authService: AuthService ) { 

      this.idInstrument = this.route.snapshot.paramMap.get('id');
      this.idMine =  JSON.parse(localStorage.getItem("mine")).id;
  }

  ngOnInit(): void {

    this.getChangePositionByInstrument(this.idInstrument);
    this.getInstrumentById(this.idInstrument);
    this.getSectorsByMine(this.idMine);
    this.initForm();
  }

  ngOnDestroy() {

    this.instrumentsSubscription?.unsubscribe();
    this.instrumentSubscription?.unsubscribe();
    this.getLossesSubscription?.unsubscribe();
    this.sectorSubscription?.unsubscribe();
    this.zoneSubscription?.unsubscribe();
    this.areasSubscription?.unsubscribe();
  }

  initForm(){

    this.currentPositionForm = this.fb.group({
      'codigo': [{value: '', disabled: true}],
      'tipo_id': [{value: '', disabled: true}],
      'este': [{value: '', disabled: true}],
      'norte': [{value: '', disabled: true}],
      'cota': [{value: '', disabled: true}],
    });

    this.timesForm = this.fb.group({
      'nombre': [''],
      'fecha_inicio_hora': [''],
      'fecha_inicio_date': [''],
      'fecha_fin_hora': [''],
      'fecha_fin_date': [''],
      'este': ['', Validators.required],
      'norte': ['', Validators.required],
      'cota': ['', Validators.required],
      'sectores': [''],
      'zonas': [''],
      'areas': [''],
      'fases': [''],
      'paredes': [''],
      'banco': [''],
      'azimut': [''],
      'inclinacion': ['']
    });
  }

  patchForm(){

    this.currentPositionForm.patchValue({
      codigo: this.lastPositionChange.codigo,
      tipo_id: this.lastPositionChange.tipo_id,
      este: this.lastPositionChange.este,
      norte: this.lastPositionChange.norte,
      cota: this.lastPositionChange.cota
    });    
  }

  changeTable(){

    if(this.typeInstrument == 'Radar'){

      this.headElements = ['N°', 'Nombre', 'Fecha/Hora Inicio', 'Fecha/Hora Fin',  'Este', 'Norte', 'Cota', 'Sector', 'Zonas', 'Fases', 'Áreas', 'Paredes', 'Banco', 'Inclinación', 'Azimut'];
      this.headTable = ['nombre_proyecto','fecha_inicio', 'fecha_fin', 'este', 'norte', 'cota', 'sectores_nombre', 'zonas_nombre', 'fases_nombre', 'areas_nombre', 'paredes', 'banco', 'inclinacion', 'azimut'];
      this.colspanLarge = 16;
    }
  }

  onreponsDeleteObjectTable($event){

    // this.instrumentSelected = $event;  
    // this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+$event.codigo);  
  }

  onreponseUpdateObjectTable($event){
  }

  onreponseTimeObjectTable($event){
  }

  onreponseChangeObjectTable($event){
  }


  onResponseAlert(event){
    this.stateModalAlert = false;
    console.log('RESPONSE MINE NORMAL');
  }

  showAddModal(){

    this.addModal.show();
  }

  goInstruments(){

    this.router.navigateByUrl('/emt/instruments' );
  }
  
  changedSector(event){
  
    console.log('TYPE SECTOR: '+event);
    this.getZonesBySector(event);
    this.getPhasesBySector(event);
  
    this.timesForm.get('areas').setValue([]);
  }
  
  changedZone(event){
  
    console.log('TYPE ZONE: '+event);
    // this.getMonitoringsByZoneConcat(event);
    this.getAreasByZones(event);
  }
  
  changedPhase(event){
  
    console.log('TYPE ZONE: '+event);
    // this.getMonitoringsByZoneConcat(event);
    // this.getAreasBySector(event);
  }

  onSubmitTime(){

    console.log('ON SUBMIT');

    if(this.timesForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');    
      return
    }
    this.createChangePosition();
  }

  createChangePosition() {
    this.instrumentsService.createChangePosition(this.timesForm.value, this.idInstrument, this.typeInstrument).subscribe({
      next: (resp: any) => {
        this.timesForm.reset();
        this.messageAlert(true, 'success', 'Perdida de tiempo creado correctamente');
        this.getChangePositionByInstrument(this.idInstrument);
        this.getInstrumentById(this.idInstrument);
        this.addModal.hide();
      },
      error: (err) => {
        console.log('Received an error: ' + err);
      },
      complete: () => {
        console.log('Completed');
      },
    });
  }

  getChangePositionByInstrument(id: string) {
    this.instrumentsSubscription = this.instrumentsService.getChangePositionByInstrument(id).subscribe({
      next: (resp) => {
        this.times = resp;
        this.stateModalTable = true;
        console.log('Response: ' + resp);
      },
      error: (err) => {
        console.log('Received an error: ' + err.message);
      },
      complete: () => {
        console.log('Completed');
      },
    });
  }

  getInstrumentById(id: string) {
    this.instrumentSubscription = this.instrumentsService.getInstrumentById(id).subscribe({
      next: (resp) => {
        console.log('TIPO INSTRUMENTO: ' + this.typeInstrument);
        this.instrumentCode = resp[0].codigo;
        this.typeInstrument = resp[0].tipo;
        this.lastPositionChange = resp[0];
        this.patchForm();
        this.changeTable();
      },
      error: (err) => {
        console.log('Received an error: ' + err.message);
      },
      complete: () => {
        console.log('Completed');
      },
    });
  }

    getSectorsByMine( idMine: string ){

  this.instrumentSubscription = this.sectorsService.getSectorsByMine( idMine ).subscribe({
    next: (resp) => { 
       this.sectors = resp;
       console.log('Response: ' + resp); 
      },
    error: (err) => { console.log('Received an error: ' + err.message)},
    complete: () => { 
      console.log('Completed'); 
    }
  });
  }

  getZonesBySector( sectorId: string ){

  this.instrumentSubscription = this.zonesService.getZonesBySector( sectorId ).subscribe({
    next: (resp) => { 
       this.zones = resp;
       console.log('Response: ' + resp); 
      },
    error: (err) => { console.log('Received an error: ' + err.message)},
    complete: () => { 
      console.log('Completed'); 
    }
  });
  }

  getAreasByZones( sectorId: string ){

    this.instrumentSubscription = this.monitoringService.getAreasByZones( sectorId ).subscribe({
      next: (resp) => { 
         this.areas = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getPhasesBySector( sectorId: string ){

    this.instrumentSubscription = this.monitoringService.getPhasesBySector( sectorId ).subscribe({
        next: (resp) => { 
           this.phases = resp;
           console.log('Response: ' + resp); 
          },
        error: (err) => { console.log('Received an error: ' + err.message)},
        complete: () => { 
          console.log('Completed'); 
        }
      });
  }

  getFieldControl(fieldName: string) {
    return this.timesForm.get(fieldName);
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;  
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
