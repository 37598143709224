
<!-- Sidebar navigation -->
    <mdb-side-nav #sidenav class="sn-bg-1 fixed cyan darken-3  white-text" [fixed]="true">
      <mdb-navbar-brand>
        <!-- Logo -->
        <li>
          <div class="logo-wrapper view overlay zoom">
            <a href="/preview"><img src="https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/whiteLogo.png?alt=media&token=d930ad0b-081f-4f48-bc5e-5f1b5cf930a2" class="img-fluid flex-center"></a>
          </div>
        </li>
        <!--/. Logo -->
      </mdb-navbar-brand>
      <links>
        <!--Social-->
        <!-- <li>
          <ul class="social">
            <li><a class="icons-sm fb-ic"><mdb-icon fab icon="facebook"> </mdb-icon></a></li>
            <li><a class="icons-sm pin-ic"><mdb-icon fab icon="pinterest"> </mdb-icon></a></li>
            <li><a class="icons-sm gplus-ic"><mdb-icon fab icon="google-plus"> </mdb-icon></a></li>
            <li><a class="icons-sm tw-ic"><mdb-icon fab icon="twitter"> </mdb-icon></a></li>
          </ul>
        </li> -->
        <!--/Social-->
        <!--Search Form-->
        <!-- <li>
          <form class="search-form" role="search">
            <div class="form-group md-form mt-0 pt-1" mdbWavesEffect>
              <input type="text" class="form-control" placeholder="Search">
            </div>
          </form>
        </li> -->
        <!--/.Search Form-->
        <!-- Side navigation links -->
        <li>
          <ul class="collapsible collapsible-accordion">
            <mdb-accordion [multiple]="false" aria-multiselectable="false">
  
              <!-- Collapsible link -->
              <!-- <mdb-accordion-item>
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="chevron-right"></mdb-icon> Collapsible menu
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <ul>
                    <li><a href="#" mdbWavesEffect>Link 1</a></li>
                    <li><a href="#" mdbWavesEffect>Link 2</a></li>
                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item> -->
  
              <!-- Simple link -->
              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect routerLink="/emt/home"><mdb-icon far icon="hand-pointer"></mdb-icon>
                  Accesos Directos
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="exclamation-triangle"></mdb-icon> Alertas/Alarmas
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>
<!-- 
              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="exclamation-circle"></mdb-icon> Alarmas
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item> -->

              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="calendar-day"></mdb-icon> Eventos
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="user-clock"></mdb-icon> Turnos Diarios
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <a routerLink="/emt/dailyshift" mdbWavesEffect>Crear Turno Diario</a>
                  <a routerLink="/emt/dailyshifts" mdbWavesEffect>Ver Turnos Diarios</a>
                </mdb-accordion-item-body>
              </mdb-accordion-item> -->

              <mdb-accordion-item>
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="user-clock"></mdb-icon> Turnos Diarios
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <ul>
                    <li><a routerLink="/emt/dailyshift" mdbWavesEffect><i class="fas fa-user-plus"></i>Crear Turno Diario</a></li>
                    <li><a routerLink="/emt/dailyshifts" mdbWavesEffect><i class="fas fa-search"></i>Ver Turnos Diarios</a></li>
                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="tasks"></mdb-icon> Check List
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="bomb"></mdb-icon> Tronaduras
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="wave-square"></mdb-icon> Eventos Sismicos
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="bezier-curve"></mdb-icon> Estado del Servicio
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect routerLink="/emt/kpi">
                  <mdb-icon fas icon="globe-americas"></mdb-icon> WebGis
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>
              
              <mdb-accordion-item class="no-collase">
                <mdb-accordion-item-head mdbWavesEffect routerLink="/emt/kpi">
                  <mdb-icon fas icon="globe-americas"></mdb-icon> KPI
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>
              
              <mdb-accordion-item>
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon fas icon="cogs"></mdb-icon>Configuraciones
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <ul>
                    <!-- <li><a routerLink="/emt/home" mdbWavesEffect><mdb-icon fas icon="users"></mdb-icon>Usuarios</a></li> -->
                    <!-- <li><a routerLink="/emt/mines" mdbWavesEffect><mdb-icon fas icon="users-cog"></mdb-icon>Trabajadores EMT</a></li> -->
                    <!-- <li><a routerLink="/emt/mines" mdbWavesEffect><mdb-icon fas icon="mountain"></mdb-icon>Faenas</a></li> -->
                    <!-- <li><a routerLink="/emt/mines" mdbWavesEffect><mdb-icon fas icon="user-tie"></mdb-icon>Cargos PGRG</a></li> -->
                    <!-- <li><a routerLink="/emt/mines" mdbWavesEffect><mdb-icon fas icon="layer-group"></mdb-icon>Tipos de Instrumentos</a></li> -->
                    <li><a routerLink="/emt/instruments" mdbWavesEffect><mdb-icon fas icon="satellite-dish"></mdb-icon>Instrumentos</a></li>
                    <li><a routerLink="/emt/connectionLinks" mdbWavesEffect><mdb-icon fas icon="network-wired"></mdb-icon>Enlaces</a></li>
                    <li><a routerLink="/emt/itinfrastructure" mdbWavesEffect><mdb-icon fas icon="layer-group"></mdb-icon>Infraestructura TI</a></li>
                    <li><a routerLink="/emt/ita2v" mdbWavesEffect><mdb-icon fas icon="globe-asia"></mdb-icon>A2V</a></li>                  
                    <!-- <li><a routerLink="/emt/mines" mdbWavesEffect><mdb-icon fas icon="user-graduate"></mdb-icon>Capacitaciones</a></li> -->
                    <!-- <li><a routerLink="/emt/kpi" mdbWavesEffect><mdb-icon fas icon="bullseye"></mdb-icon>KPI</a></li> -->
                    <!-- <li><a routerLink="/back/home" mdbWavesEffect><mdb-icon fas icon="bullseye"></mdb-icon>Backoffice</a></li> -->
                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
  
              <!-- Collapsible link -->
              <!-- <mdb-accordion-item>
                <mdb-accordion-item-head mdbWavesEffect><mdb-icon far icon="eye"></mdb-icon> Collapsible menu 2
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <ul>
                    <li><a href="#" mdbWavesEffect>Link 1</a></li>
                    <li><a href="#" mdbWavesEffect>Link 2</a></li>
                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item> -->

  
            </mdb-accordion>
          </ul>
        </li>
        <!--/. Side navigation links -->
      </links>
      <div class="sidenav-bg mask-strong"></div>
    </mdb-side-nav>
    <!--/. Sidebar navigation -->
  



<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark cyan darken-3 fixed-top" [containerInside]="false">

  <!-- Navbar brand -->
  <mdb-navbar-brand class="div-brand">
    <a class="navbar-brand" style="display: flex; align-items: center;">
      <img class="p-0 m-0 pl-2" src="/assets/logoWhite.svg" width="110" alt="">
      <h2 class="name-mine">{{ mine.nombre_largo }}</h2>
    </a>
  </mdb-navbar-brand>
  

  <!-- Collapsible content -->
  <links>

    <!-- Links -->
    <!-- <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link waves-light" mdbWavesEffect>Home<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Features</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Pricing</a>
      </li>

      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Basic dropdown<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>
          <div class="divider dropdown-divider"></div>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>
        </div>
      </li>

    </ul> -->
    <!-- Links -->

    <ul class="navbar-nav ml-auto nav-flex-icons">
      <!-- <li class="nav-item">
        <a class="nav-link">
          <mdb-icon fab icon="twitter"></mdb-icon>
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link user-name">
        </a>
      </li>
      <li class="nav-item dropdown btn-group" dropdown>
        <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light text-navbar" mdbWavesEffect>
          {{userName}}
          <!-- <mdb-icon fas icon="user-alt"></mdb-icon> -->
          <i class="fas fa-user-alt"></i>
        </a>
        <div class="dropdown-menu dropdown-primary dropdown-menu-right" role="menu">
          <!-- <a class="dropdown-item" routerLink="/emt/config">Mi Perfil</a> -->
          <a class="dropdown-item" routerLink="/preview">Faenas</a>
          <a class="dropdown-item" *ngIf="authService.hasRole(['admin', 'geotecnico', 'desarrollo', 'supervisor'])" routerLink="/back/home">Backoffice</a>
          <a class="dropdown-item" (click)="logout()">Cerrar sesión</a>
          <!-- <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a> -->
        </div>
      </li>
    </ul>
  </links>
  <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->