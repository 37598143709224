import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackofficeRoutingModule } from './backoffice-routing.module';
import { BackofficeComponent } from './backoffice.component';
import { BackComponent } from './back/back.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule, CharCounterModule, CollapseModule, FileInputModule, IconsModule, InputsModule, MDBBootstrapModulesPro, MdbTimePickerModule, TableModule, WavesModule } from 'ng-uikit-pro-standard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ComponentsModule } from '../components/components.module';
import { BackIncidencesComponent } from './back-incidences/back-incidences.component';
import { BackMinesComponent } from './back-mines/back-mines.component';
import { BackMineComponent } from './back-mine/back-mine.component';
import { BackUsersComponent } from './back-users/back-users.component';
import { BrandComponent } from './back-instruments/brand/brand.component';
import { ModelComponent } from './back-instruments/model/model.component';
import { TypeComponent } from './back-instruments/type/type.component';
import { ResumeComponent } from './back-instruments/resume/resume.component';
import { HomeComponent } from './back-instruments/home/home.component';
import { TrainingsComponent } from './back-trainings/trainings/trainings.component';


@NgModule({
  declarations: [
    BackofficeComponent,
    BackComponent,
    BackIncidencesComponent,
    BackMinesComponent,
    BackMineComponent,
    BackUsersComponent,
    BrandComponent,
    ModelComponent,
    TypeComponent,
    ResumeComponent,
    HomeComponent,
    TrainingsComponent,
  ],
  imports: [
    CommonModule,
    BackofficeRoutingModule,
    SharedModule, 
    ReactiveFormsModule,
    MdbTimePickerModule,
    WavesModule,
    TableModule,
    InputsModule,
    IconsModule,
    ComponentsModule,
    InputsModule, WavesModule, ButtonsModule, CharCounterModule, CollapseModule,
    FormsModule,
    NgxSpinnerModule,
    FileInputModule,
    MDBBootstrapModulesPro.forRoot()
  ]
})
export class BackofficeModule { }
