<div class="card card-circle z-depth-5 animated fadeIn fast">

    <div class="card-body text-center" style="padding-top: 40px;">
        <p class="icon-back"><i class="{{ element.icon }}"></i></p>
        <p class="card-title title-word"> {{ element.name }} </p>
        <!-- <p class="card-text word"> {{ element.ubicacion }} </p> -->

        <button class="btn-color" mdbBtn type="button" rounded="false" block="false" mdbWavesEffect (click)="home(element)">INGRESAR</button>


    </div>
</div>