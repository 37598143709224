import { DailyShiftService } from './../../services/daily-shift.service';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IMyOptions, MDBDatePickerComponent, LocaleService } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';


@Component({
  selector: 'app-daily-shift',
  templateUrl: './daily-shift.component.html',
  styleUrls: ['./daily-shift.component.scss']
})
export class DailyShiftComponent implements OnInit {

  @ViewChild('datePicker', { static: true }) datePicker!: MDBDatePickerComponent;

  
  modalInfo: ModalInfo = new ModalInfo();
  stateModal: boolean = false;
  
  contactForm: FormGroup;
  disabledSubmitButton: boolean = true;

  @HostListener('input') oninput() {
    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    }
  }

  optionsSelect = [
    { value: 'turno1', label: '6:00 AM - 18:00 PM' },
    { value: 'turno2', label: '18:00 PM - 6:00 AM' },
  ];


  myDatePickerOptions: IMyOptions = {};

  locales = {
    'es': {
      dayLabels: { su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sab' },
      dayLabelsFull: { su: 'Domingo', mo: 'Lunes', tu: 'Martes', we: 'Miercoles', th: 'Jueves', fr: 'Viernes', sa: 'Sabado' },
      monthLabels: {
        1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun',
        7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic'
      },
      monthLabelsFull: {
        1: 'Enero', 2: 'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio', 7: 'Julio',
        8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre'
      },
      dateFormat: 'dd mmmm yyyy',
      todayBtnTxt: 'Hoy',
      clearBtnTxt: 'Limpiar',
      closeBtnTxt: 'Cerrar',
      firstDayOfWeek: 'mo',
      sunHighlight: true,
    }
  };

  constructor( private fb: FormBuilder, private dailyShiftService: DailyShiftService, private localeService: LocaleService ) { 

    this.localeService.setLocaleOptions(this.locales);

    this.contactForm = fb.group({
      'id_report': [12, Validators.required],
      'date': [ new Date(), Validators.compose([Validators.required])],
      'shift': ['', Validators.required],
      'failure_event': ['', Validators.required],
      'special_works': ['', Validators.required],
      'other_themes': ['', Validators.required],
      'mineNetworkLink': [true, Validators.required],
      'vpnLink': [ true, Validators.required],
      'vpn': [true, Validators.required],
      'emt': [true, Validators.required],

      // 'contactFormCopy': ['', Validators.requiredTrue],
    });
  }

  ngOnInit(): void {

    this.getDailyShift();
  }

  get id() {
    return this.contactForm.get('id_report');
  }

  get email() {
    return this.contactForm.get('date');
  }

  get subjects() {
    return this.contactForm.get('shift');
  }

  get message() {
    return this.contactForm.get('failure_event');
  }

  // get copy() {
  //   return this.contactForm.get('contactFormCopy');
  // }

  onSubmit() {
    this.dailyShiftService.createDailyShift(this.contactForm.value).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp.dailyShift);
        this.contactForm.reset();
        this.disabledSubmitButton = true;   
        
        this.stateModal = true;  
        this.modalInfo.messageModal = resp.msg;
        this.modalInfo.type = 'success'        
    },
    error: (err) => { console.log('Received an error: ' + err)},
    complete: () => {  
        console.log('Completed');
  }
  });
  }


  dailyShiftObserver;
  async getDailyShift() {
    console.log('ENTRO getDailyShift');

    this.dailyShiftObserver = this.dailyShiftService.getDailyShift(  ).subscribe({
      next: (resp: any) =>{  
        console.log('Response: ' + resp.dailyShift);
    },
    error: (err) => { 
      console.log('Received an error: ' + err)},
    complete: () => { 
        console.log('Completed'); }
  });
    
  }

  onResponseNormal(event){
    this.stateModal = false;
    console.log('RESPONSE MINE NORMAL');
  }

}
