<div class="container-fluid mt-3 px-0">
  <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link mt-3 p-0 mb-0 pb-0">
    <mdb-breadcrumb-item (click)="goInstruments()">Instrumentos</mdb-breadcrumb-item>
    <!-- <mdb-breadcrumb-item class="active">Sectores</mdb-breadcrumb-item> -->
  </mdb-breadcrumb>
  <div class="z-depth-5 mb-5">
    <div class="header-forms white-text text-center mb-5">
      <h1 class="header-title-forms" *ngIf="idInstrument == 'new'">Crear Instrumento</h1>
      <h1 class="header-title-forms" *ngIf="idInstrument != 'new'">Editar Instrumento</h1>
    </div>
    <div class="body-forms">
      <form class="text-center" [formGroup]="instrumentForm">

          <div class="mt-5" style="background-color: white;">

              <div class="sub-headers-forms mb-3">
                <h2 class="title-forms">General</h2>
              </div>

              <div class="row px-5">
                  <div class="md-form md-outline mt-3 col-md-9">
                    <input type="text" formControlName="codigo" id="materialcontactFormId" class="form-control" mdbInput
                      mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Nombre (*)</label>
                    <mdb-error *ngIf="getFieldControl('codigo')?.invalid && (getFieldControl('codigo')?.dirty || getFieldControl('codigo')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('codigo')?.valid && (getFieldControl('codigo')?.dirty || getFieldControl('codigo')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="md-form md-outline mt-3 col-md-3">
                    <mdb-select-2 [outline]="true" placeholder="Seleccionar Tipo" label="Tipo (*)" [formControlName]="'tipo_id'">
                        <mdb-select-option *ngFor="let option of typesInstrument" [value]="option.id" (click)="changedType(option)">{{ option.nombre }}</mdb-select-option>
                    </mdb-select-2>
                  </div>

                  <div class="md-form md-outline mt-3 col-md-3">
                    <mdb-select-2 [outline]="true" placeholder="Seleccionar Marca" label="Marca (*)" [formControlName]="'marca_id'" (ngModelChange)="changedBrand($event)">
                        <mdb-select-option *ngFor="let option of brands" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                    </mdb-select-2>
                  </div>

                  <div class="md-form md-outline mt-3 col-md-3">
                    <mdb-select-2 [outline]="true" placeholder="Seleccionar Modelo" label="Modelo (*)" [formControlName]="'modelo_id'">
                        <mdb-select-option *ngFor="let option of models" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
 
                  <div class="md-form md-outline mt-3 col-md-2">
                    <input type="text" formControlName="este" id="materialcontactFormId" class="form-control" mdbInput
                      mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Este (*)</label>
                    <mdb-error *ngIf="getFieldControl('este')?.invalid && (getFieldControl('este')?.dirty || getFieldControl('este')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('este')?.valid && (getFieldControl('este')?.dirty || getFieldControl('este')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="md-form md-outline mt-3 col-md-2">
                    <input type="text" formControlName="norte" id="materialcontactFormId" class="form-control" mdbInput
                      mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Norte (*)</label>
                    <mdb-error *ngIf="getFieldControl('norte')?.invalid && (getFieldControl('norte')?.dirty || getFieldControl('norte')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('norte')?.valid && (getFieldControl('norte')?.dirty || getFieldControl('norte')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="md-form md-outline mt-3 col-md-2">
                    <input type="text" formControlName="cota" id="materialcontactFormId" class="form-control" mdbInput
                      mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Cota (*)</label>
                    <mdb-error *ngIf="getFieldControl('cota')?.invalid && (getFieldControl('cota')?.dirty || getFieldControl('cota')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('cota')?.valid && (getFieldControl('cota')?.dirty || getFieldControl('cota')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                </div>
          </div>

          <div class="mt-5"  *ngIf="typeRadar" style="background-color: white;">

                <div class="sub-headers-forms mb-3">
                  <h2 class="title-forms">Datos del proyecto</h2>
                </div>

                <div class="row px-5">
                    <div class="md-form md-outline mt-3- col-md-4">
                      <input type="text" formControlName="nombre" id="materialcontactFormId" class="form-control" mdbInput
                        mdbValidate />
                      <label for="materialcontactFormId" class="pl-4">Nombre proyecto (*)</label>

                      <mdb-error *ngIf="getFieldControl('nombre')?.invalid && (getFieldControl('nombre')?.dirty || getFieldControl('nombre')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                      <mdb-success *ngIf="getFieldControl('nombre')?.valid && (getFieldControl('nombre')?.dirty || getFieldControl('nombre')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                    </div>

                    <div class="input-time md-form md-outline mt-3 col-md-2">
                      <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                        <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                          label="Fecha de Inicio (*)"  formControlName="fecha_inicio_date">
                        </mdb-date-picker>            
                      </div>
                    </div>

                    <div class="md-form  md-form md-outline mt-3 col-md-2">
                      <input
                        type="time"
                        id="input"
                        name="appt-time"
                        class="form-control"
                        formControlName="fecha_inicio_hora"
                        value="00:00"
                        mdbInput
                      />
                      <label for="input" class="pt-1 pr-3">Hora de Inicio (*)</label>
                    </div>

                    <div class="input-time md-form md-outline mt-3 col-md-2">
                      <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                        <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'.'"
                          label="Fecha de Termino (*)"  formControlName="fecha_fin_date">
                        </mdb-date-picker>            
                      </div>
                    </div>

                    <div class="md-form  md-form md-outline mt-3 col-md-2">
                      <input
                        type="time"
                        id="input"
                        name="appt-time"
                        class="form-control"
                        formControlName="fecha_fin_hora"
                        value="00:00"
                        mdbInput
                      />
                      <label for="input">Hora de Termino (*)</label>
                    </div>

                    <div class="md-form md-outline mt-3 col-md-4">
                      <mdb-select-2 [outline]="true" placeholder="Seleccionar Sector" label="Sector (*)" [formControlName]="'sectores'" (ngModelChange)="changedSector($event)">
                          <mdb-select-option *ngFor="let option of sectors" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="md-form md-outline mt-3 col-md-4">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Zonas" label="Zonas (*)" [formControlName]="'zonas'" (ngModelChange)="changedZone($event)">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of zones" [value]="option.id" >{{ option.nombre }} </mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="md-form md-outline mt-3 col-md-4">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Fases" label="Fases (*)" [formControlName]="'fases'" (ngModelChange)="changedPhase($event)">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of phases" [value]="option.id" >{{ option.fase }} </mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="md-form md-outline mt-3 col-md-4">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Paredes" label="Paredes (*)" [formControlName]="'paredes'" (ngModelChange)="changedZone($event)">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of paredes" [value]="option.value" >{{ option.value }}</mdb-select-option>
                      </mdb-select-2>
                    </div>

                    <div class="md-form md-outline mt-3 col-md-4">
                      <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Areas" label="Áreas (*)" [formControlName]="'areas'">
                        <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                        <mdb-select-option *ngFor="let option of areas" [value]="option.id" >{{ option.nombre }}</mdb-select-option>
                      </mdb-select-2>
                  </div>

                    <div class="md-form md-outline mt-3 col-md-4">
                      <input type="number" formControlName="banco" id="materialcontactFormId" class="form-control" mdbInput
                        mdbValidate />
                      <label for="materialcontactFormId" class="pl-4">Banco</label>
                      <mdb-error *ngIf="getFieldControl('banco')?.invalid && (getFieldControl('banco')?.dirty || getFieldControl('banco')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                      <mdb-success *ngIf="getFieldControl('banco')?.valid && (getFieldControl('banco')?.dirty || getFieldControl('banco')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                    </div>

                    <div class="md-form md-outline mt-3 col-md-4">
                      <input type="number" formControlName="azimut" id="materialcontactFormId" class="form-control"
                        mdbInput mdbValidate />
                      <label for="materialcontactFormId" class="pl-4">Azimut [°]</label>
                      <mdb-error *ngIf="getFieldControl('azimut')?.invalid && (getFieldControl('azimut')?.dirty || getFieldControl('azimut')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                      <mdb-success *ngIf="getFieldControl('azimut')?.valid && (getFieldControl('azimut')?.dirty || getFieldControl('azimut')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                    </div>

                    <div class="md-form md-outline mt-3 col-md-4">
                      <input type="number" formControlName="inclinacion" id="materialcontactFormId" class="form-control"
                        mdbInput mdbValidate />
                      <label for="materialcontactFormId" class="pl-4">Inclinación [°]</label>
                      <mdb-error *ngIf="getFieldControl('inclinacion')?.invalid && (getFieldControl('inclinacion')?.dirty || getFieldControl('inclinacion')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                      <mdb-success *ngIf="getFieldControl('inclinacion')?.valid && (getFieldControl('inclinacion')?.dirty || getFieldControl('inclinacion')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                    </div>

                </div>

          </div>
          <div class="footer-button py-4 pr-3">
            <button type="button" mdbBtn class="waves-light cancel-button" (click)="goInstruments()" mdbWavesEffect>Cancelar</button>
            <!-- <button type="submit" mdbBtn class="waves-light save-button" mdbWavesEffect>Guardar</button> -->
            <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmit()"  mdbWavesEffect>Guardar</button>
          </div>
    
      </form>
    </div>
  </div>

</div>


<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal"
    (reponseStateModalAccept)="onResponseNormal($event)"></app-modal-accept>
