import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, Input, SimpleChanges, Output, EventEmitter,} from "@angular/core";
import { MdbTableDirective, MdbTablePaginationComponent, ModalDirective } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
import { AuthService } from "src/app/services/backoffice/auth/auth.service";


@Component({
  selector: 'app-table-incidence-back',
  templateUrl: './table-incidence-back.component.html',
  styleUrls: ['./table-incidence-back.component.scss']
})
export class TableIncidenceBackComponent implements OnInit {

  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, {static: true}) mdbTable: MdbTableDirective;

  
  @Input() data: any;
  @Input() stateModal: boolean;
  // @Input() refreshTableZone: boolean;

  @Input() headElements: string[];
  @Input() headTable: string[];
  @Input() colspanLarge: number;

  @Output() reponseStateModal = new EventEmitter();
  @Output() reponseDeleteTable = new EventEmitter();
  @Output() reponseUpdateTable = new EventEmitter();

  elements: any = [];
  // headElements = ['id_report', 'shift', 'date', 'handle'];
  searchText: string = '';
  previous: string;
  
  @HostListener('input') oninput() {
    this.searchItems();
  }
  
  constructor(private cdRef: ChangeDetectorRef, private router: Router, public authService: AuthService) {}

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);
    // this.elements = this.data;
    // this.mdbTable.setDataSource(this.elements);
    // this.elements = this.mdbTable.getDataSource();
    // this.previous = this.mdbTable.getDataSource();
    // this.ngAfterViewInit();

    if (this.stateModal == true) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");

      this.elements = this.data;
      this.mdbTable.setDataSource(this.elements);
      this.elements = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
      this.ngAfterViewInit();

      setTimeout(() => {
        this.reponseStateModal.emit(false);
      }, 500);
    }
  }

    // if (this.refreshTableZone == true) {
    
    //   this.elements = this.dataZoneExclusion;
    //   this.mdbTable.setDataSource(this.elements);
    //   this.elements = this.mdbTable.getDataSource();
    //   this.previous = this.mdbTable.getDataSource();
    //   this.ngAfterViewInit();

    //   setTimeout(() => {
    //     this.reponseStateModal.emit(false);
    //   }, 500);
    // }
  


  ngOnInit() {

      this.elements = this.data;
      this.mdbTable.setDataSource(this.elements);
      this.elements = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
      // this.ngAfterViewInit();
    // this.elements = this.dataZoneExclusion;

    // for (let i = 1; i <= 15; i++) {
    //   this.elements.push({
    //     id: i.toString(),
    //     first: "User " + i,
    //     last: "Name " + i,
    //     handle: "Handle " + i,
    //   });
    // }

    // console.log('ELEMENTS: '+this.elements);
    // this.mdbTable.setDataSource(this.elements);
    // this.elements = this.mdbTable.getDataSource();
    // this.previous = this.mdbTable.getDataSource();

  }

  // ngAfterViewInit() {
  //   this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);
  
  //   this.mdbTablePagination.calculateFirstItemIndex();
  //   this.mdbTablePagination.calculateLastItemIndex();
  //   this.cdRef.detectChanges();
  // }

  searchItems() {

    const prev = this.mdbTable.getDataSource();
    
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }



  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(50);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  deleteObject( object: any ){
    
    this.reponseDeleteTable.emit(object);
  }

  updateObject(object: any){

    this.reponseUpdateTable.emit(object);
  }

}

