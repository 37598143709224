import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidenavModule, WavesModule, AccordionModule } from 'ng-uikit-pro-standard';
import { NavbarPreviewComponent } from './navbar-preview/navbar-preview.component'
import { RouterModule } from '@angular/router';
import { NavbarBackofficeComponent } from './navbar-backoffice/navbar-backoffice.component';



@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    NavbarPreviewComponent,
    NavbarBackofficeComponent
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    NavbarPreviewComponent,
    NavbarBackofficeComponent
  ],
  imports: [
    CommonModule,
    SidenavModule,
    WavesModule,
    AccordionModule,
    RouterModule,
    MDBBootstrapModulesPro.forRoot()
  ]
})
export class SharedModule { }
