
<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">{{ titleModal }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body">
              <div class="container mt-4 px-4">
                <form class="text-center forms-text" [formGroup]="objectForm">
                  <div class="mx-auto px-4" style="background-color: white;">
                    <div class="row">                           
                    
                      <!-- <div class="md-form md-outline mt-5 col-md-6">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar Sector" label="Sector" [formControlName]="'servicio_id'">
                            <mdb-select-option *ngFor="let option of servicesMine" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div> -->

                      <!-- <div class="md-form md-outline mt-5 col-sm-6">
                        <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar servicio" label="Servicios" [formControlName]="'servicio_id'">
                          <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                          <mdb-select-option *ngFor="let option of servicesMine" [value]="option.id" >{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div> -->

                      <div class="md-form md-outline mt-5 col-md-6">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar servicio" label="Servicios (*)" [formControlName]="'servicio_id'">
                            <mdb-select-option *ngFor="let option of servicesMine" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div>

                      <div class="md-form md-outline mt-5 col-sm-6">
                        <input type="text" formControlName="nombre" id="sectorFormId1" class="form-control" mdbInput
                          mdbValidate />
                        <label for="sectorFormId1" class="pl-4">Nombre del sector (*)</label>
                        <mdb-error *ngIf="getFieldControl('nombre')?.invalid && (getFieldControl('nombre')?.dirty || getFieldControl('nombre')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                        <mdb-success *ngIf="getFieldControl('nombre')?.valid && (getFieldControl('nombre')?.dirty || getFieldControl('nombre')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                      </div>

                      <div class="md-form md-outline mt-5 col-md-6">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar" label="Unidad de distancia (*)" [formControlName]="'unidad_distancia'">
                            <mdb-select-option *ngFor="let option of udistance" [value]="option.nombre">{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div>

                      <div class="md-form md-outline mt-5 col-md-6">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar" label="Unidad de tiempo (*)" [formControlName]="'unidad_tiempo'">
                            <mdb-select-option *ngFor="let option of utime" [value]="option.nombre">{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div>

                      <!-- <div class="md-form md-outline mt-5 col-sm-6">
                        <input type="text" formControlName="unidad_distancia" id="sectorFormId2" class="form-control" mdbInput
                          mdbValidate />
                        <label for="sectorFormId2" class="pl-4">Unidad de distancia</label>
                      </div>

                      <div class="md-form md-outline mt-5 col-sm-6">
                        <input type="text" formControlName="unidad_tiempo" id="sectorFormId3" class="form-control" mdbInput
                          mdbValidate />
                        <label for="sectorFormId3" class="pl-4">Unidad de tiempo</label>
                      </div> -->
                      
                      <!-- <div class="md-form md-outline mt-5 col-sm-6">
                        <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar servicio" label="Tipo servicio" [formControlName]="'tipo'">
                          <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                          <mdb-select-option *ngFor="let option of types" [value]="option.nombre" >{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div> -->

                      <div class="md-form md-outline mt-5 col-md-6">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar servicio" label="Tipo servicio (*)" [formControlName]="'tipo'">
                            <mdb-select-option *ngFor="let option of types" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div>

                      <!-- <div class="md-form md-outline mt-5 col-3">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar Marca" label="Marca" [formControlName]="'marca_id'" (ngModelChange)="changedBrand($event)">
                            <mdb-select-option *ngFor="let option of brands" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div> -->
  
                    </div>
                  </div>         
              </form>
              </div>
              
            </div>
            <div class="modal-footer modals-footer">
                <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
                <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitObject()" mdbWavesEffect>Guardar</button>
            </div>
        </div>
    </div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
(reponseStateModalAccept)="onResponseAlert($event)"></app-modal-accept>

