import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import { ModalInfo } from 'src/app/models/modal-info';

@Component({
  selector: 'app-phase-back',
  templateUrl: './phase-back.component.html',
  styleUrls: ['./phase-back.component.scss']
})
export class PhaseBackComponent implements OnInit, OnDestroy {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();
  @Output() reponseObject = new EventEmitter();
  @Output() responseAddObject = new EventEmitter();

  modalInfo: ModalInfo = new ModalInfo();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  titleModal: string = 'Agregar Fase'

  idObject: string = '';
  servicesMine = [];
  sectorsMine = [];
  areas = [];

  objectsSubscription: Subscription;
  objectsAreasSubscription: Subscription;

  types = [{"id": 1, "nombre":"Vigilancia"}, {"id": 2, "nombre":"Monitoreo"}]

  constructor( private fb: FormBuilder, private minesbackService: MinesbackService, private router: Router, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id');
   }

  ngOnInit(): void {
      
    this.initForm();
    this.getSectorsByMine(this.idMine);
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
    this.objectsSubscription?.unsubscribe();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'sector_faena_id': ['', Validators.required],
      'fase': ['', Validators.required],
      'paredes': [[0]],
      'areas': [''],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'sector_faena_id': this.data.sector_faena_id,
      'fase': this.data.fase,
      'areas': this.data.areas_id,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");

      this.addModal.show();
      this.getSectorsByMine(this.idMine);
      this.reponseStateModalForm.emit(false);
    }


    if(this.objectForm){
      if(this.typeModal == 'edit'){

        this.titleModal = 'Editar Fase';
        this.getAreasBySector(this.data.sector_faena_id);
        this.idObject = this.data.id;
        this.patchForm();
      }else{
        this.titleModal = 'Agregar Fase'
        this.objectForm.reset();
      }
    }
  }

  changedType(event){

    if(event)this.getAreasBySector(event);
   }


  showAddModal(){

    this.addModal.show();
  }

  onResponseAlert(event){

    this.stateModalAlert = event;
  }

  onSubmitObject(){

    console.log('ON SUBMIT');
    if(this.objectForm.invalid){
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');
      return
    }

    this.typeModal == 'new' ? this.createPhase() : this.updatePhase();
  }


  createPhase(){

    this.objectForm.get('paredes').setValue([]);

    this.minesbackService.createPhase(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
        this.responseAddObject.emit(resp);
        this.reponseRefresh.emit(false);  
        this.objectForm.reset();
        this.addModal.hide();  
        this.messageAlert(true, 'success', 'Fase creada correctamente');  
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar fase');
    },
      complete: () => {  
          console.log('Completed');
      }
    });
  }

  updatePhase(){

    this.minesbackService.updatePhase(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{  
        const formValue = { ...this.objectForm.value };
        formValue['id'] = this.idObject;
        this.reponseObject.emit(formValue);
        this.reponseRefresh.emit(false);  
        this.objectForm.reset();
        this.addModal.hide();
        this.messageAlert(true, 'success', 'Fase actualizada correctamente'); 
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar fase');    
    },
    complete: () => {  
        console.log('Completed');
    }
    });
  }
     
  getSectorsByMine(id: string){
  
    this.objectsSubscription = this.minesbackService.getSectorsByMine(id).subscribe({
      next: (resp) => { 
         this.sectorsMine = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getAreasBySector(id: string){
  
    this.objectsAreasSubscription = this.minesbackService.getAreasBySector(id).subscribe({
      next: (resp) => { 
         this.areas = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
