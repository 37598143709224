import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import { InstrumentsService } from 'src/app/services/backoffice/instruments/instruments.service';

@Component({
  selector: 'app-type-back',
  templateUrl: './type-back.component.html',
  styleUrls: ['./type-back.component.scss']
})
export class TypeBackComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() modalInfo: any;
  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  titleModal: string = 'Agregar Tipo'

  idObject: string = '';
  // servicesMine = [];
  // types = [{"id": 1, "nombre":"Vigilancia"}, {"id": 2, "nombre":"Monitoreo"}]

  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService, private router: Router, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id');
   }

  ngOnInit(): void {

    this.initForm();
  }

  initForm(){

    this.objectForm = this.fb.group({
      //variables': ['', Validators.required],
      'nombre': ['', Validators.required],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'nombre': this.data.nombre,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");

      this.addModal.show();
      this.reponseStateModalForm.emit(false);

      if(this.objectForm){
        if(this.typeModal == 'edit'){
  
          console.log('EDIT PHASE: '+this.data.areas);
          this.titleModal = 'Editar Tipo';
          this.idObject = this.data.id;
          this.patchForm();
        }else{
          this.objectForm.reset();
          this.titleModal = 'Agregar Tipo';
        }
      }
    }
  }

   showAddModal(){

    this.addModal.show();
  }

  onResponseAlert(event){

    this.stateModalAlert = event;
  }


  onSubmitObject(){

    console.log('ON SUBMIT');

    if(this.objectForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');      
      return
    }

    this.typeModal == 'new' ? this.createType() : this.updateType();
  }


  createType(){

    this.instrumentsService.createTypesInstrument(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
        this.reponseRefresh.emit(false);
        this.addModal.hide();
        this.objectForm.reset(); 
        this.messageAlert(true, 'success', 'Tipo creado correctamente');      
    },
    error: (err) => {
       console.log('Received an error: ' + err);
       this.messageAlert(true, 'error', 'Error al actualizar tipo');
      },
    complete: () => {  
        console.log('Completed');
    }
    });
  }

  updateType(){

    //this.objectForm.get('variables').setValue(["1"]);
    this.instrumentsService.updateTypesInstrument(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{  
      this.reponseRefresh.emit(false);  
      this.addModal.hide();
      this.objectForm.reset();
      this.messageAlert(true, 'success', 'Tipo actualizado correctamente');      
    },
    error: (err) => {
       console.log('Received an error: ' + err);
       this.messageAlert(true, 'error', 'Error al actualizar tipo');
      },
    complete: () => {  
        console.log('Completed');

    }
    });
  }
     
  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
