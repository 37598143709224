
<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark cyan darken-3 fixed-top" [containerInside]="false">

  <!-- Navbar brand -->
  <mdb-navbar-brand><a class="navbar-brand">
    <!-- <img class="p-0 m-0 pl-2" src="/assets/logoNavbarWhite3.svg" width="135" alt=""> -->
    <img class="p-0 m-0 pl-2" src="/assets/logoWhite.svg" width="110" alt="">
 </a></mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>

    <!-- Links -->
    <!-- <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link waves-light" mdbWavesEffect>Home<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Features</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect>Pricing</a>
      </li>

      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Basic dropdown<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Another action</a>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Something else here</a>
          <div class="divider dropdown-divider"></div>
          <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>
        </div>
      </li>

    </ul> -->
    <!-- Links -->

    <ul class="navbar-nav ml-auto nav-flex-icons">
      <!-- <li class="nav-item">
        <a class="nav-link">
          <mdb-icon fab icon="twitter"></mdb-icon>
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link user-name">
        </a>
      </li>
      <li class="nav-item dropdown btn-group" dropdown>
        <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light text-navbar" mdbWavesEffect>
          {{userName}}
          <!-- <mdb-icon fas icon="user-alt"></mdb-icon> -->
          <i class="fas fa-user-alt"></i>
        </a>
        <div class="dropdown-menu dropdown-primary dropdown-menu-right" role="menu">
          <!-- <a class="dropdown-item" routerLink="/emt/config">Mi Perfil</a> -->
          <a class="dropdown-item" routerLink="/preview">Faenas</a>
          <a class="dropdown-item" *ngIf="authService.hasRole(['admin', 'geotecnico', 'desarrollo', 'supervisor'])" routerLink="/back/home">Backoffice</a>
          <a class="dropdown-item" (click)="logout()">Cerrar sesión</a>
          <!-- <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a> -->
        </div>
      </li>
    </ul>
  </links>
  <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->