import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalAcceptComponent } from './modal-accept/modal-accept.component';

import { CUSTOM_ELEMENTS_SCHEMA,  NO_ERRORS_SCHEMA } from '@angular/core';


import {MDBSpinningPreloader, MDBBootstrapModulesPro, CharCounterModule, TableModule, CollapseModule, TooltipModule} from 'ng-uikit-pro-standard';
import {AppComponent} from 'src/app/app.component';

import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { InputsModule, ModalModule, WavesModule, ButtonsModule, IconsModule } from 'ng-uikit-pro-standard';
import { TableComponent } from './table/table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableInstrumentsComponent } from './table-instruments/table-instruments.component';
import { TableTimesComponent } from './table-times/table-times.component';
import { CardbackofficeComponent } from './cardbackoffice/cardbackoffice.component';
import { ServiceBackComponent } from './mines/service-back/service-back.component';
import { SectorBackComponent } from './mines/sector-back/sector-back.component';
import { ZoneBackComponent } from './mines/zone-back/zone-back.component';
import { AreaBackComponent } from './mines/area-back/area-back.component';
import { PhaseBackComponent } from './mines/phase-back/phase-back.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './map/map.component';
import { MineBackComponent } from './mines/mine-back/mine-back.component';
import { ImageBackComponent } from './mines/image-back/image-back.component';
import { TypeBackComponent } from './instruments/type-back/type-back.component';
import { BrandBackComponent } from './instruments/brand-back/brand-back.component';
import { ModelBackComponent } from './instruments/model-back/model-back.component';
import { TableInstumentBackComponent } from './instruments/table-instument-back/table-instument-back.component';
import { TableResumenComponent } from './tables/table-resumen/table-resumen.component';
import { UserBackComponent } from './user-back/user-back.component';
import { BackTrainingTypeComponent } from './back-trainings/back-training-type/back-training-type.component';
import { BackTrainingNoteComponent } from './back-trainings/back-training-note/back-training-note.component';
import { BackTrainingStateComponent } from './back-trainings/back-training-state/back-training-state.component';
import { BackTrainingTableStateComponent } from './back-trainings/back-training-table-state/back-training-table-state.component';
import { UserMineBackComponent } from './mines/user-mine-back/user-mine-back.component';
import { TableIncidenceBackComponent } from './incidences/table-incidence-back/table-incidence-back.component';
import { IncidenceBackComponent } from './incidences/incidence-back/incidence-back.component';
import { TableUsersBackComponent } from './user-back/table-users-back/table-users-back/table-users-back.component';

@NgModule({
  declarations: [
    ModalAcceptComponent,
    TableComponent,
    TableInstrumentsComponent,
    TableTimesComponent,
    CardbackofficeComponent,
    ServiceBackComponent,
    SectorBackComponent,
    ZoneBackComponent,
    AreaBackComponent,
    PhaseBackComponent,
    MapComponent,
    MineBackComponent,
    ImageBackComponent,
    TypeBackComponent,
    BrandBackComponent,
    ModelBackComponent,
    TableInstumentBackComponent,
    TableResumenComponent,
    UserBackComponent,
    BackTrainingTypeComponent,
    BackTrainingNoteComponent,
    BackTrainingStateComponent,
    BackTrainingTableStateComponent,
    UserMineBackComponent,
    TableIncidenceBackComponent,
    IncidenceBackComponent,
    TableUsersBackComponent
  ],
  exports: [
    ModalAcceptComponent,
    TableInstrumentsComponent,
    TableTimesComponent,
    TableComponent,
    CardbackofficeComponent,
    ServiceBackComponent,
    SectorBackComponent,
    ZoneBackComponent,
    AreaBackComponent,
    PhaseBackComponent,
    MapComponent,
    MineBackComponent,
    ImageBackComponent,
    TypeBackComponent,
    BrandBackComponent,
    ModelBackComponent,
    TableInstumentBackComponent,
    TableResumenComponent,
    UserBackComponent,
    BackTrainingTypeComponent,
    BackTrainingNoteComponent,
    BackTrainingStateComponent,
    UserMineBackComponent,
    TableIncidenceBackComponent,
    IncidenceBackComponent,
    TableUsersBackComponent
  ],
  imports: [
    CommonModule,
    InputsModule, WavesModule, ButtonsModule, CharCounterModule,
    IconsModule,
    CharCounterModule,
    FormsModule,
    ReactiveFormsModule,
    WavesModule,
    TableModule,
    IconsModule,
    CollapseModule,
    TooltipModule,
    GoogleMapsModule,
    MDBBootstrapModulesPro.forRoot(),
    ModalModule.forRoot(),
  ],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent],
  schemas:  [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule { }
