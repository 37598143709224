
import { MinesService } from '../../services/backoffice/mine/mines/mines.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-back-mines',
  templateUrl: './back-mines.component.html',
  styleUrls: ['./back-mines.component.scss']
})
export class BackMinesComponent implements OnInit, OnDestroy {

  mines: any[] = [];

  headElements = ['N°', 'Sigla', 'Nombre Servicio', 'Dirección de Oficinas', 'Herramientas'];
  headTable = ['nombre_corto', 'nombre_largo', 'direccion_oficinas', 'tools'];
  colspanLarge: number = 5;

  modalInfo: ModalInfo = new ModalInfo();
  stateModalAlert: boolean = false;
  stateModalTable: boolean = false;

  stateModalMine: boolean = false;
  typeMine: string = 'new';
  dataMine: any;

  objectSelected: any;
  objectsSubscription: Subscription;
  
  constructor( private minesService: MinesService, private router: Router, public authService: AuthService ) { }

  ngOnInit(): void {

    this.getMines();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
 }

  onReponseStateModalFormMine(event){
    this.stateModalMine = event;
  }
  
  onreponsDeleteObjectTable($event){
    console.log('COMPANY1: '+$event.id);
    this.objectSelected = $event;
    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+$event.nombre_largo);  
  }

  onreponseUpdateObjectTable($event){
    this.objectSelected = $event;
    this.router.navigateByUrl('/emt/sectors/'+ $event.id );
  }

  onReponseDeleteObjectModal($event){

    this.deleteMine(this.objectSelected.id);
  }

  onResponseAlert(event){
    this.stateModalAlert = false;
  }

  onResponseRefresh(){
    this.getMines();
  }

  addMine(){
    this.stateModalMine = true;
  }
  
  getMines(){

    this.objectsSubscription = this.minesService.getMines( ).subscribe({
      next: (resp) => { 
         this.mines = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  deleteMine(id: string){

    this.minesService.deleteMine(id).subscribe({
      next: (resp) => { 
         //this.getMines();  
         this.mines = this.mines.filter(objeto => objeto.id !== this.objectSelected.id);
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
