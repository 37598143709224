import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

const base_url = environment.base_url;

@Injectable({
  providedIn: "root",
})
export class DailyShiftService {
  constructor(private http: HttpClient) {}

  getDailyShift(): Observable<boolean> {
    const url = `${base_url}/dailyshift`;

    return this.http.get(url).pipe(
      tap((resp: any) => {
        console.log("resp: " + resp);
      })
    );
  }

  createDailyShift(form: any) {
    const url = `${base_url}/dailyShift`;
    const req = this.http.post(url, form);
    return req;
  }
}
