<div mdbModal #modalAccept="mdbModal" class="modal fade top" id="modalAccept" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1500 !important;">
  <div class="modal-dialog modal-dialog-centered modal-md modal-notify" role="document">
    <!--Content-->
    <div class="modal-content text-center">
      <!--Header-->
      <!-- [ngStyle]="{'background-color': ( modalInfo.type == 'error') ? '#D41920' : '#20A647'}" -->
      <div class="modal-header d-flex justify-content-center css-modal-header">
        <p class="text-head">Notificación</p>
      </div>
      <!--Body-->
      <div class="modal-body pt-1 pb-0">
        <mdb-icon *ngIf="modalInfo.type == 'success'" fas icon="check" size="4x"
          class="green-text animated fadeIn pt-3"></mdb-icon>
        <mdb-icon *ngIf="modalInfo.type == 'error'" fas icon="times" size="4x" class="red-text animated fadeIn pt-3">
        </mdb-icon>
        <mdb-icon *ngIf="modalInfo.type == 'warning'" fas icon="exclamation-triangle" size="4x" class="red-text animated fadeIn pt-3">
        </mdb-icon>
        <p class="pt-3" style="color: black; font-size: 20px;">{{ modalInfo.messageModal }}</p>
      </div>
      <!--Footer-->
      <div *ngIf="modalInfo.type != 'warning'" class="modal-footer justify-content-center">
        <!-- <a href="https://mdbootstrap.com/docs/b5/angular/pro/" mdbBtn color="danger" outline="true" class="waves-effect"
          mdbWavesEffect>Yes</a> -->
        <a type="button" mdbBtn rounded="false" class="waves-effect save-button" mdbWavesEffect data-dismiss="modal"
          (click)="modalAccept.hide()">Ok
        </a>
      </div>
      <div *ngIf="modalInfo.type == 'warning'" class="modal-footer justify-content-center">
        <button type="button" mdbBtn class="waves-light button-cancel-modals cancel-button"  (click)="modalAccept.hide()"
            mdbWavesEffect>Cancelar
        </button>   
        <button type="button" mdbBtn class="waves-light button-accept-modals save-button"  (click)="deleteObject()"
            mdbWavesEffect>Aceptar
        </button>          
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>