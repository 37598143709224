
<div class="padding-view">
    <div class="breadcrumb-style">
      <mdb-breadcrumb customClass="white" class="breadcrumbs pl-4">
        <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/home">Backoffice</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/backInstruments">Instrumentos</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="breadcrumbs-link">Resumen</mdb-breadcrumb-item>
    </mdb-breadcrumb>
    </div>
    <div class="container z-depth-1 p-0 my-5">
      <div class="header-forms white-text header-div">
        <div>
        </div>
        <h5 class="header-title-forms">
          Resumen
        </h5>
        <div class="add-icon">
            <a (click)="download()"><mdb-icon fas icon="download" class="add-button" mdbTooltip="Descargar" placement="top"></mdb-icon></a>
        </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="md-form md-outline col-md-3">
          <mdb-select-2 [outline]="true" class="input-style" placeholder="Elegir Tipo" label="Tipo" [(ngModel)]="valueFilter" (ngModelChange)="getSelectedFilter($event)">
          <mdb-select-option *ngFor="let option of types; let i = index" [value]="option.nombre">{{ option.nombre }}
          </mdb-select-option>
        </mdb-select-2>
        </div>
        <div class="md-form md-outline col-md-3">
          <button type="button" mdbBtn class="waves-light button-accept-modals save-button m-0"  (click)="filter()"
            mdbWavesEffect>Filtrar
          </button> 
          <button type="button" mdbBtn class="waves-light button-accept-modals cancel-button m-0 ml-3"  (click)="resetFilter()"
            mdbWavesEffect>Reset
          </button> 
        </div>
      </div>
    </div>
      <div class="table-body container">
        <app-table-resumen [data]="resume" [stateModal]="stateModalTable" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
        [stateDownload]="stateDownload" (reponseStateDownload) = "OnReponseStateDownload($event)"></app-table-resumen>
     </div>
    </div>
  </div>
  