

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import { MinesService } from 'src/app/services/backoffice/mine/mines/mines.service';
import { ModalInfo } from 'src/app/models/modal-info';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mine-back',
  templateUrl: './mine-back.component.html',
  styleUrls: ['./mine-back.component.scss']
})
export class MineBackComponent implements OnInit {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();

  modalInfo: ModalInfo = new ModalInfo();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  idObject: string = '';
  titleModal: string = 'Editar Faena'
  
  public myDatePickerOptions: IMyOptions = {
    // Your options
    //  dateFormat: 'DD/MM/YYYY'
  };

  types = [{"id": 1, "nombre":"Vigilancia"}, {"id": 2, "nombre":"Monitoreo"}]
  //companies = [{"id": 1, "nombre":"AMSA"}, {"id": 2, "nombre":"CODELCO"}]

  companies = [];

  getCompaniesSubscription: Subscription;

  constructor( private fb: FormBuilder, private minesbackService: MinesbackService, private router: Router, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id');
   }

  ngOnInit(): void {

    this.initForm();
    this.getCompanies();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'nombre_corto': ['', Validators.required],
      'nombre_largo': ['', Validators.required],
      'email_svr': ['', [Validators.required, Validators.email]],
      'telefono_svr': ['', Validators.required],
      'direccion_oficinas': ['', Validators.required],
      'este': [ '', Validators.required],
      'norte': ['', Validators.required],
      'cota': ['', Validators.required],
      'inicio_mes': ['', Validators.required],
      'imagen_corporativa': [''],
      "url_imagen": [''],
      "compania": ['', Validators.required],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'nombre_largo': this.data.nombre_largo,
      'nombre_corto': this.data.nombre_corto,
      'email_svr': this.data.email_svr,
      'telefono_svr': this.data.telefono_svr,
      'compania': this.data.compania,
      'direccion_oficinas': this.data.direccion_oficinas,
      'inicio_mes': this.data.inicio_mes,
      'este': this.data.este,
      'norte': this.data.norte,
      'cota': this.data.este
    });
    
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal == true) {

      this.addModal.show();
      this.reponseStateModalForm.emit(false);
    }

    if(this.objectForm){
      if(this.typeModal == 'edit'){
        
        this.titleModal = 'Editar Faena'
        this.idObject = this.data.id;
        this.patchForm();

      }else{
        this.titleModal = 'Agregar Faena'
        this.objectForm.reset();
      }
    }
  }

  showAddModal(){

    this.addModal.show();
  }

  onResponseNormal(event){

    this.stateModalAlert = event;
  }


  onSubmitObject(){

    console.log('ON SUBMIT');

    if(this.objectForm.invalid){

      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');
      return
    }

    this.typeModal == 'new' ? this.createMine() : this.updateMine();
 
  }

  createMine(){

    const start = this.objectForm.value.inicio_mes.toString()+ "T" +"00:00:00".toString();
    this.objectForm.get('inicio_mes').setValue(start);
    // this.objectForm.get('inicio_mes').patchValue(new Date(this.objectForm.value.inicio_mes).toISOString());
    
    this.minesbackService.createMine(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
        this.reponseRefresh.emit(false);
        this.objectForm.reset();
        this.addModal.hide();
        this.messageAlert(true, 'success', 'Faena creada correctamente');  
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al crear faena');
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }

  updateMine(){

    if(this.objectForm.value.inicio_mes.length < 15){
        const start = this.objectForm.value.inicio_mes.toString()+ "T" +"00:00:00".toString();
        this.objectForm.get('inicio_mes').setValue(start);
    }

    // this.objectForm.get('inicio_mes ').patchValue(new Date(this.objectForm.value.inicio_mes).toISOString());

    this.minesbackService.updateMine(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{  
        localStorage.setItem('mine',  JSON.stringify(resp.objects[0]));
        this.reponseRefresh.emit(false);
        this.objectForm.reset();
        this.addModal.hide();       
        this.messageAlert(true, 'success', 'Faena actualizada correctamente');  
      },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar faena');
    },
    complete: () => {  
        console.log('Completed');
    }
  });
  }

  getCompanies(){
  
    this.getCompaniesSubscription = this.minesbackService.getCompanies().subscribe({
      next: (resp) => { 
         this.companies = resp;
         console.dir('Companies: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }
  
  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}

