<!-- 
<header>
    <app-navbar-backoffice></app-navbar-backoffice>
</header> -->
<!--/.Double navigation-->

<header>
    <app-navbar-backoffice></app-navbar-backoffice>
</header>

<!--Main Layout-->
<main>
    <div class="px-0 mx-0 container-fluid">
        <router-outlet></router-outlet>
    </div>
</main>
<!--/Main layout-->