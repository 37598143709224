import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyOptions, ModalDirective } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstrumentsService } from 'src/app/services/backoffice/instruments/instruments.service';

@Component({
  selector: 'app-model-back',
  templateUrl: './model-back.component.html',
  styleUrls: ['./model-back.component.scss']
})
export class ModelBackComponent implements OnInit, OnDestroy {

  @ViewChild('addModal', { static: true }) addModal!: ModalDirective;

  @Input() modalInfo: any;
  @Input() stateModal: boolean;
  @Input() data: any;
  @Input() typeModal: string;
  @Output() reponseStateModalForm = new EventEmitter();
  @Output() reponseRefresh = new EventEmitter();

  objectForm: FormGroup;

  stateModalTable: boolean = false;
  stateModalAlert: boolean = false;

  idMine: string = '';
  titleModal: string = 'Agregar Modelo'

  idObject: string = '';
  // servicesMine = [];

  brands = [];
  objectsSubscription: Subscription;
  
  // brands = [{"id": 1, "nombre":"Vigilancia"}, {"id": 2, "nombre":"Monitoreo"}]

  constructor( private fb: FormBuilder, private instrumentsService: InstrumentsService, private router: Router, private route: ActivatedRoute) { 
    this.idMine = this.route.snapshot.paramMap.get('id'); //a las rutas
   }

  ngOnInit(): void {

    this.initForm();
    this.getBrands();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
  }

  initForm(){

    this.objectForm = this.fb.group({
      'marca_id': ['', Validators.required],
      'nombre': ['', Validators.required],
    });
  }

  patchForm(){

    this.objectForm.patchValue({
      'tipo_id': this.data.marca_id,
      'nombre': this.data.nombre,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes ", changes);

    if (this.stateModal) {
      console.log("MESSAGE STATEMODAL TRUE CHANGES");

      this.addModal.show();
      this.reponseStateModalForm.emit(false);

      if(this.objectForm){
        if(this.typeModal == 'edit'){
          
          this.titleModal = 'Editar Modelo';
          this.idObject = this.data.id;
          this.patchForm();
        }else{
          this.objectForm.reset();
          this.titleModal = 'Agregar Modelo';
        }
      }
    }
  }

  showAddModal(){

    this.addModal.show();
  }

  onResponseAlert(event){

    this.stateModalAlert = event;
  }


  onSubmitObject(){

    console.log('ON SUBMIT');

    if(this.objectForm.invalid){
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');
      return
    }

    this.typeModal == 'new' ? this.createModel() : this.updateModel();
  }


  createModel(){

    this.instrumentsService.createModelInstrument(this.objectForm.value).subscribe({
      next: (resp: any) =>{  
        this.reponseRefresh.emit(false);    
        this.addModal.hide();
        this.objectForm.reset();
        this.messageAlert(true, 'success', 'Modelo creado correctamente');
    },
    error: (err) => {
       console.log('Received an error: ' + err);
       this.messageAlert(true, 'error', 'Error al crear modelo');
      },
    complete: () => {  
        console.log('Completed');
    }
    });
  }

  updateModel(){

    this.instrumentsService.updateModelInstrument(this.objectForm.value, this.idObject).subscribe({
      next: (resp: any) =>{  
      this.reponseRefresh.emit(false); 
      this.addModal.hide();
      this.objectForm.reset();
      this.messageAlert(true, 'success', 'Modelo actualizado correctamente');
    },
    error: (err) => { 
      console.log('Received an error: ' + err);
      this.messageAlert(true, 'error', 'Error al actualizar modelo');
    },
    complete: () => {  
        console.log('Completed');
    }
    });
  }
     
  getBrands(){

    this.objectsSubscription = this.instrumentsService.getBrandInstrument( ).subscribe({
      next: (resp) => { 
        console.log('Response: ' + resp); 
         this.brands = resp;
         this.stateModalTable = true;  
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  getFieldControl(fieldName: string) {
    return this.objectForm.get(fieldName);
  }
    
  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

}
