
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Mine } from '../../../../models/mine';
import { SectorMine } from '../../../../models/sector-mine';
import { Servicio } from '../../../../models/servicio';
import { Area } from '../../../../models/area';
import { Company } from 'src/app/models/company';
import { Zone } from 'src/app/models/zone';

const base_url = environment.base_url;
const base_url_fast = environment.base_url_fast;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Basic ' + btoa('Galio:Emt.532%')
  })
};

@Injectable({
  providedIn: 'root'
})
export class MinesbackService {

  constructor(private http: HttpClient) { }

  //Companies

  
  getCompanies(): Observable<Company[]>{
    const url = `${ base_url }/companies`;
    return this.http.get( url )
              .pipe(
                map( (resp: {ok: boolean, objects: Company[] }) => resp.objects )
              );
  }

  //Mines

  getMines(): Observable<Mine[]>{
    const url = `${ base_url_fast }/faena`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Mine[] }) => resp.objects )
              );
  }

  getMineById(id: string): Observable<Mine[]>{
    const url = `${ base_url_fast }/faena/${id}`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Mine[] }) => resp.objects )
              );
  }

  createMine(form: any) {
    const url = `${ base_url_fast }/faena`;
    const req = this.http.post(url, form, httpOptions);
    return req;
  }

  updateMine(form: any, id: string) {
    const url = `${base_url_fast}/faena/${id}`;
    const req = this.http.put(url, form, httpOptions);
    return req;
  }

  //All Data
  getServicesByMine(id: string): Observable<any[]>{
    const url = `${ base_url_fast }/faena/componentes/${id}`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
              );
  }

  // Services
  getServicios(): Observable<Servicio[]>{
    const url = `${ base_url_fast }/servicio`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Servicio[] }) => resp.objects )
              );
  }

  getServiciosByMine(id: string): Observable<Servicio[]>{
    const url = `${ base_url_fast }/faena/servicios/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Servicio[] }) => resp.objects )
              );
  }

  createService(form: any): Observable<Servicio[]> {
    const url = `${ base_url_fast }/faena/servicio`;
    return this.http.post( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: Servicio[] }) => resp.objects )
              );
  }

  updateService(form: any, id: string) {
    const url = `${base_url_fast}/faena/servicio/${id}`;
    const req = this.http.put(url, form, httpOptions);
    return req;
  }

  deleteService(id: string) {
    const url = `${base_url_fast}/faena/servicio/${id}`;
    const req = this.http.delete(url, httpOptions);
    return req;
  }


  // Sectores
  getSectors(): Observable<SectorMine[]>{
    const url = `${ base_url_fast }/sector`;
    return this.http.get( url, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: SectorMine[] }) => resp.objects )
              );
  }

  getSectorsByMine(id: string): Observable<SectorMine[]>{
    const url = `${ base_url_fast }/faena/sector/${id}`;
    return this.http.post( url, null, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: SectorMine[] }) => resp.objects )
              );
  }

  // createSector(form: any, id: string) {
  //   const url = `${base_url_fast}/sector`;
  //   const req = this.http.post(url, form, httpOptions);
  //   return req;
  // }

  createSector(form: any, id: string): Observable<SectorMine[]> {
    const url = `${ base_url_fast }/sector`;
    return this.http.post( url, form, httpOptions )
              .pipe(
                map( (resp: {ok: boolean, objects: SectorMine[] }) => resp.objects )
              );
  }

  updateSector(form: any, id: string) {
    const url = `${base_url_fast}/sector/${id}`;
    const req = this.http.put(url, form, httpOptions);
    return req;
  }

  deleteSector(id: string) {
    const url = `${base_url_fast}/sector/${id}`;
    const req = this.http.delete(url, httpOptions);
    return req;
  }

    //Zona
    getZones(): Observable<Zone[]>{
      const url = `${ base_url_fast }/zona`;
      return this.http.get( url, httpOptions )
                .pipe(
                  map( (resp: {ok: boolean, objects: Zone[] }) => resp.objects )
                );
    }
  
    getZonesByMine(id: string): Observable<SectorMine[]>{
      const url = `${ base_url_fast }/faena/zona/${id}`;
      return this.http.post( url, null, httpOptions )
                .pipe(
                  map( (resp: {ok: boolean, objects: SectorMine[] }) => resp.objects )
                );
    }
  
    // createZone(form: any) {
    //   const url = `${base_url_fast}/zona`;
    //   const req = this.http.post(url, form, httpOptions);
    //   return req;
    // }

    createZone(form: any): Observable<Zone[]> {
      const url = `${ base_url_fast }/zona`;
      return this.http.post( url, form, httpOptions )
                .pipe(
                  map( (resp: {ok: boolean, objects: Zone[] }) => resp.objects )
                );
    }

    updateZone(form: any, id: string) {
      const url = `${base_url_fast}/zona/${id}`;
      const req = this.http.put(url, form, httpOptions);
      return req;
    }

    deleteZone(id: string) {
      const url = `${base_url_fast}/zona/${id}`;
      const req = this.http.delete(url, httpOptions);
      return req;
    }


    //Area
    getAreas(): Observable<Area[]>{
      const url = `${ base_url_fast }/monitoreo`;
      return this.http.get( url, httpOptions )
                .pipe(
                  map( (resp: {ok: boolean, objects: Area[] }) => resp.objects )
                );
    }

    getAreasBySector(id: string): Observable<Area[]>{
      const url = `${ base_url_fast }/sectores/areas/${id}`;
      return this.http.post( url, null, httpOptions )
                .pipe(
                  map( (resp: {ok: boolean, objects: Area[] }) => resp.objects )
                );
    }
  
    // getSectorsByMine(): Observable<SectorMine[]>{
    //   const url = `${ base_url_fast }/sector`;
    //   return this.http.get( url, httpOptions )
    //             .pipe(
    //               map( (resp: {ok: boolean, objects: SectorMine[] }) => resp.objects )
    //             );
    // }
  
    // createArea(form: any) {
    //   const url = `${base_url_fast}/monitoreo`;
    //   const req = this.http.post(url, form, httpOptions);
    //   return req;
    // }

    createArea(form: any): Observable<Area[]> {
      const url = `${ base_url_fast }/monitoreo`;
      return this.http.post( url, form, httpOptions )
                .pipe(
                  map( (resp: {ok: boolean, objects: Area[] }) => resp.objects )
                );
    }
  
    updateArea(form: any, id: string) {
      const url = `${base_url_fast}/monitoreo/${id}`;
      const req = this.http.put(url, form, httpOptions);
      return req;
    }

    deleteArea(id: string) {
      const url = `${base_url_fast}/monitoreo/${id}`;
      const req = this.http.delete(url, httpOptions);
      return req;
    }
  
    //Phase
    getPhases(): Observable<Area[]>{
      const url = `${ base_url_fast }/fase`;
      return this.http.get( url, httpOptions )
                .pipe(
                  map( (resp: {ok: boolean, objects: Area[] }) => resp.objects )
                );
    }
  
    // getSectorsByMine(): Observable<SectorMine[]>{
    //   const url = `${ base_url_fast }/sector`;
    //   return this.http.get( url, httpOptions )
    //             .pipe(
    //               map( (resp: {ok: boolean, objects: SectorMine[] }) => resp.objects )
    //             );
    // }
  
    // createPhase(form: any) {
    //   const url = `${base_url_fast}/fase`;
    //   const req = this.http.post(url, form, httpOptions);
    //   return req;
    // }

    createPhase(form: any): Observable<any[]> {
      const url = `${ base_url_fast }/fase`;
      return this.http.post( url, form, httpOptions )
                .pipe(
                  map( (resp: {ok: boolean, objects: any[] }) => resp.objects )
                );
    }

    updatePhase(form: any, id: string) {
      const url = `${base_url_fast}/fase/${id}`;
      const req = this.http.put(url, form, httpOptions);
      return req;
    }

    deletePhase(id: string) {
      const url = `${base_url_fast}/fase/${id}`;
      const req = this.http.delete(url, httpOptions);
      return req;
    }
}
