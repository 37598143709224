
<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">{{ titleModal }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body">
              <div class="container mt-4 px-4">
                <form class="text-center forms-text" [formGroup]="objectForm">
                  <div class="mx-auto px-4" style="background-color: white;">
                    <div class="row">

                      <div class="md-form md-outline mt-5 col-md-6">
                        <mdb-select-2 [outline]="true" placeholder="Seleccionar escala de tiempo" label="Escala de tiempo (*)" formControlName="estado_id">
                            <mdb-select-option *ngFor="let option of dataTimeLine" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                        </mdb-select-2>
                      </div>

                      <div class="md-form md-outline mt-5 col-sm-6">
                        <input type="text" formControlName="nombre" id="materialcontactFormId" class="form-control" mdbInput
                          mdbValidate />
                        <label for="materialcontactFormId" class="pl-4">Nombre (*)</label>
                        <mdb-error *ngIf="input?.invalid && (input?.dirty || input?.touched)" class="pl-3">Entrada inválida</mdb-error>
                        <mdb-success *ngIf="input?.valid && (input?.dirty || input?.touched)" class="pl-3">Entrada válida</mdb-success>  
                      </div>
                             
                    </div>
                  </div>         
              </form>
              </div>
              
            </div>
            <div class="modal-footer modals-footer">
                <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
                <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitObject()" mdbWavesEffect>Guardar</button>
            </div>
        </div>
    </div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
(reponseStateModalAccept)="onResponseNormal($event)"></app-modal-accept>
