
<div class="padding-view">
    <div class="breadcrumb-style">
      <mdb-breadcrumb customClass="white" class="breadcrumbs pl-4">
        <mdb-breadcrumb-item class="breadcrumbs-gray" routerLink="/back/home">Backoffice</mdb-breadcrumb-item>
        <mdb-breadcrumb-item class="breadcrumbs-link">Faenas</mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
      <div class="container z-depth-1 p-0 mt-5">
        <div class="header-forms white-text header-div">
          <div></div>
          <h5 class="header-title-forms">
            Faenas
          </h5>
          <div class="add-icon">
              <a (click)="addMine()"><mdb-icon fas icon="plus-circle" class="add-button" *ngIf="authService.hasRole(['admin', 'geotecnico', 'supervisor'])" mdbTooltip="Agregar" placement="top"></mdb-icon></a>
          </div>
      </div>
      <div class="table-body container">
        <app-table [data]="mines" [stateModal]="stateModalTable" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
         (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
         (reponseUpdateTable)="onreponseUpdateObjectTable($event)"></app-table>
     </div>
      </div>
</div>
  
<app-mine-back [stateModal]="stateModalMine" [data]="dataMine" [typeModal]="typeMine" (reponseRefresh)="onResponseRefresh()"
  (reponseStateModalForm) = "onReponseStateModalFormMine($event)"></app-mine-back>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
		(reponseStateModalAccept) = "onResponseAlert($event)" (reponseDeleteObjectModal) = "onReponseDeleteObjectModal($event)"></app-modal-accept>
