import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { UsersService } from 'src/app/services/backoffice/users/users.service';
import { ModalInfo } from 'src/app/models/modal-info';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})

export class RecoverComponent implements OnInit {

  recoverForm: FormGroup;
  token: string;
  modalInfo: ModalInfo = new ModalInfo();
  stateModalAlert: boolean = false;

  constructor(public formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private userService: UsersService) {

    this.token = this.route.snapshot.paramMap.get('token'); 
    console.log('EL TOKEN: '+this.token);
  }

  ngOnInit(): void {

    this.initForm();
  }

  initForm(){

    this.recoverForm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(6)]],
      password1: [null, [Validators.required, Validators.minLength(6)]],
    });
  }

  recover() {
  
    if (this.recoverForm.invalid) {
  
      this.messageAlert(true, 'error', 'Debe completar los campos correspondientes');
      return;
    }
  
    if(this.recoverForm.value.password != this.recoverForm.value.password1 ){
        
      this.messageAlert(true, 'error', 'Las contraseñas deben ser iguales');
      return;
    }
  
    this.recoverUser();
  }
  
  onResponseNormal(event){
    this.stateModalAlert = false;
    console.log('RESPONSE MINE NORMAL');
  }
  
  
  returnLogin(){
  
    this.router.navigateByUrl('/login');
  }
  
  recoverUser(){
  
    this.userService.recover( this.recoverForm.value, this.token ).subscribe({
      next: (resp) =>{ 
          console.log('Response: ' + resp.ok);
          this.messageAlert(true, 'success', resp.msg);
      },
      error: (err) => { 
        this.messageAlert(true, 'error', err.error.msg);
      },
      complete: () => { 
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 2000);
          console.log('Completed'); }
    });
  }

  get password1() { return this.recoverForm.get('password1'); }
  get password() { return this.recoverForm.get('password'); }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}