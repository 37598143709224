
<div class="container-fluid mt-3 px-0 mb-2">
    <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link mt-3 p-0 mb-0 pb-0">
      <mdb-breadcrumb-item class="active">Instrumentos</mdb-breadcrumb-item>
    </mdb-breadcrumb>
    <div class="z-depth-1">
      <div class="header-forms white-text header-div">
        <div></div>
        <div>
          <h5 class="header-title-forms">
            Instrumentos
          </h5>
        </div>
        <div class="add-icon">
            <a href="/emt/instrument/new"><mdb-icon fas icon="plus-circle" style="color: white;" *ngIf="authService.hasRole(['admin', 'supervisor', 'vigilante'])" mdbTooltip="Agregar" placement="top"></mdb-icon></a>
        </div>
      </div>
      <div class="table-body container">
        <app-table-instruments [data]="mines" [stateModal]="stateModalTable" [headElements]="headElements" [headTable]="headTable" [colspanLarge]="colspanLarge"
         (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
         (reponseUpdateTable)="onreponseUpdateObjectTable($event)"
         (reponseTimeTable)="onreponseTimeObjectTable($event)"
         (reponseChangeTable)="onreponseChangeObjectTable($event)"></app-table-instruments>
     </div>
    </div>
  </div>
  
<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModal" (reponseDeleteObjectModal) = "onReponseDeleteObjectModal($event)"
		(reponseStateModalAccept) = "onResponseModalAlert($event)"></app-modal-accept>
