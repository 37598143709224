
<div mdbModal #addModal="mdbModal" class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 1050 !important">
    <div class="modal-dialog modal-size" role="document">
        <div class="modal-content">
            <div class="header-div">
                <h4 class="modal-title-head" id="myModalLabel">{{ titleModal }}</h4>
                <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close" (click)="addModal.hide()">
                    <span aria-hidden="true" class="p-0 m-0">×</span>
                </button>
            </div>
            <div class="modal-body modals-body">
              <div class="row mt-4 px-4">
              <div class="col-md-6">
                  <div class="sub-headers-forms text-center">
                    <h2 class="title-forms">Imagen de Faena</h2>
                  </div>
                  <div class="img-container z-depth-2">
                    <div class="py-3">
                      <input type="file" class="pl-1" (change)="onFileSelected($event, 'img')"/>
                      <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitObject('img')" mdbWavesEffect [disabled]="isButtonDisabled">Guardar</button>
                     </div>
                     <div *ngIf="base64Image" class="img-div">
                       <img [src]="base64Image" alt="Uploaded Image" width="200px" heigth="110px"/>
                     </div>
                     <div *ngIf="originalImage" class="img-div">
                      <img src="{{image}}" alt="Uploaded Image" width="200px" heigth="110px"/>
                    </div>     
                  </div>
                  <!-- <div style="background-color: white;">
                      <img src="{{image}}" width="270px" heigth="210px" alt="image">
                  </div> -->                
              </div>
              <div class="col-md-6">
          
                <div class="sub-headers-forms text-center">
                  <h2 class="title-forms">Logo de Faena</h2>
                </div>
                <div class="img-container z-depth-2">
                  <div class="py-3">
                    <input type="file" class="pl-1" (change)="onFileSelected($event, 'logo')" />
                    <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitObject('logo')" mdbWavesEffect [disabled]="isButtonDisabled">Guardar</button>
                  </div>
                  <div *ngIf="base64Logo" class="img-div">
                    <img [src]="base64Logo" alt="Uploaded Image" width="200px" heigth="110px"/>
                  </div>
                  <div *ngIf="originalLogo" class="img-div">
                    <img src="{{logo}}" alt="Uploaded Image" width="200px" heigth="110px"/>
                  </div>   
                </div>
              </div>
            </div>
              
            </div>
            <div class="modal-footer modals-footer">
                <!-- <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()" mdbWavesEffect>Cancelar</button>
                <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitObject()" mdbWavesEffect>Guardar</button> -->
            </div>
        </div>
    </div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
(reponseStateModalAccept)="onResponseAlert($event)"></app-modal-accept>
