import { DailyShiftService } from './../../services/daily-shift.service';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IMyOptions, MDBDatePickerComponent, LocaleService } from 'ng-uikit-pro-standard';
import { MdbTimePickerModule, WavesModule } from 'ng-uikit-pro-standard';
import { ModalInfo } from 'src/app/models/modal-info';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  constructor( private fb: FormBuilder, private dailyShiftService: DailyShiftService, private localeService: LocaleService, private spinner: NgxSpinnerService ) { 

  }

  ngOnInit(): void {

    // this.spinner.show();

    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 3000);

  }

 
}
