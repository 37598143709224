

<div class="card-circle z-depth-5 animated fadeIn fast" style="background: url( {{mine.url_imagen}} ) no-repeat center center; background-size: cover;">
        <!-- <img
        src="{{ mine.url_imagen }}"
        class="img-fluid mine-image" alt="Sample image with waves effect.">   -->
    
        <div class="card-main">
            <p class="card-title"> {{ mine.nombre_corto }} </p>
            <!-- <p class="card-text word"> {{ element.ubicacion }} </p> -->
    
            <button class="btn-color btn-card" mdbBtn type="button" rounded="false" block="false" mdbWavesEffect (click)="home(mine)">INGRESAR</button>
        </div>
    </div>

