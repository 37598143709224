import { MinesService } from '../../services/backoffice/mine/mines/mines.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  mines = [];
  mineSubscription: Subscription;

  constructor(private router: Router, private minesService: MinesService, private spinner: NgxSpinnerService) { }

//   mines = [
//     {
//         "compania": "Antofagasta Minerals",
//         "nombre": "Minera Los Pelambres",
//         "ubicacion": "Salamanca",
//         "uid": "6241df97f3a61e917be97083",
//         "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/pelambres.PNG?alt=media&token=de84b494-3095-4d0b-9839-80b3266f1847"
//     },
//     {
//         "compania": "Antofagasta Minerals",
//         "nombre": "Antucoya",
//         "ubicacion": "Antofagasta",
//         "uid": "6241dfa6f3a61e917be97085",
//         "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/antacuya.jpg?alt=media&token=c30d4fb8-722d-42df-bc86-cd9952322eea"
//     },
//     {
//         "compania": "Antofagasta Minerals",
//         "nombre": "Centinela",
//         "ubicacion": "Antofagasta",
//         "uid": "62435d7fbf8778358b0f880a",
//         "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/Centinela.jpg?alt=media&token=07b5d663-c7e7-4df2-9438-813cf64610e2"
//     }
// ];


// mines = [
//   {
//     "company": "Antofagasta Minerals",
//     "name": "Minera Los Pelambres",
//     "initials": "MLP",
//     "location": "Antofagasta",
//     "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/pelambres.PNG?alt=media&token=de84b494-3095-4d0b-9839-80b3266f1847",
//     "active": true,
//     "createdAt": "2022-12-26T15:48:20.892Z",
//     "updatedAt": "2022-12-26T15:48:20.892Z",
//     "id": "1"
// },
// {
//     "company": "Antofagasta Minerals",
//     "name": "Antucoya",
//     "initials": "Antucoya",
//     "location": "Antofagasta",
//     "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/antacuya.jpg?alt=media&token=c30d4fb8-722d-42df-bc86-cd9952322eea",
//     "active": true,
//     "createdAt": "2022-12-26T15:50:14.191Z",
//     "updatedAt": "2022-12-26T15:50:14.191Z",
//     "id": "2"
// },
// {
//     "company": "Antofagasta Minerals",
//     "name": "Centinela",
//     "initials": "Centinela",
//     "location": "Antofagasta",
//     "url": "https://firebasestorage.googleapis.com/v0/b/reportabilidad-af140.appspot.com/o/Centinela.jpg?alt=media&token=07b5d663-c7e7-4df2-9438-813cf64610e2",
//     "active": true,
//     "createdAt": "2022-12-26T15:50:36.280Z",
//     "updatedAt": "2022-12-26T15:50:36.280Z",
//     "id": "3"
// }
// ]

  ngOnInit(): void {
  
  this.spinner.show();
  this.getMines();

  }

  // goMine( idx: any ){
  //   console.log('ID PROJECT: '+idx.uid);
  //   this.router.navigate( ['home/'+idx.uid] );
  // }

  getMines(){

    this.mineSubscription = this.minesService.getMines( ).subscribe({
      next: (resp) => { 
         this.mines = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed');
        this.spinner.hide(); 
      }
    });
  }

}
