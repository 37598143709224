<div class="container-fluid mt-3 px-0 mb-2">
  <mdb-breadcrumb customClass="white" class="breadcrumbs breadcrumbs-link mt-3 p-0 mb-0 pb-0">
    <mdb-breadcrumb-item (click)="goInstruments()">Instrumentos</mdb-breadcrumb-item>
    <mdb-breadcrumb-item class="active">Posiciones</mdb-breadcrumb-item>
  </mdb-breadcrumb>
  <div class="z-depth-1">
    <div class="header-forms white-text header-div">
      <div></div>
      <div>
        <h5 class="header-title-forms">
          Listado de cambio de posiciones del {{ instrumentCode }}
        </h5>
      </div>
      <div class="add-icon">
        <a (click)="showAddModal()"><mdb-icon fas icon="plus-circle" (click)="showAddModal()" *ngIf="authService.hasRole(['admin', 'supervisor', 'vigilante'])" mdbTooltip="Agregar" placement="top"
            style="color: white;"></mdb-icon></a>
      </div>
    </div>
    <div class="table-body container">
      <app-table-times [data]="times" [stateModal]="stateModalTable" [headElements]="headElements"
        [headTable]="headTable" [colspanLarge]="colspanLarge" (reponseDeleteTable)="onreponsDeleteObjectTable($event)"
        (reponseUpdateTable)="onreponseUpdateObjectTable($event)" (reponseTimeTable)="onreponseTimeObjectTable($event)"
        (reponseChangeTable)="onreponseChangeObjectTable($event)"></app-table-times>
    </div>
  </div>
</div>


<div mdbModal #addModal="mdbModal" style="overflow-y: auto" class="modal fade" id="basicExample" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-size" role="document">
    <div class="modal-content">
      <div class="header-div">
        <h4 class="modal-title-head" id="myModalLabel">Agregar nueva posición {{ instrumentCode }}</h4>
        <button type="button" class="close close-button p-0 m-0" data-dismiss="modal" aria-label="Close"
          (click)="addModal.hide()">
          <span aria-hidden="true" class="p-0 m-0">×</span>
        </button>
      </div>
      <div class="modal-body modals-body">
        <div class="container mt-4 px-4">
          <div>
            <form class="text-center row forms-text" [formGroup]="currentPositionForm">


              <div class="col-12 mx-auto" style="background-color: white;">
                <div class="row">

                  <div class="col-12 sub-headers-forms">
                    <span class="title-forms modals-subtitle">Posición actual</span>
                  </div>

                  <div class="col-12">
                    <div class="row px-3">
                      <div class="md-form md-outline mt-5 col-6">
                        <input type="text" formControlName="codigo" id="materialcontactFormId" class="form-control"
                          mdbInput mdbValidate />
                        <label for="materialcontactFormId" class="pl-4">Nombre del Instrumento</label>
                        <!-- <mdb-error *ngIf="id.invalid && (id.dirty || id.touched)">Input Invalido</mdb-error>
                                                                              <mdb-success *ngIf="id.valid && (id.dirty || id.touched)">Input Valido</mdb-success> -->
                      </div>

                      <!-- <div class="md-form md-outline mt-5 col-4">
                                            <mdb-select-2 [outline]="true" placeholder="Seleccionar Tipo perdida" label="Tipo" [formControlName]="'tipo_perdida'">
                                                <mdb-select-option *ngFor="let option of types" [value]="option.value">{{ option.label }}</mdb-select-option>
                                            </mdb-select-2>
                                        </div> -->
                      <!-- <div class="md-form md-outline mt-5 col-2">
                                          <input type="text" formControlName="tipo" id="materialcontactFormId" class="form-control" mdbInput mdbValidate />
                                          <label for="materialcontactFormId" class="pl-4">Tipo</label>
                                        </div> -->

                      <div class="md-form md-outline mt-5 col-2">
                        <input type="text" formControlName="este" id="materialcontactFormId" class="form-control"
                          mdbInput mdbValidate />
                        <label for="materialcontactFormId" class="pl-4">Este</label>
                        <!-- <mdb-error *ngIf="id.invalid && (id.dirty || id.touched)">Input Invalido</mdb-error>
                                                                          <mdb-success *ngIf="id.valid && (id.dirty || id.touched)">Input Valido</mdb-success> -->
                      </div>

                      <div class="md-form md-outline mt-5 col-2">
                        <input type="text" formControlName="norte" id="materialcontactFormId" class="form-control"
                          mdbInput mdbValidate />
                        <label for="materialcontactFormId" class="pl-4">Norte</label>
                        <!-- <mdb-error *ngIf="id.invalid && (id.dirty || id.touched)">Input Invalido</mdb-error>
                                                                          <mdb-success *ngIf="id.valid && (id.dirty || id.touched)">Input Valido</mdb-success> -->
                      </div>

                      <div class="md-form md-outline mt-5 col-2">
                        <input type="text" formControlName="cota" id="materialcontactFormId" class="form-control"
                          mdbInput mdbValidate />
                        <label for="materialcontactFormId" class="pl-4">Cota</label>
                        <!-- <mdb-error *ngIf="id.invalid && (id.dirty || id.touched)">Input Invalido</mdb-error>
                                                                          <mdb-success *ngIf="id.valid && (id.dirty || id.touched)">Input Valido</mdb-success> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>

          <div class="mt-5" *ngIf="typeInstrument" style="background-color: white;">


            <div class="sub-headers-forms mb-3">
              <h2 class="title-forms">Datos del proyecto</h2>
            </div>
            <form class="text-center row forms-text" [formGroup]="timesForm">

              <div class="row px-5">

                <ng-container *ngIf="this.typeInstrument == 'Radar'">
                  <div class="md-form md-outline mt-3- col-md-4">
                    <input type="text" formControlName="nombre" id="materialcontactFormId" class="form-control" mdbInput
                      mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Nombre proyecto (*)</label>
                    <mdb-error *ngIf="getFieldControl('nombre')?.invalid && (getFieldControl('nombre')?.dirty || getFieldControl('nombre')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('nombre')?.valid && (getFieldControl('nombre')?.dirty || getFieldControl('nombre')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="input-time md-form md-outline mt-3 col-md-2">
                    <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                        label="Fecha de Inicio (*)" formControlName="fecha_inicio_date">
                      </mdb-date-picker>
                    </div>
                  </div>

                  <div class="md-form  md-form md-outline mt-3 col-md-2">
                    <input type="time" id="input" name="appt-time" class="form-control"
                      formControlName="fecha_inicio_hora" value="00:00" mdbInput />
                    <label for="input" class="pt-1 pr-3">Hora de Inicio (*)</label>
                  </div>

                  <div class="input-time md-form md-outline mt-3 col-md-2">
                    <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'.'"
                        label="Fecha de Termino" formControlName="fecha_fin_date">
                      </mdb-date-picker>
                    </div>
                  </div>

                  <div class="md-form  md-form md-outline mt-3 col-md-2">
                    <input type="time" id="input" name="appt-time" class="form-control" formControlName="fecha_fin_hora"
                      value="00:00" mdbInput />
                    <label for="input">Hora de Termino</label>
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <input type="number" formControlName="este" id="materialcontactFormId" class="form-control"
                      [formControlName]="'este'" mdbInput mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Este (*)</label>
                    <mdb-error *ngIf="getFieldControl('este')?.invalid && (getFieldControl('este')?.dirty || getFieldControl('este')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('este')?.valid && (getFieldControl('este')?.dirty || getFieldControl('este')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <input type="number" formControlName="norte" id="materialcontactFormId" class="form-control"
                      [formControlName]="'norte'" mdbInput mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Norte (*)</label>
                    <mdb-error *ngIf="getFieldControl('norte')?.invalid && (getFieldControl('norte')?.dirty || getFieldControl('norte')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('norte')?.valid && (getFieldControl('norte')?.dirty || getFieldControl('norte')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <input type="number" formControlName="cota" id="materialcontactFormId" class="form-control"
                      [formControlName]="'cota'" mdbInput mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Cota (*)</label>
                    <mdb-error *ngIf="getFieldControl('cota')?.invalid && (getFieldControl('cota')?.dirty || getFieldControl('cota')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('cota')?.valid && (getFieldControl('cota')?.dirty || getFieldControl('cota')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>


                  <div class="md-form md-outline mt-3 col-md-4">
                    <mdb-select-2 [outline]="true" placeholder="Seleccionar Sector" label="Sector (*)"
                      [formControlName]="'sectores'" (ngModelChange)="changedSector($event)">
                      <mdb-select-option *ngFor="let option of sectors" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                    </mdb-select-2>
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Zonas" label="Zonas (*)"
                      [formControlName]="'zonas'" (ngModelChange)="changedZone($event)">
                      <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                      <mdb-select-option *ngFor="let option of zones" [value]="option.id">{{ option.nombre }}
                      </mdb-select-option>
                    </mdb-select-2>
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Fases" label="Fases (*)"
                      [formControlName]="'fases'" (ngModelChange)="changedPhase($event)">
                      <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                      <mdb-select-option *ngFor="let option of phases" [value]="option.id">{{ option.fase }}
                      </mdb-select-option>
                    </mdb-select-2>
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Paredes" label="Paredes (*)"
                      [formControlName]="'paredes'" (ngModelChange)="changedZone($event)">
                      <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                      <mdb-select-option *ngFor="let option of paredes" [value]="option.value">{{ option.value
                        }}</mdb-select-option>
                    </mdb-select-2>
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <mdb-select-2 [multiple]="true" [outline]="true" placeholder="Seleccionar Areas" label="Áreas (*)"
                      [formControlName]="'areas'">
                      <mdb-select-all-option>Seleccionar Todos</mdb-select-all-option>
                      <mdb-select-option *ngFor="let option of areas" [value]="option.id">{{ option.nombre }}</mdb-select-option>
                    </mdb-select-2>
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <input type="number" id="materialcontactFormId" class="form-control"
                      formControlName="banco"mdbInput mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Banco</label>
                    <mdb-error *ngIf="getFieldControl('banco')?.invalid && (getFieldControl('banco')?.dirty || getFieldControl('banco')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('banco')?.valid && (getFieldControl('banco')?.dirty || getFieldControl('banco')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <input type="number" id="materialcontactFormId" class="form-control"
                      formControlName="azimut" mdbInput mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Azimut [°]</label>
                    <mdb-error *ngIf="getFieldControl('azimut')?.invalid && (getFieldControl('azimut')?.dirty || getFieldControl('azimut')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('azimut')?.valid && (getFieldControl('azimut')?.dirty || getFieldControl('azimut')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <input type="number" id="materialcontactFormId" class="form-control"
                      formControlName="inclinacion" mdbInput mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Inclinación [°]</label>
                    <mdb-error *ngIf="getFieldControl('inclinacion')?.invalid && (getFieldControl('inclinacion')?.dirty || getFieldControl('inclinacion')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('inclinacion')?.valid && (getFieldControl('inclinacion')?.dirty || getFieldControl('inclinacion')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>
                </ng-container>

                <ng-container *ngIf="this.typeInstrument != 'Radar'">

                  <div class="md-form md-outline mt-3 col-md-4">
                    <input type="number" formControlName="este" id="materialcontactFormId" class="form-control" mdbInput mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Este</label>
                    <mdb-error *ngIf="getFieldControl('este')?.invalid && (getFieldControl('este')?.dirty || getFieldControl('este')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('este')?.valid && (getFieldControl('este')?.dirty || getFieldControl('este')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <input type="number" formControlName="norte" id="materialcontactFormId" class="form-control" mdbInput mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Norte</label>
                    <mdb-error *ngIf="getFieldControl('norte')?.invalid && (getFieldControl('norte')?.dirty || getFieldControl('norte')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('norte')?.valid && (getFieldControl('norte')?.dirty || getFieldControl('norte')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="md-form md-outline mt-3 col-md-4">
                    <input type="number" formControlName="cota" id="materialcontactFormId" class="form-control" mdbInput mdbValidate />
                    <label for="materialcontactFormId" class="pl-4">Cota</label>
                    <mdb-error *ngIf="getFieldControl('cota')?.invalid && (getFieldControl('cota')?.dirty || getFieldControl('cota')?.touched)" class="pl-3">Entrada inválida</mdb-error>
                    <mdb-success *ngIf="getFieldControl('cota')?.valid && (getFieldControl('cota')?.dirty || getFieldControl('cota')?.touched)" class="pl-3">Entrada válida</mdb-success> 
                  </div>

                  <div class="input-time md-form md-outline mt-3 col-md-2">
                    <div style="background-color: white !important; height: 36px; border-radius: 5px;">
                      <mdb-date-picker [inline]="true" name="mydate" [options]="myDatePickerOptions" [placeholder]="'-'"
                        label="Fecha de Inicio" formControlName="fecha_inicio_date">
                      </mdb-date-picker>
                    </div>
                  </div>

                  <div class="md-form  md-form md-outline mt-3 col-md-2">
                    <input type="time" id="input" name="appt-time" class="form-control"
                      formControlName="fecha_inicio_hora" value="00:00" mdbInput />
                    <label for="input" class="pt-1 pr-3">Hora de Inicio</label>
                  </div>

                </ng-container>

              </div>
            </form>
          </div>

        </div>

      </div>
      <div class="modal-footer modals-footer">
        <!-- <button type="button" mdbBtn color="secondary" size="sm" class="bwaves-light" data-dismiss="modal" (click)="addModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect>Save changes</button> -->
        <button type="button" mdbBtn class="waves-light cancel-button" (click)="addModal.hide()"
          mdbWavesEffect>Cancelar</button>
        <button type="button" mdbBtn class="waves-light save-button" (click)="onSubmitTime()"
          mdbWavesEffect>Guardar</button>
      </div>
    </div>
  </div>
</div>

<app-modal-accept [modalInfo]="modalInfo" [stateModal]="stateModalAlert"
  (reponseStateModalAccept)="onResponseAlert($event)"></app-modal-accept>