
<div class="container-fluid mt-3 px-0 mb-2">
    <!-- Default form contact -->
    <mdb-card>
      <mdb-card-header class="header-forms white-text text-center">
        <h5 class="header-title-forms">
          Crear Turno Diario
        </h5>
      </mdb-card-header>
      <mdb-card-body class="px-lg-5 pt-0 body-forms">
        <form class="text-center row forms-text" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  
          <div class="row">
  
            <div class="col-12 mx-auto mt-5" style="background-color: white;">
              <div class="row">
  
                <div class="col-12 sub-headers-forms">
                  <span class="title-forms">General</span>
                </div>
  
                <div class="col-12">
                  <div class="row container-fluid">
                    <div class="md-form mt-5 col-4">
                      <input type="text" formControlName="id_report" id="materialcontactFormId" class="form-control" mdbInput
                        mdbValidate />
                      <label for="materialcontactFormId">Id</label>
                      <mdb-error *ngIf="id.invalid && (id.dirty || id.touched)">Input Invalido</mdb-error>
                      <mdb-success *ngIf="id.valid && (id.dirty || id.touched)">Input Valido</mdb-success>
                    </div>
        
        
                    <div class="md-form mt-5 col-4">
                      <mdb-date-picker name="mydate" formControlName="date" #datePicker [options]="myDatePickerOptions"
                        [placeholder]="'Seleccionar Fecha'" [locale]="'es'" required>
                      </mdb-date-picker>
                    </div>
      
                    <div class="md-form mt-5 col-4">
                      <mdb-select formControlName="shift" [options]="optionsSelect" placeholder="Seleccionar tu Turno"
                        mdbValidate></mdb-select>
                      <mdb-error *ngIf="subjects.invalid && (subjects.dirty || subjects.touched)">Input invalid</mdb-error>
                      <mdb-success *ngIf="subjects.valid && (subjects.dirty || subjects.touched)">Input valid</mdb-success>
                    </div>
                  </div>
                </div>
  
  
  
              </div>
  
  
            </div>
  
  
            <div class="col-12 mx-auto mt-5" style="background-color: white;">
              <div class="row">
                <div class="col-12 sub-headers-forms">
                  <span class="title-forms">Enlaces</span>
                </div>
                <div class="col-12">
                  <div class="row container-fluid">
                    <div class="col-3 pt-0 mt-0 mx-auto d-flex justify-content-center">
                      <div class="md-form">
                        <mdb-checkbox mdbValidate formControlName="mineNetworkLink">Enlace Red Mina</mdb-checkbox>
                      </div>
                    </div>
                    <div class="col-3 pt-0 mt-0 mx-auto d-flex justify-content-center">
                      <div class="md-form">
                        <mdb-checkbox mdbValidate formControlName="vpnLink">EMT</mdb-checkbox>
                      </div>
                    </div>
                    <div class="col-3 pt-0 mt-0 mx-auto d-flex justify-content-center">
                      <div class="md-form">
                        <mdb-checkbox mdbValidate formControlName="vpn">Vpn</mdb-checkbox>
                      </div>
                    </div>
                    <div class="col-md-3 pt-0 mt-0 mx-auto d-flex justify-content-center">
                      <div class="md-form">
                        <mdb-checkbox mdbValidate formControlName="emt">EMT</mdb-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  
            <div class="col-12 mx-auto mt-5" style="background-color: white;">
              <div class="row">
                <div class="col-12 sub-headers-forms">
                  <span class="title-forms">Observaciones</span>
                </div>
                <div class="col-12">
                  <div class="md-form mt-5">
                    <textarea type="text text-area-form" formControlName="failure_event" id="materialContactFormMessage" style="height: 100px;"
                      class="form-control md-textarea" mdbInput mdbValidate></textarea>
                    <label for="materialContactFormMessage">Observaciones eventos geotécnicos por falla de terreno</label>
                    <mdb-error *ngIf="message.invalid && (message.dirty || message.touched)">Input invalid</mdb-error>
                    <mdb-success *ngIf="message.valid && (message.dirty || message.touched)">Input valid</mdb-success>
                  </div>
      
                  <div class="md-form mt-5">
                    <textarea type="text text-area-form" formControlName="special_works" id="materialContactFormMessage" style="height: 100px;"
                      class="form-control md-textarea" mdbInput mdbValidate></textarea>
                    <label for="materialContactFormMessage">Observaciones eventos geotécnicos por falla de terreno</label>
                    <mdb-error *ngIf="message.invalid && (message.dirty || message.touched)">Input invalid</mdb-error>
                    <mdb-success *ngIf="message.valid && (message.dirty || message.touched)">Input valid</mdb-success>
                  </div>
      
                  <div class="md-form mt-5">
                    <textarea type="text text-area-form" formControlName="other_themes" id="materialContactFormMessage" style="height: 100px;"
                      class="form-control md-textarea" mdbInput mdbValidate></textarea>
                    <label for="materialContactFormMessage">Observaciones eventos geotécnicos por falla de terreno</label>
                    <mdb-error *ngIf="message.invalid && (message.dirty || message.touched)">Input invalid</mdb-error>
                    <mdb-success *ngIf="message.valid && (message.dirty || message.touched)">Input valid</mdb-success>
                  </div>
      
                </div>
              </div>
            </div>
  
  
  
          </div>
  
          <!-- <div class="md-form">
          <mdb-time-picker [buttonLabel]="'Done'" 
          [twelvehour]="true" 
          [darktheme]="false"
          [placeholder]="'Selected time'" 
          [label]="'Light
          version, 12hours'"
          [showClock]="true"></mdb-time-picker>
        </div> -->
  
  
          <!-- <div class="md-form">
          <input
            mdbInput
            type="text"
            id="form1"
            class="form-control"
            [mdbTimePicker]="timepicker"
            
          />
          <label for="form1">Example label</label>
          <mdb-timepicker-toggle
            [mdbTimePickerToggle]="timepicker"
          ></mdb-timepicker-toggle>
          <mdb-timepicker #timepicker></mdb-timepicker>
        </div> -->
  
  
  
  
  
  
        <div class="col-12 mx-auto pt-3">
          <button mdbBtn outline="true" rounded="true" block="true" class="z-depth-0 my-4 waves-effect button-send-forms" style="color: white;"
              mdbWavesEffect type="submit" [disabled]="disabledSubmitButton">
              Enviar
          </button>
        </div>
        </form>
      </mdb-card-body>
    </mdb-card>
    <!-- Default form contact -->
  </div>
  
  
  