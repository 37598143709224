
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalInfo } from 'src/app/models/modal-info';
import { UsersService } from 'src/app/services/backoffice/users/users.service';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-back-users',
  templateUrl: './back-users.component.html',
  styleUrls: ['./back-users.component.scss']
})
export class BackUsersComponent implements OnInit, OnDestroy {

  users = [];

  headElements = ['N°', 'Nombre', 'Apellido', 'Rut', 'Cargo', 'Capacitación', 'Herramientas'];
  headTable = ['name1', 'last_name1', 'rut', 'role', 'training', 'tools'];
  colspanLarge: number = 8;

  modalInfo: ModalInfo = new ModalInfo();
  stateModalAlert: boolean = false;
  stateModalTable: boolean = false;

  stateModalType: boolean = false;
  typeObject: string = 'new';
  dataObject: any;

  objectSelected: any;
  objectsSubscription: Subscription;

  constructor( private usersService: UsersService, public authService: AuthService ) { }

  ngOnInit(): void {

    this.getUsers();
  }

  ngOnDestroy(){

    this.objectsSubscription?.unsubscribe();
 }

  onreponsDeleteObjectTable($event){
    this.objectSelected = $event;
    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+$event.nombre);   
  }

  onreponseUpdateObjectTable($event){
    this.typeObject = 'edit';
    this.stateModalType = true;
    this.dataObject = $event;
  }

  onResponseAlert(event){
    this.stateModalAlert = false;
  }

  onReponseStateModalFormMine(event){
    this.stateModalType = event;
  }
  
  addObject(){
    this.typeObject = 'new';
    this.stateModalType = true;
  }
  
  onResponseRefresh(){
    this.getUsers();
  }

  getUsers(){

    this.objectsSubscription = this.usersService.getUsers( ).subscribe({
      next: (resp) => { 
         this.users = resp;
         this.stateModalTable = true;  
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }
}
