import { ResendComponent } from './resend/resend.component';
import { RecoverComponent } from './recover/recover.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'recover/:token', component: RecoverComponent },
  { path: 'resend', component: ResendComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
