import { Mine } from 'src/app/models/mine';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalInfo } from 'src/app/models/modal-info';
import { MinesbackService } from 'src/app/services/backoffice/mine/mines/minesback.service';
import { AuthService } from 'src/app/services/backoffice/auth/auth.service';

@Component({
  selector: 'app-back-mine',
  templateUrl: './back-mine.component.html',
  styleUrls: ['./back-mine.component.scss']
})

export class BackMineComponent implements OnInit, OnDestroy {

  mines = [];
  services = [];
  
  headElements = ['Área', 'Herramientas'];
  headTable = ['name', 'tools'];
  colspanLarge: number = 2;

  headElementsFases = ['Fases','Áreas', 'Herramientas'];
  headTableFases = ['fases','name', 'tools'];

  modalInfo: ModalInfo = new ModalInfo();
  stateModalAlert: boolean = false;
  stateModalTable: boolean = false;
  mine: Mine;
  idMine: string = '';
  dataParam: string = '';

  typeModal: string = 'new';

  viewMap: boolean = false;
  // instrumentSelected: any;

  typeArea: string = 'new';
  typeImage: string = 'new';
  typeMine: string = 'new';
  typeZone: string = 'new';
  typeSector: string = 'new';
  typeService: string = 'new';
  typePhase: string = 'new';

  stateTools: boolean = false;
  stateImages: boolean = false;
  
  stateModalService: boolean = false;
  stateModalSector: boolean = false;
  stateModalZone: boolean = false;
  stateModalArea: boolean = false;
  stateModalPhase: boolean = false;
  stateModalUsers: boolean = false;
  stateModalMine: boolean = false;

  dataMine: any;
  dataService: any;
  dataSector: any;
  dataPhase: any;
  dataZone: any;
  dataArea: any;

  selectType: string = '';
  idSelected: string =  '';  

  getServicesMineSubscription: Subscription;

  constructor( private minesbackService: MinesbackService,  private router: Router, private route: ActivatedRoute, public authService: AuthService) {
    this.idMine = this.route.snapshot.paramMap.get('id'); 
   }

  ngOnInit(): void {

    this.dataParam = this.route.snapshot.paramMap.get('data');
    this.getServicesByMine(this.idMine);
  }

  ngOnDestroy(){

    this.getServicesMineSubscription?.unsubscribe();
 }

  // onreponsDeleteObjectTable($event){

  //   this.instrumentSelected = $event;
  //   this.stateModalAlert = true;  
  //   this.modalInfo.messageModal = 'Esta seguro de eliminar '+$event.codigo;
  //   this.modalInfo.type = 'warning'    
  // }

  // onreponseUpdateObjectTable($event){
  //   this.instrumentSelected = $event;
  //   this.router.navigateByUrl('/emt/instrument/'+ $event.id );

  //   localStorage.setItem('instrument', $event.id );
  // }

  // onreponseTimeObjectTable($event){
  //   this.instrumentSelected = $event;
  //   this.router.navigateByUrl('/emt/times/'+ $event.id );

  //   localStorage.setItem('instrument', $event.id );
  // }

  // onreponseChangeObjectTable($event){
  //   // console.log('COMPANY2: '+$event.uid);
  //   this.instrumentSelected = $event;
  //   this.router.navigateByUrl('/emt/positions/'+ $event.id );

  //   localStorage.setItem('instrument', $event.id );
  // }

  onResponseAlertModal(event){

    this.stateModalAlert = false;
  }

  onReponseStateModalFormService(event){
    this.stateModalService = event;
  }

  onReponseStateModalFormSector(event){
    this.stateModalSector = event;
  }

  onReponseStateModalFormZone(event){
    this.stateModalZone = event;
  }

  onReponseStateModalFormArea(event){
    this.stateModalArea = event;
  }

  onReponseStateModalFormPhase(event){
    this.stateModalPhase = event;
  }

  onReponseStateModalFormMine(event){
    this.stateModalMine = event;
  }

  onReponseStateModalFormUsers(event){
    this.stateModalUsers = event;
  }

  onResponseModalImage(event){
    this.stateImages = event;
  }

  onResponseRefresh(){
    
    console.log('RESPONSE REFRESH');
    //this.getServicesByMine(this.idMine);
  }

  addService(){
    this.typeService = 'new';
    this.stateModalService = true;
  }

  addSector(){
    this.typeSector = 'new';
    this.stateModalSector = true;
  }

  addZone(){
    this.stateModalZone = true;
    this.typeZone = 'new';
  }

  addArea(){
    this.stateModalArea = true;
    this.typeArea = 'new';
  }

  addPhase(){
    this.stateModalPhase = true;
    this.typePhase = 'new';
  }

  addUsers(){
    this.stateModalUsers = true;
  }

  editMine(){
    this.mine = JSON.parse(localStorage.getItem("mine"));
    this.stateModalMine = true;
    this.typeMine = 'edit';
    this.dataMine = this.mine;
  }
  
  // editZone(){
  //   console.log('EDIT ZONE');
  // }

  updateObject(item){  
    console.log('EDIT ZONE: '+item.id);
  }

  deleteObject(item){}


  updateObjectZone(item){
    this.typeZone = 'edit';
    this.dataZone = item;
    this.stateModalZone = true;
  }

  deleteObjectZone(item){

    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+item.nombre); 
    this.selectType = 'zone';
    this.idSelected = item.id; 
  }

  updateObjectPhase(item){
    this.typePhase = 'edit';
    this.dataPhase = item;
    this.stateModalPhase = true;
  }

  deleteObjectPhase(item){
    
    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+item.fase); 
    this.selectType = 'phase';
    this.idSelected = item.id;
  }

  updateObjectSector(item){
    this.typeSector = 'edit';
    this.dataSector = item;
    this.stateModalSector = true;
  }

  deleteObjectSector(item){
    
    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+item.nombre); 
    this.selectType = 'sector';
    this.idSelected = item.id;
  }

  updateObjectService(item){
    this.typeService = 'edit';
    this.dataService = item;
    this.stateModalService = true;
  }

  deleteObjectService(item){

    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+item.nombre); 
    this.selectType = 'service';
    this.idSelected = item.id; 
  }

  updateObjectArea(item){
    this.typeArea = 'edit';
    this.dataArea = item;
    this.stateModalArea = true;
  }

  deleteObjectArea(item){

    this.messageAlert(true, 'warning', 'Esta seguro de eliminar '+item.nombre); 
    this.selectType = 'area';
    this.idSelected = item.id;
  }

  onReponseDeleteObjectModal(item){

    console.log('EVENT'+item)
    if( this.selectType == 'area') this.deleteArea(this.idSelected);
    if( this.selectType == 'phase') this.deletePhase(this.idSelected);
    if( this.selectType == 'zone') this.deleteZone(this.idSelected);
    if( this.selectType == 'sector') this.deleteSector(this.idSelected);
    if( this.selectType == 'service') this.deleteService(this.idSelected);
  }

  tools(){
    this.stateTools = true;
  }

  showImages(){
    this.stateImages = true;
  }

  showMap(){

    this.viewMap == false ? this.viewMap = true : this.viewMap = false;
  }

  getServicesByMine(id: string){
  
    this.getServicesMineSubscription = this.minesbackService.getServicesByMine(id).subscribe({
      next: (resp) => { 
         this.services = resp;
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }


  //AGGREGATE 

  onResponseAddObjectService(event){

    console.log('AGGREGATE SERVICE');
    this.aggregateService(event[0]);
  }

  onReponseAddObjectSector(event){

    console.log('AGGREGATE SECTOR');
    this.aggregateMine(event[0]);
  }

  onReponseAddObjectZone(event){

    console.log('AGGREGATE ZONE');
    this.aggregateZone(event[0]);
  }

  onReponseAddObjectArea(event){

    console.log('AGGREGATE AREA');
    this.aggregateArea(event[0]);
  }

  onReponseAddObjectPhase(event){

    console.log('AGGREGATE PHASE');
    this.aggregatePhase(event[0]);
  }
  
  aggregateService(newService: any) {

    console.log('AGGREGATE SERVICE');
    // Verifica si ya existe un servicio con el mismo ID
    const existingService = this.services.find(service => service.id === newService.id);
  
    if (existingService) {
      console.log(`Ya existe un servicio con ID ${newService.id}. No se puede agregar.`);
    } else {
      // Agrega el nuevo servicio a la lista
      this.services.push(newService);
      console.log(`Nuevo servicio con ID ${newService.id} agregado.`, newService);
    }
  }

  aggregateMine(newMineData: any) {

    // Encuentra el servicio con el ID especificado
    const idService = newMineData.servicio_id;
    console.log('ID SERVICE: '+idService);
    const targetService = this.services.find(servicio => servicio.id === idService);
  
    // Verifica si se encontró el servicio
    if (targetService) {
      // Verifica si el servicio tiene la propiedad "mines" y si no, crea un nuevo array de minas
      if (!targetService.mines) {
        targetService.mines = [];
      }
  
      // Agrega la nueva mina al servicio
      targetService.mines.push(newMineData);
  
      console.log(`Nueva mina agregada al servicio con ID.`, newMineData);
  
      return; // Termina la función después de agregar la nueva mina en el servicio seleccionado
    }
  
    console.log(`No se encontró el servicio con ID.`);
  }
  
  aggregateZone(newZoneData: any) {

    const idSector = newZoneData.sector_faena_id;

    // Recorre la lista de servicios
    for (const servicio of this.services) {
      // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
      if (!servicio.mines) {
        continue;
      }
  
      // Encuentra la mina con el ID especificado en el servicio actual
      const targetMine = servicio.mines.find(mina => mina.id === idSector);
  
      // Verifica si se encontró la mina
      if (targetMine) {
        // Verifica si la mina tiene la propiedad "zonas" y si no, crea un nuevo array de zonas
        if (!targetMine.zonas) {
          targetMine.zonas = [];
        }
  
        // Agrega la nueva zona a la mina
        targetMine.zonas.push(newZoneData);
  
        console.log(`Nueva zona agregada a la mina con ID ${idSector}.`, newZoneData);
        return; // Termina la función después de agregar la nueva zona a la mina
      }
    }
  
    console.log(`No se encontró la mina con ID ${idSector}. No se puede agregar la zona.`);
  }
  
  aggregateArea(newAreaData: any) {

    const zonaId = newAreaData.zona_mina_id;
  
    // Recorre la lista de servicios
    for (const servicio of this.services) {
      // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
      if (!servicio.mines) {
        continue;
      }
  
      // Recorre la lista de minas en el servicio actual
      for (const mina of servicio.mines) {
        // Verifica si la mina tiene la propiedad "zonas" y si no, pasa a la siguiente mina
        if (!mina.zonas) {
          continue;
        }
  
        // Encuentra la zona con el ID especificado en la mina actual
        const targetZone = mina.zonas.find(zona => zona.id === zonaId);
  
        // Verifica si se encontró la zona
        if (targetZone) {
          // Verifica si la zona tiene la propiedad "areas" y si no, crea un nuevo array de áreas
          if (!targetZone.areas) {
            targetZone.areas = [];
          }
  
          // Agrega la nueva área a la zona
          targetZone.areas.push(newAreaData);
  
          console.log(`Nueva área agregada a la zona con ID ${zonaId}.`, newAreaData);
          return; // Termina la función después de agregar la nueva área a la zona
        }
      }
    }
  
    console.log(`No se encontró la zona con ID ${zonaId}. No se puede agregar el área.`);
  }
  
  aggregatePhase(newPhaseData: any) {

    const idSector = newPhaseData.sector_faena_id;

    // Recorre la lista de servicios
    for (const servicio of this.services) {
      // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
      if (!servicio.mines) {
        continue;
      }
  
      // Encuentra la mina con el ID especificado en el servicio actual
      const targetMine = servicio.mines.find(mina => mina.id === idSector);
  
      // Verifica si se encontró la mina
      if (targetMine) {
        // Verifica si la mina tiene la propiedad "zonas" y si no, crea un nuevo array de zonas
        if (!targetMine.fases) {
          targetMine.fases = [];
        }
  
        // Agrega la nueva zona a la mina
        targetMine.fases.push(newPhaseData);
  
        console.log(`Nueva zona agregada a la mina con ID ${idSector}.`, newPhaseData);
        return; // Termina la función después de agregar la nueva zona a la mina
      }
    }
  
    console.log(`No se encontró la mina con ID ${idSector}. No se puede agregar la zona.`);
  }


  //EDIT

  onReponseObjectService(event){

    console.log('AGGREGATE SERVICE');
    this.editService(event);
  }

  onResponseObjectSector(event){

    console.log('AGGREGATE SERVICE');
    this.editSector(event);
  }

  onResponseObjectZone(event){

    console.log('AGGREGATE SERVICE');
    this.editZone(event);
  }
  
  onResponseObjectArea(event){

    console.log('AGGREGATE SERVICE');
    this.editArea(event);
  }

  onResponseObjectPhase(event){

    console.log('AGGREGATE SERVICE');
    //this.editPhase(event);
    this.getServicesByMine(this.idMine);
  }

  editService(newServiceData: any) {

    const serviceId = newServiceData.id;
    // Encuentra el índice del servicio con el ID especificado
    const indexService = this.services.findIndex(service => service.id === serviceId);
  
    // Verifica si se encontró el servicio
    if (indexService !== -1) {
      // Actualiza las propiedades del servicio con los nuevos datos
      this.services[indexService] = {
        ...this.services[indexService],
        ...newServiceData
      };
  
      console.log(`Servicio con ID ${serviceId} editado.`, this.services[indexService]);
    } else {
      console.log(`No se encontró el servicio con ID ${serviceId}.`);
    }
  }

  editSector(newMineData: any) {
    const mineId = newMineData.id;
  
    // Recorre la lista de servicios
    for (const servicio of this.services) {
      // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
      if (!servicio.mines) {
        continue;
      }
  
      // Encuentra el índice de la mina con el ID especificado en el servicio actual
      const indexMine = servicio.mines.findIndex(mine => mine.id === mineId);
  
      // Verifica si se encontró la mina
      if (indexMine !== -1) {
        // Actualiza las propiedades de la mina con los nuevos datos
        servicio.mines[indexMine] = {
          ...servicio.mines[indexMine],
          ...newMineData
        };
  
        console.log(`Mina con ID ${mineId} editada.`, servicio.mines[indexMine]);
        return; // Termina la función después de encontrar y editar la mina
      }
    }
  
    console.log(`No se encontró la mina con ID ${mineId}.`);
  }

  editZone(newZoneData: any) {
    const zoneId = newZoneData.id;
  
    // Recorre la lista de servicios
    for (const servicio of this.services) {
      // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
      if (!servicio.mines) {
        continue;
      }
  
      // Recorre la lista de minas en el servicio actual
      for (const mina of servicio.mines) {
        // Verifica si la mina tiene la propiedad "zonas" y si no, pasa a la siguiente mina
        if (!mina.zonas) {
          continue;
        }
  
        // Encuentra el índice de la zona con el ID especificado en la mina actual
        const indexZone = mina.zonas.findIndex(zone => zone.id === zoneId);
  
        // Verifica si se encontró la zona
        if (indexZone !== -1) {
          // Actualiza las propiedades de la zona con los nuevos datos
          mina.zonas[indexZone] = {
            ...mina.zonas[indexZone],
            ...newZoneData
          };
  
          console.log(`Zona con ID ${zoneId} editada.`, mina.zonas[indexZone]);
          return; // Termina la función después de encontrar y editar la zona
        }
      }
    }
  
    console.log(`No se encontró la zona con ID ${zoneId}.`);
  }
  
  editArea(newAreaData: any) {
    const areaId = newAreaData.id;
  
    // Recorre la lista de servicios
    for (const servicio of this.services) {
      // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
      if (!servicio.mines) {
        continue;
      }
  
      // Recorre la lista de minas en el servicio actual
      for (const mina of servicio.mines) {
        // Verifica si la mina tiene la propiedad "zonas" y si no, pasa a la siguiente mina
        if (!mina.zonas) {
          continue;
        }
  
        // Recorre la lista de zonas en la mina actual
        for (const zona of mina.zonas) {
          // Verifica si la zona tiene la propiedad "areas" y si no, pasa a la siguiente zona
          if (!zona.areas) {
            continue;
          }
  
          // Encuentra el índice del área con el ID especificado en la zona actual
          const indexArea = zona.areas.findIndex(area => area.id === areaId);
  
          // Verifica si se encontró el área
          if (indexArea !== -1) {
            // Actualiza las propiedades del área con los nuevos datos
            zona.areas[indexArea] = {
              ...zona.areas[indexArea],
              ...newAreaData
            };
  
            console.log(`Área con ID ${areaId} editada.`, zona.areas[indexArea]);
            return; // Termina la función después de encontrar y editar el área
          }
        }
      }
    }
  
    console.log(`No se encontró el área con ID ${areaId}.`);
  }
  
  editPhase(newFaseData: any) {
    const faseId = newFaseData.id;
  
    for (const servicio of this.services) {
      // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
      if (!servicio.mines) {
        continue;
      }
  
      // Recorre la lista de minas en el servicio actual
      for (const mina of servicio.mines) {
        // Verifica si la mina tiene la propiedad "zonas" y si no, pasa a la siguiente mina
        if (!mina.fases) {
          continue;
        }
  
        // Encuentra el índice de la zona con el ID especificado en la mina actual
        const indexPhase = mina.fases.findIndex(fase => fase.id === faseId);
  
        // Verifica si se encontró la zona
        if (indexPhase !== -1) {
          // Actualiza las propiedades de la zona con los nuevos datos
          mina.fases[indexPhase] = {
            ...mina.zonas[indexPhase],
            ...newFaseData
          };
  
          console.log(`Zona con ID ${faseId} editada.`, mina.zonas[indexPhase]);
          return; // Termina la función después de encontrar y editar la zona
        }
      }
    }
  
    console.log(`No se encontró la fase con ID ${faseId}.`);
  }


  // Remove

  removeService(serviceId: string) {
    // Encuentra el índice del servicio con el ID especificado
    const indexService = this.services.findIndex(service => service.id === serviceId);

    // Verifica si se encontró el servicio
    if (indexService !== -1) {
        // Elimina el servicio de la lista
        const removedService = this.services.splice(indexService, 1)[0];
        console.log(`Servicio con ID ${serviceId} eliminado.`, removedService);
    } else {
        console.log(`No se encontró el servicio con ID ${serviceId}.`);
    }
  }

  removeMine(id: string) {
    // Recorre la lista de servicios
    for (const servicio of this.services) {
        // Encuentra el índice de la mina con el ID especificado en el servicio actual
        const indexMine = servicio.mines.findIndex(mine => mine.id === id);

        // Verifica si se encontró la mina en el servicio actual
        if (indexMine !== -1) {
            // Elimina la mina de la lista de minas en el servicio actual
            const removedMine = servicio.mines.splice(indexMine, 1)[0];
            console.log(`Mina con ID ${id} eliminada.`, removedMine);
            return; // Termina la función después de encontrar y eliminar la mina
        }
    }
  }
  
  removeZone(zoneId: string) {
    // Recorre la lista de servicios
    for (const servicio of this.services) {
        // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
        if (!servicio.mines) {
            continue;
        }
  
        // Recorre la lista de minas en el servicio actual
        for (const mina of servicio.mines) {
            // Verifica si la mina tiene la propiedad "zonas" y si no, pasa a la siguiente mina
            if (!mina.zonas) {
                continue;
            }
  
            // Encuentra la zona con el ID especificado en la mina actual
            const indexZona = mina.zonas.findIndex(zona => zona.id === zoneId);
  
            // Verifica si se encontró la zona en la mina actual
            if (indexZona !== -1) {
                // Elimina la zona de la lista de zonas en la mina actual
                const removedZona = mina.zonas.splice(indexZona, 1)[0];
                console.log(`Zona con ID ${zoneId} eliminada.`, removedZona);
                return; // Termina la función después de encontrar y eliminar la zona
            }
        }
    }
  
    console.log(`No se encontró ninguna zona con ID ${zoneId}.`);
  }

  removeAreas(areaId: string) {
    // Recorre la lista de servicios
    for (const servicio of this.services) {
        // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
        if (!servicio.mines) {
            continue;
        }
  
        // Recorre la lista de minas en el servicio actual
        for (const mina of servicio.mines) {
            // Verifica si la mina tiene la propiedad "zonas" y si no, pasa a la siguiente mina
            if (!mina.zonas) {
                continue;
            }
  
            // Recorre la lista de zonas en la mina actual
            for (const zona of mina.zonas) {
                // Verifica si la zona tiene la propiedad "areas" y si no, pasa a la siguiente zona
                if (!zona.areas) {
                    continue;
                }
  
                // Filtra las áreas que tienen el ID diferente al especificado
                zona.areas = zona.areas.filter(area => area.id !== areaId);
            }
        }
    }
  
    console.log(`Áreas con ID ${areaId} eliminadas.`);
  }

  removeFase(faseId: string) {
    // Recorre la lista de servicios
    for (const servicio of this.services) {
        // Verifica si el servicio tiene la propiedad "mines" y si no, pasa al siguiente servicio
        if (!servicio.mines) {
            continue;
        }
  
        // Recorre la lista de minas en el servicio actual
        for (const mina of servicio.mines) {
            // Verifica si la mina tiene la propiedad "fases" y si no, pasa a la siguiente mina
            if (!mina.fases) {
                continue;
            }
  
            // Encuentra las fases con el ID especificado en la mina actual
            const indexesToRemove = mina.fases.reduce((acc, fase, index) => {
                if (fase.id === faseId) {
                    acc.push(index);
                }
                return acc;
            }, []);
  
            // Verifica si se encontraron fases en la mina actual
            if (indexesToRemove.length > 0) {
                // Elimina las fases de la lista de fases en la mina actual
                indexesToRemove.reverse().forEach(index => {
                    const removedFase = mina.fases.splice(index, 1)[0];
                    console.log(`Fase con ID ${faseId} eliminada.`, removedFase);
                });
            }
        }
    }
  
    console.log(`No se encontraron fases con ID ${faseId}.`);
  }

  deleteArea(id: string){

    this.minesbackService.deleteArea(id).subscribe({
      next: (resp) => { 
         //this.removeAreas(id);
         this.messageAlert(true, 'success', 'Área eliminada satisfactoriamente');
         console.log('Response: ' + resp); 
         this.getServicesByMine(this.idMine);
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  deletePhase(id: string){

    this.minesbackService.deletePhase(id).subscribe({
      next: (resp) => { 
         this.removeFase(id);
         this.messageAlert(true, 'success', 'Fase eliminada satisfactoriamente');
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  deleteZone(id: string){

    this.minesbackService.deleteZone(id).subscribe({
      next: (resp) => { 
         this.removeZone(id);
         this.messageAlert(true, 'success', 'Zona eliminada satisfactoriamente');
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

  deleteSector(id: string){

    this.minesbackService.deleteSector(id).subscribe({
      next: (resp) => { 
         this.removeMine(id);
         this.messageAlert(true, 'success', 'Sector eliminado satisfactoriamente');
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }
  
  deleteService(id: string){

    this.minesbackService.deleteService(id).subscribe({
      next: (resp) => { 
         this.removeService(id);
         this.messageAlert(true, 'success', 'Servicio eliminado satisfactoriamente');
         console.log('Response: ' + resp); 
        },
      error: (err) => { console.log('Received an error: ' + err.message)},
      complete: () => { 
        console.log('Completed'); 
      }
    });
  }

 
  
  messageAlert(state: boolean, type: string, messageModal: string) {
    this.stateModalAlert = state;
    this.modalInfo.messageModal = messageModal;
    this.modalInfo.type = type;
  }

}
